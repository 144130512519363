import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom"; // Importar useNavigate para redirecciones
import "./PaginaNovedades.css";

const PaginaNovedades = () => {
  const [vista, setVista] = useState("grid"); // Valor inicial es "grid"
  const [noticias, setNoticias] = useState([]);
  const navigate = useNavigate(); // Hook para redirigir

  useEffect(() => {
    // Hacer la petición GET al backend para obtener las noticias
    fetch("http://localhost:5000/api/noticias")
      .then((response) => response.json())
      .then((data) => {
        setNoticias(data); // Guardar las noticias en el estado
      })
      .catch((error) => console.error("Error al obtener las noticias:", error));
  }, []);

  // Función para manejar la redirección a la página de detalles
  const handleDetalle = (id) => {
    navigate(`/detalle-novedades/${id}`); // Redirige a la ruta con el ID de la noticia
  };

  return (
    <div className="menu-contenedor5">
      <div className="seccion-noticias">
        <div className="contenido-noticias">
          <h4 className="caso-uso">Actividades destacadas</h4>
          <h2 className="titulo-noticias">
            Actividades y logros de nuestra comunidad teológica
          </h2>
          <p className="descripcion-noticias">
            Descubre todas las actividades, programas y eventos que nuestra
            institución está realizando para fortalecer la formación teológica y
            el crecimiento espiritual. ¡Sé parte de esta misión!
          </p>
         
        </div>

        <div className="imagen-noticias">
          <div className="circulo-degradado"></div>
          <img
            src="/Imagenes/novedades.png"
            alt="Imagen de actividades teológicas"
          />
        </div>
      </div>

      {/* Sección de noticias */}
      <section className="contact-sectionPrin">
      <h2 className="noticias-titulo">Encontramos {noticias.length} Noticias Disponibles :.</h2>

        {/* Selector de vista */}
        <div className="vista-selector">
          <span>Vista</span>
          <button
            className={`grid-btn ${vista === "grid" ? "active" : ""}`}
            onClick={() => setVista("grid")}
          >
            <Icon icon="tdesign:menu-application" width="24" height="24" />
          </button>
          
        </div>

        {/* Contenedor de noticias */}
        <div
          className={`noticias-container ${
            vista === "list" ? "list-view" : "grid-view"
          }`}
        >
          {noticias.length > 0 ? (
            noticias.map((noticia) => (
              <div className="noticia-card" key={noticia._id}>
                <div className="noticia-imagen">
                  <img
                    src={`http://localhost:5000/uploads/${noticia.imagen}`}
                    alt={`Imagen de la noticia ${noticia.titulo}`}
                  />
                  <span className="noticia-categoria">NOTICIAS</span>
                </div>
                <div className="noticia-texto">
                  <h3 className="noticia-titulo">{noticia.titulo}</h3>
                  <p className="noticia-fecha">
                    {new Date(noticia.fecha).toLocaleDateString("es-ES", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                  <button
                    className="noticia-boton"
                    onClick={() => handleDetalle(noticia._id)}
                  >
                    Más Detalles
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No hay noticias disponibles.</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default PaginaNovedades;
