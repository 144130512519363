// src/Componentes-Restringidos/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token'); // Verifica si el token existe

  if (!token) {
    return <Navigate to="/Acceso" />; // Si no hay token, redirige al login
  }

  return children; // Si hay token, muestra el contenido protegido
};

export default ProtectedRoute;


