import React from 'react';
import './ProgramaBachillerato.css';

const CursosComplementarios = () => {
  return (
    <div className="programaBach-page">
      {/* Cabecera */}
      <header className="programaBach-header">
        <h1>Cursos Complementarios</h1>
        <p>
          Programas diseñados para complementar la educación cristiana con herramientas prácticas en áreas como 
          música, idiomas, comunicación, evangelismo y más.
        </p>
        <span className="nivelBach">Cursos Breves</span>
        <div className="calificacionesBach">
          ⭐⭐⭐⭐⭐ (Duración: Días o meses según el curso)
        </div>
      </header>

      {/* ¿Qué aprenderá? */}
      <section className="aprendizajeBach">
        <h2>¿Qué aprenderá?</h2>
        <p>
          Estos cursos ofrecen formación práctica en áreas específicas que fortalecen la educación cristiana, 
          como habilidades ministeriales, música, idiomas y evangelismo. Son ideales para quienes buscan 
          ampliar sus conocimientos en un tiempo corto.
        </p>
      </section>

      {/* Lista de cursos */}
      <section className="planEstudiosBach">
        <h2>Cursos Disponibles</h2>
        <table>
          <thead>
            <tr>
              <th>Curso</th>
              <th>Temas</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Taller de Música</td>
              <td>
                <ul>
                  <li>Guitarra</li>
                  <li>Piano</li>
                  <li>Acordeón</li>
                  <li>Canto</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Taller de Idiomas</td>
              <td>
                <ul>
                  <li>Aymara</li>
                  <li>Quechua</li>
                  <li>Inglés</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Taller de Comunicación y Redes</td>
              <td>
                <ul>
                  <li>Programas y paquetes</li>
                  <li>Inteligencia Artificial</li>
                  <li>Estrategias de comunicación en redes</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Taller de Misión Extrema</td>
              <td>
                <ul>
                  <li>Misión transcultural</li>
                  <li>Proyectos y estrategias evangelísticas</li>
                  <li>Práctica en misión extrema</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      {/* Requisitos */}
      <section className="requisitosBach">
        <h2>Requisitos</h2>
        <ul>
          <li>Fotocopia de CI o DNI</li>
          <li>Formulario de inscripción</li>
          <li>Carta de recomendación (opcional)</li>
        </ul>
      </section>

      {/* Descripción */}
      <section className="descripcionBach">
        <h2>Descripción</h2>
        <p>
          Los cursos complementarios son programas cortos diseñados para fortalecer áreas específicas de la 
          vida cristiana. No forman parte de la malla curricular, pero están enfocados en brindar herramientas 
          prácticas que pueden aplicarse directamente en el ministerio, el servicio comunitario y el desarrollo 
          personal.
        </p>
      </section>
    </div>
  );
};

export default CursosComplementarios;
