// src/Componentes-Restringidos/ModuloCalificacionesAsistencia/MisAsistencias.js
import React, { useState } from "react";

const MisAsistencias = () => {
  // Aquí almacenaremos las asistencias del estudiante
  const [asistencias, setAsistencias] = useState([
    { numero: 1, fecha: "01/10/2024", estado: "Presente" },
    { numero: 2, fecha: "02/10/2024", estado: "Ausente" },
    { numero: 3, fecha: "03/10/2024", estado: "Justificado" },
    // Puedes añadir más datos de ejemplo
  ]);

  return (
    <div className="container mt-5">
      <h2>Mis Asistencias</h2>
      <table className="table table-striped table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>#</th>
            <th>Fecha</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {asistencias.map((asistencia) => (
            <tr key={asistencia.numero}>
              <td>{asistencia.numero}</td>
              <td>{asistencia.fecha}</td>
              <td>{asistencia.estado}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MisAsistencias;
