import React from "react";
import "./ProgramaBachillerato.css";

const ProgramaBachillerato = () => {
  return (
    <div className="programaBach-page">
      {/* Cabecera */}
      <header className="programaBach-header">
        <h1>Bachiller en Teología</h1>
        <p>
          Forma parte de un programa académico diseñado para líderes con
          vocación ministerial.
        </p>
        <span className="nivelBach">Nivel Técnico Superior</span>
        <div className="calificacionesBach">
          ⭐⭐⭐⭐⭐ (45 Materias Obligatorias - Duración: 3 años)
        </div>
      </header>

      <div className="aprendizajeBach">
        <h2>¿Qué aprenderá?</h2>
        <p>
          Este programa proporciona una base teológica sólida, habilidades de
          liderazgo y herramientas prácticas para servir en contextos
          ministeriales y comunitarios.
        </p>
      </div>
      {/* Plan de estudios */}
      <div className="planEstudiosBach">
        <h2>Plan de Estudios</h2>
        <table>
          <thead>
            <tr>
              <th>Área</th>
              <th>Materias</th>
              <th>Duración (Horas)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Área Pastoral</td>
              <td>
                <ul>
                  <li>Apologética Cristiana Contemporánea</li>
                  <li>Formación y Liderazgo Espiritual</li>
                  <li>Evangelismo y Desarrollo Integral de la Iglesia</li>
                  <li>Administración y Gestión de Recursos Eclesiásticos</li>
                  <li>Cuidado y Asesoramiento Pastoral</li>
                  <li>Organización y Política Iglesia del Nazareno</li>
                  <li>Ética Cristiana</li>
                  <li>Metodología de Investigación Teológica</li>
                </ul>
              </td>
              <td>9 meses</td>
            </tr>
            <tr>
              <td>Área Biblia</td>
              <td>
                <ul>
                  <li>Nuevo Testamento I</li>
                  <li>Nuevo Testamento II</li>
                  <li>Antiguo Testamento I</li>
                  <li>Antiguo Testamento II</li>
                  <li>Hermenéutica Bíblica</li>
                  <li>Literatura Poética</li>
                  <li>Literatura Joanina</li>
                  <li>Pentateuco</li>
                  <li>Práctica Ministerial I</li>
                  <li>Práctica Ministerial II</li>
                  <li>Práctica Ministerial III</li>
                </ul>
              </td>
              <td>8 meses y 1 semana</td>
            </tr>
            <tr>
              <td>Área Teología</td>
              <td>
                <ul>
                  <li>Teología Sistemática I</li>
                  <li>Teología Sistemática II</li>
                  <li>Teología Sistemática III</li>
                  <li>Teología Sistemática IV</li>
                  <li>Teología de Santidad I</li>
                  <li>Teología de Santidad II</li>
                  <li>Teología y Ministerio de Jesús</li>
                </ul>
              </td>
              <td>8 meses y 1 semana</td>
            </tr>
            <tr>
              <td>Área Desarrollo Ministerial e Historia</td>
              <td>
                <ul>
                  <li>Historia Eclesiástica I</li>
                  <li>Historia Eclesiástica II</li>
                  <li>Fundamentos Nazarénicos</li>
                  <li>Historia e Identidad Iglesia del Nazareno</li>
                  <li>La Iglesia en Misión</li>
                </ul>
              </td>
              <td>8 meses y 1 semana</td>
            </tr>
          </tbody>
        </table>
        <p className="nota-plan-estudios">
          <strong>Nota: SON 48 MATERIAS, DE LAS CUALES 45 SON OBLIGATORIAS</strong>
        </p>
      </div>

      {/* Requisitos */}
      <div className="requisitosBach">
        <h2>Requisitos Generales de Ingreso</h2>
        <ul>
          <li>Tener un llamamiento del Señor al ministerio cristiano.</li>
          <li>
            Ser miembro en plena comunión de una iglesia. (Acreditar con una
            certificación).
          </li>
          <li>
            Tener una carta de recomendación de parte del pastor y/o autoridades
            eclesiásticas que correspondan (autoridad eclesiástica de su
            denominación).
          </li>
          <li>
            Presentar título de bachiller en humanidades o libreta de
            calificación de acuerdo al nivel. (Se quedará fotocopia simple).
          </li>
          <li>
            Tener por lo menos 18 años de edad para residencia. (Presentar
            fotocopia de cédula de identidad).
          </li>
          <li>Gozar de buena salud. (Presentarse a la revisión médica).</li>
          <li>Presentar certificado de matrimonio. (Para los casados).</li>
          <li>
            Presentar solicitud de ingreso (FORM-01) con firma, fecha, datos
            completos y dos fotografías a color 4x4 fondo rojo.
          </li>
          <li>Llenar formulario de recomendación del pastor (FORM-02).</li>
          <li>
            Depositar la matrícula anual correspondiente. (Presentar fotocopia o
            enviar foto).
          </li>
        </ul>
      </div>

      {/* Descripción */}
      <div className="descripcionBach">
        <h2>Descripción</h2>
        <p>
          La nueva malla curricular consta de 48 materias pero 45 materias son
          obligatorias, organizados en 4 áreas, con un tiempo mínimo de 3 años
          para completar el programa de Bachiller en Teología. Cada materia
          tiene una duración aproximada de 30 horas académicas.
        </p>
      </div>
    </div>
  );
};

export default ProgramaBachillerato;
