// src/Componentes-Restringidos/ModuloPanelDeDatos/PanelDeDatos.js
import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import "./PanelDeDatos.css"; // Asegúrate de tener el archivo CSS

const dataGenero = [
  { name: "Hombres", value: 52 },
  { name: "Mujeres", value: 50 },
];

const dataInscripcion = [
  { name: "Nuevo", value: 30 },
  { name: "Antiguo", value: 40 },
  { name: "Repite", value: 30 },
];

const dataPie = [
  { name: "Estudiantes Inscritos", value: 52 },
  { name: "Estudiantes no Inscritos", value: 10 },
];

const COLORS = ["#0088FE", "#FFBB28"];

const PanelDeDatos = () => {
  return (
    <div className="panel-datos-container">
      <div className="titulo-panel-datos">
      <i className="fas fa-medal icono-titulo"></i>

        <h1 className="titulo-texto">Bienvenido, al Panel de Datos</h1>
      </div>
      <hr className="linea-divisoria" />

      <div className="grafico-contenedor">
        {/* Gráfico de Barras para Distribución de Género */}
        <div className="grafico-barra">
          <h3>Distribución de alumnos por Género</h3>
          <ResponsiveContainer width="100%" height={220}>
            {" "}
            {/* Cambia a 200 o el valor que prefieras */}
            <BarChart data={dataGenero}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Gráfico de Barras para Tipo de Inscripción */}
        <div className="grafico-barra">
          <h3>Clasificación de Estudiantes por Tipo de Inscripción</h3>
          <ResponsiveContainer width="100%" height={220}>
            <BarChart data={dataInscripcion}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Gráfico de Pie */}
        <div className="grafico-pie">
          <h3>Cantidad de Inscritos Gestión - 2024</h3>
          <ResponsiveContainer width="100%" height={220}>
            <PieChart>
              <Pie
                data={dataPie}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#8884d8"
              >
                {dataPie.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default PanelDeDatos;
