import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import generarPDF from "./GenerarPDF";

const ImprimirBoleta = () => {
  const { codigoEstudiante } = useParams(); // Obtener el código del estudiante de la URL
  const [searchTerm, setSearchTerm] = useState(""); // Estado para manejar el término de búsqueda
  const [formData, setFormData] = useState({
    codigoEstudiante: "Código Estudiante",
    periodoIngreso: "",
    turno: "Mañana",
    nivel: "Licenciatura",
    modalidad: "REGULAR",
    ciclo: "1",
    foto: null,
    estado: "Activo",
    dni: "",
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    sexo: "",
    celular: "",
    correo: "",
    fechaNacimiento: "",
    pais: "",
    ciudad: "",
    direccion: "",
    iglesia: "",
    distrito: "",
    fotocopiaCI: null,
    formulario1: null,
    formulario2: null,
    formulario3: null,
    comprobantePago: null,
    matricula: null,
  });

  // Cargar datos del estudiante al montar el componente
  useEffect(() => {
    const obtenerDatosEstudiante = async () => {
      try {
        const response = await axios.get(
          `http://localhost:5000/api/estudiantes/${codigoEstudiante}`
        );

        const estudianteData = response.data;

        // Asegúrate de convertir la fecha a formato 'YYYY-MM-DD' si es necesario
        const fechaNacimiento = estudianteData.fechaNacimiento
          ? new Date(estudianteData.fechaNacimiento).toISOString().split("T")[0]
          : ""; // Si no hay fecha, se asigna un string vacío

        setFormData({
          codigoEstudiante: estudianteData.codigoEstudiante,
          periodoIngreso: estudianteData.periodoIngreso,
          turno: estudianteData.turno,
          nivel: estudianteData.nivel,
          modalidad: estudianteData.modalidad,
          ciclo: estudianteData.ciclo,
          foto: estudianteData.foto || null,
          estado: estudianteData.estado,
          dni: estudianteData.dni,
          nombres: estudianteData.nombres,
          apellidoPaterno: estudianteData.apellidoPaterno,
          apellidoMaterno: estudianteData.apellidoMaterno,
          sexo: estudianteData.sexo,
          celular: estudianteData.celular,
          correo: estudianteData.correo,
          fechaNacimiento, // Asignamos la fecha formateada
          pais: estudianteData.pais,
          ciudad: estudianteData.ciudad,
          direccion: estudianteData.direccion,
          iglesia: estudianteData.iglesia,
          distrito: estudianteData.distrito,
          fotocopiaCI: estudianteData.fotocopiaCI || null,
          formulario1: estudianteData.formulario1 || null,
          formulario2: estudianteData.formulario2 || null,
          formulario3: estudianteData.formulario3 || null,
          comprobantePago: estudianteData.comprobantePago || null,
          matricula: estudianteData.matricula || null,
        });
      } catch (error) {
        console.error("Error al cargar los datos del estudiante:", error);
        alert("No se pudieron cargar los datos del estudiante");
      }
    };

    obtenerDatosEstudiante();
  }, [codigoEstudiante]);

  const handleInputChange = (e) => {
    const { name, value } = e.target; // Extraemos el nombre y valor del campo

    // Actualizamos el estado general del formulario
    setFormData({
      ...formData,
      [name]: value,
    });

    // Si el campo modificado es "iglesia", también actualizamos el término de búsqueda
    if (name === "iglesia") {
      setSearchTerm(value); // Actualizamos el término de búsqueda
    }
  };

  // Manejar el cambio de archivos
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0], // Asigna el archivo seleccionado al campo correspondiente
    });
  };

  // Manejador del formulario para guardar cambios
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    // Agregar los datos al FormData para enviarlos
    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        formDataToSend.append(key, formData[key]);
      }
    });

    try {
      const response = await axios.put(
        `http://localhost:5000/api/estudiantes/${codigoEstudiante}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("Estudiante actualizado correctamente");
    } catch (error) {
      console.error("Error al actualizar estudiante:", error);
      alert("Error al actualizar el estudiante");
    }
  };

  return (
    <div className="container">
      <h2>Modificar Estudiante</h2>
      <form onSubmit={handleSubmit}>
        <div className="custom-box">
          <button className="custom-button">.: DATOS ACADÉMICOS :.</button>
          <div className="form-fields d-flex">
            <div className="w-75">
              <div className="row mb-3">
                <div className="col-md-6">
                  <label>Código de Estudiante:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="codigoEstudiante"
                    value={formData.codigoEstudiante}
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label>Periodo de Ingreso:</label>
                  <select
                    className="form-control"
                    name="periodoIngreso"
                    value={formData.periodoIngreso}
                    onChange={handleInputChange}
                  >
                    <option value="I-2024">I-2024</option>
                    <option value="II-2024">II-2024</option>
                    <option value="I-2023">I-2023</option>
                    {/* Más opciones */}
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label>Nivel:</label>
                  <select
                    className="form-control"
                    name="nivel"
                    value={formData.nivel}
                    onChange={handleInputChange}
                  >
                    <option value="LICENCIATURA">Licenciatura</option>
                    <option value="BACHILLERATO">Bachillerato</option>
                    <option value="CURSO_COMPLEMENTARIO">
                      Cursos Complementarios
                    </option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label>Modalidad:</label>
                  <select
                    className="form-control"
                    name="modalidad"
                    value={formData.modalidad}
                    onChange={handleInputChange}
                  >
                    <option value="REGULAR">Regular</option>
                    <option value="MODULAR">Modular</option>
                    <option value="INTENSIVO">Intensivo</option>
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label>Ciclo Académico:</label>
                  <select
                    className="form-control"
                    name="ciclo"
                    value={formData.ciclo}
                    onChange={handleInputChange}
                  >
                    {[...Array(10).keys()].map((i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <label>Estado:</label>
                  <select
                    name="estado"
                    className="form-control"
                    value={formData.estado}
                    onChange={handleInputChange}
                  >
                    <option value="Activo">Activo</option>
                    <option value="Inactivo">Inactivo</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Columna para la imagen */}
            <div className="w-25 text-center">
              {/* Columna para la imagen, ocupa el 25% */}
              <img
                src={
                  typeof formData.foto === "string"
                    ? `http://localhost:5000/${formData.foto}` // Si la imagen es una cadena (ruta del servidor), concatenamos con la URL base
                    : formData.foto instanceof Blob
                    ? URL.createObjectURL(formData.foto) // Si la imagen es un archivo local, la previsualizamos con createObjectURL
                    : "/Imagenes/Avatar.jpg" // Imagen por defecto
                }
                alt="Imagen de perfil"
                className="img-thumbnail"
                style={{
                  width: "150px",
                  height: "200px",
                  objectFit: "cover",
                  marginBottom: "10px",
                }}
              />
              <p>
                {formData.foto ? "Foto cargada" : "Fotografía no disponible"}
              </p>
              <label htmlFor="upload-button" className="btn btn-info mt-2">
                <i className="fas fa-upload"></i> Subir imagen
              </label>
              <input
                type="file"
                id="upload-button"
                className="d-none"
                name="foto"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>

        <div className="custom-box">
          <button className="custom-button">.: DATOS PERSONALES :.</button>
          <div className="form-fields">
            <div className="row mb-3">
              <div className="col-md-4">
                <label>DNI:</label>
                <input
                  type="text"
                  className="form-control"
                  name="dni"
                  value={formData.dni}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4">
                <label>Nombres:</label>
                <input
                  type="text"
                  className="form-control"
                  name="nombres"
                  value={formData.nombres}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4">
                <label>Apellido Paterno:</label>
                <input
                  type="text"
                  className="form-control"
                  name="apellidoPaterno"
                  value={formData.apellidoPaterno}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <label>Apellido Materno:</label>
                <input
                  type="text"
                  className="form-control"
                  name="apellidoMaterno"
                  value={formData.apellidoMaterno}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4">
                <label>Sexo:</label>
                <select
                  className="form-control"
                  name="sexo"
                  value={formData.sexo}
                  onChange={handleInputChange}
                >
                  <option value="">==Seleccionar==</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Masculino">Masculino</option>
                </select>
              </div>
              <div className="col-md-4">
                <label>Celular:</label>
                <input
                  type="text"
                  className="form-control"
                  name="celular"
                  value={formData.celular}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <label>Correo Electrónico:</label>
                <input
                  type="email"
                  className="form-control"
                  name="correo"
                  value={formData.correo}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4">
                <label>Fecha de Nacimiento:</label>
                <input
                  type="date"
                  className="form-control"
                  name="fechaNacimiento"
                  value={formData.fechaNacimiento}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4" style={{ position: "relative" }}>
                <label>Iglesia:</label>
                <input
                  type="text"
                  className="form-control"
                  name="iglesia"
                  value={formData.iglesia}
                  onChange={handleInputChange} // Maneja los cambios en el campo dinámicamente
                  placeholder="Buscar iglesia..."
                />
              </div>
              <div className="col-md-4" style={{ position: "relative" }}>
                <label>Curso</label>
                <input
                  type="text"
                  className="form-control"
                  name="curso"
                  value={formData.curso}
                  onChange={handleInputChange} // Maneja los cambios en el campo dinámicamente
                  placeholder="Buscar curso..."
                />
              </div>
            </div>
          </div>
        </div>
        <button
          className="btn btn-success"
          onClick={() => generarPDF(formData)}
        >
          Imprimir Boleta
        </button>
      </form>
    </div>
  );
};

export default ImprimirBoleta;
