import React from 'react';
import './ProgramaBachillerato.css';

const DiplomadoEstudiosInterculturales = () => {
  return (
    <div className="programaBach-page">
      {/* Cabecera */}
      <header className="programaBach-header">
        <h1>Diplomado en Estudios Interculturales</h1>
        <p>Capacitación práctica para formar líderes en misiones y estudios interculturales.</p>
        <span className="nivelBach">Nivel Diplomado</span>
        <div className="calificacionesBach">
          ⭐⭐⭐⭐⭐ (9 Cursos - Duración: 1 año)
        </div>
      </header>

      {/* ¿Qué aprenderá? */}
      <section className="aprendizajeBach">
        <h2>¿Qué aprenderá?</h2>
        <p>
          Este diplomado está diseñado para equipar a líderes misioneros con competencias prácticas,
          teológicas y estratégicas en el contexto de las misiones interculturales.
        </p>
      </section>

      {/* Plan de estudios */}
      <section className="planEstudiosBach">
        <h2>Plan de Estudios</h2>
        <table>
          <thead>
            <tr>
              <th>Área</th>
              <th>Materias</th>
              <th>Duración</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Teología</td>
              <td>
                <ul>
                  <li>Teología Bíblica de la Misión</li>
                  <li>Bases Wesleyanas de la Misión</li>
                </ul>
              </td>
              <td>4 meses</td>
            </tr>
            <tr>
              <td>Antropología</td>
              <td>
                <ul>
                  <li>Antropología Cultural</li>
                  <li>Misiones Interculturales</li>
                </ul>
              </td>
              <td>4 meses</td>
            </tr>
            <tr>
              <td>Estrategia</td>
              <td>
                <ul>
                  <li>Evangelismo Integral</li>
                  <li>Estrategias Ministeriales</li>
                </ul>
              </td>
              <td>2 meses</td>
            </tr>
            <tr>
              <td>Cuidado Misionero</td>
              <td>
                <ul>
                  <li>Desarrollo Comunitario Integral</li>
                  <li>La IDN y el Envío de Misioneros</li>
                </ul>
              </td>
              <td>2 meses</td>
            </tr>
          </tbody>
        </table>
      </section>

      {/* Requisitos */}
      <section className="requisitosBach">
        <h2>Requisitos</h2>
        <ul>
          <li>Fotocopia de CI o DNI</li>
          <li>Formulario de inscripción</li>
          <li>Carta de recomendación pastoral</li>
          <li>Compromiso de participación en el servicio misionero final</li>
        </ul>
      </section>

      {/* Descripción */}
      <section className="descripcionBach">
        <h2>Descripción</h2>
        <p>
          Este diplomado consta de 9 cursos diseñados para proporcionar herramientas prácticas,
          conocimientos teológicos y competencias interculturales aplicadas al contexto misionero.
          El programa tiene una duración de 1 año, distribuido en áreas específicas como Teología,
          Antropología, Estrategia y Cuidado Misionero.
        </p>
      </section>
    </div>
  );
};

export default DiplomadoEstudiosInterculturales;
