import "./App.css";
import "aos/dist/aos.css";
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

// Importación de componentes públicos
import Encabezado from "./Componentes/Encabezado/Encabezado";
import PiePagina from "./Componentes/PiePagina/PiePagina";
import BotonWhatsapp from "./Componentes/BotonWhatsapp/BotonWhatsapp";
import Acceso from "./Componentes/Acceso/Acceso";
import SeccionBanner from "./Componentes/PaginaPrincipal/SeccionBanner/SeccionBanner";
import SeccionBienvenida from "./Componentes/PaginaPrincipal/SeccionBienvenida/SeccionBienvenida";
import SeccionDestacados from "./Componentes/PaginaPrincipal/SeccionDestacados/SeccionDestacados";
import SeccionRecorrido from "./Componentes/PaginaPrincipal/SeccionRecorrido/SeccionRecorrido";
import SeccionTestimonios from "./Componentes/PaginaPrincipal/SeccionTestimonios/SeccionTestimonios";
import SeccionInstituciones from "./Componentes/PaginaPrincipal/SeccionInstituciones/SeccionInstituciones";
import PaginaSobreNosotros from "./Componentes/PaginaSobreNosotros/PaginaSobreNosotros";
import PaginaOfertaAcademica from "./Componentes/OfertaAcademica/OfertaAcademica";
import ProgramaDiplomados from "./Componentes/OfertaAcademica/ProgramaDiplomados";
import ProgramaLicenciatura from "./Componentes/OfertaAcademica/ProgramaLicenciatura";
import ProgramaBachillerato from "./Componentes/OfertaAcademica/ProgramaBachillerato";
import ProgramaDiplomas from "./Componentes/OfertaAcademica/ProgramaDiplomas";
import ProgramaCursosComplementarios from "./Componentes/OfertaAcademica/ProgramaCursosComplementarios";
import PaginaNovedades from "./Componentes/PaginaNovedades/PaginaNovedades";
import NoticiaDetalle from "./Componentes/NoticiaDetalle/NoticiaDetalle";

import PaginaSoporteTecnico from "./Componentes/PaginaSoporteTecnico/PaginaSoporteTecnico";
import PerfilesAutoridades from "./Componentes/PerfilesAutoridades/PerfilesAutoridades";
import FormularioInscripcion from "./Componentes/FormularioInscripcion/FormularioInscripcion";

// Importación de componentes restringidos
import Dashboard from "./Componentes-Restringidos/Dashboard/Dashboard";
import AlumnoDatos from "./Componentes-Restringidos/ModuloGestionEstudiantes/AlumnoDatos/AlumnoDatos";
import ModificarEstudiante from "./Componentes-Restringidos/ModuloGestionEstudiantes/AlumnoDatos/ModificarEstudiante";
import ImprimirBoleta from "./Componentes-Restringidos/ModuloGestionEstudiantes/AlumnoDatos/ImprimirBoleta";
import PanelDeDatos from "./Componentes-Restringidos/ModuloPanelDeDatos/PanelDeDatos";
import GestionAsistencia from "./Componentes-Restringidos/ModuloCalificacionesAsistencia/GestionAsistencia";
import GestionCalificaciones from "./Componentes-Restringidos/ModuloCalificacionesAsistencia/GestionCalificaciones";
import MiAsistencia from "./Componentes-Restringidos/ModuloCalificacionesAsistencia/MiAsistencia";
import MisCalificaciones from "./Componentes-Restringidos/ModuloCalificacionesAsistencia/MisCalificaciones";
import ModuloNoticias from "./Componentes-Restringidos/ModuloNoticias/ModuloNoticias";
import MenuLateral from "./Componentes-Restringidos/MenuLateral/MenuLateral";
import BarraSuperior from "./Componentes-Restringidos/BarraSuperior/BarraSuperior";
import ProtectedRoute from "./Componentes/ProtectedRoute";

const App = () => {
  return (
    <>
      <Routes>
        {/* Rutas públicas con encabezado, pie de página y botón de WhatsApp */}
        <Route
          path="/"
          element={
            <LayoutPublico>
              <>
                <SeccionBanner />
                <SeccionBienvenida />
                <SeccionDestacados />
                <SeccionRecorrido />
                <SeccionTestimonios />
                <SeccionInstituciones />
              </>
            </LayoutPublico>
          }
        />
        <Route
          path="/sobre-nosotros"
          element={
            <LayoutPublico>
              <PaginaSobreNosotros />
            </LayoutPublico>
          }
        />
        <Route
          path="/oferta-academica"
          element={
            <LayoutPublico>
              <PaginaOfertaAcademica />
            </LayoutPublico>
          }
        />
        <Route
          path="/programa-diplomados"
          element={
            <LayoutPublico>
              <ProgramaDiplomados />
            </LayoutPublico>
          }
        />
        <Route
          path="/programa-licenciatura"
          element={
            <LayoutPublico>
              <ProgramaLicenciatura />
            </LayoutPublico>
          }
        />
        <Route
          path="/programa-Diplomas"
          element={
            <LayoutPublico>
              <ProgramaDiplomas />
            </LayoutPublico>
          }
        />
        <Route
          path="/programa-bachillerato"
          element={
            <LayoutPublico>
              <ProgramaBachillerato />
            </LayoutPublico>
          }
        />

        <Route
          path="/programa-cursosComplementarios"
          element={
            <LayoutPublico>
              <ProgramaCursosComplementarios />
            </LayoutPublico>
          }
        />
        <Route
          path="/novedades"
          element={
            <LayoutPublico>
              <PaginaNovedades />
            </LayoutPublico>
          }
        />

<Route 
  path="/detalle-novedades/:id" // Agregar :id para capturar el parámetro dinámico
  element={
    <LayoutPublico>
      <NoticiaDetalle />
    </LayoutPublico>
  }
/>


        <Route
          path="/perfiles-autoridades/:id"
          element={
            <LayoutPublico>
              <PerfilesAutoridades />
            </LayoutPublico>
          }
        />
        <Route
          path="/PaginaSoporteTecnico"
          element={
            <LayoutPublico>
              <PaginaSoporteTecnico />
            </LayoutPublico>
          }
        />
        <Route
          path="/inscripcion"
          element={
            <LayoutPublico>
              <FormularioInscripcion />
            </LayoutPublico>
          }
        />

        {/* Ruta de acceso sin encabezado, pie de página ni botón de WhatsApp */}
        <Route
          path="/acceso"
          element={
            <LayoutSinEncabezadoYPie>
              <Acceso />
            </LayoutSinEncabezadoYPie>
          }
        />

        {/* Rutas privadas con barra lateral y barra superior */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <LayoutPrivado>
                <Dashboard />
              </LayoutPrivado>
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-estudiantes"
          element={
            <ProtectedRoute>
              <LayoutPrivado>
                <AlumnoDatos />
              </LayoutPrivado>
            </ProtectedRoute>
          }
        />
        <Route
          path="/modificar-estudiante/:codigoEstudiante"
          element={
            <ProtectedRoute>
              <LayoutPrivado>
                <ModificarEstudiante />
              </LayoutPrivado>
            </ProtectedRoute>
          }
        />
        <Route
          path="/imprimir-estudiante/:codigoEstudiante"
          element={
            <ProtectedRoute>
              <LayoutPrivado>
                < ImprimirBoleta />
              </LayoutPrivado>
            </ProtectedRoute>
          }
        />
       
        <Route
          path="/panel-datos"
          element={
            <ProtectedRoute>
              <LayoutPrivado>
                <PanelDeDatos />
              </LayoutPrivado>
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-asistencia"
          element={
            <ProtectedRoute>
              <LayoutPrivado>
                <GestionAsistencia />
              </LayoutPrivado>
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-calificaciones"
          element={
            <ProtectedRoute>
              <LayoutPrivado>
                <GestionCalificaciones />
              </LayoutPrivado>
            </ProtectedRoute>
          }
        />
        <Route
          path="/mis-calificaciones"
          element={
            <ProtectedRoute>
              <LayoutPrivado>
                <MisCalificaciones />
              </LayoutPrivado>
            </ProtectedRoute>
          }
        />
        <Route
          path="/mi-asistencia"
          element={
            <ProtectedRoute>
              <LayoutPrivado>
                <MiAsistencia />
              </LayoutPrivado>
            </ProtectedRoute>
          }
        />
        <Route
          path="/noticias"
          element={
            <ProtectedRoute>
              <LayoutPrivado>
                <ModuloNoticias />
              </LayoutPrivado>
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

// Layout para rutas públicas (con encabezado, pie de página y botón de WhatsApp)
const LayoutPublico = ({ children }) => (
  <>
    <Encabezado />
    <main>{children}</main>
    <PiePagina />
    <BotonWhatsapp /> {/* Botón de WhatsApp solo aquí */}
  </>
);

// Layout sin encabezado ni pie de página
const LayoutSinEncabezadoYPie = ({ children }) => (
  <main className="layout-sin-encabezado-pie">{children}</main>
);

// Layout para rutas privadas (con barra lateral y barra superior)
const LayoutPrivado = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="layout-container">
      <MenuLateral isCollapsed={isCollapsed} />
      <div
        className={`contenido-principalDa ${isCollapsed ? "colapsado" : ""}`}
      >
        <BarraSuperior toggleMenu={toggleMenu} isCollapsed={isCollapsed} />
        <div className="contenidoDa">{children}</div>
      </div>
    </div>
  );
};

export default App;
