import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";

const ModificarEstudiante = () => {
  const { codigoEstudiante } = useParams(); // Obtener el código del estudiante de la URL
  const [searchTerm, setSearchTerm] = useState(""); // Estado para manejar el término de búsqueda
  const [formData, setFormData] = useState({
    codigoEstudiante: "Código Estudiante",
    periodoIngreso: "",
    turno: "Mañana",
    nivel: "Licenciatura",
    modalidad: "REGULAR",
    ciclo: "1",
    foto: null,
    estado: "Activo",
    dni: "",
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    sexo: "",
    celular: "",
    correo: "",
    fechaNacimiento: "",
    pais: "",
    ciudad: "",
    direccion: "",
    iglesia: "",
    distrito: "",
    fotocopiaCI: null,
    formulario1: null,
    formulario2: null,
    formulario3: null,
    comprobantePago: null,
    matricula: null,
  });

  // Array con todas las iglesias
  const iglesias = [
    "16 DE FEBRERO",
    "16 DE JULIO",
    "2 DE FEBRERO",
    "6 DE JUNIO",
    "7 DE SEPTIEMBRE",
    "ACHICA ARRIBA",
    "ALTO CHIJINI",
    "ALTO LIMA",
    "ANTACOLLO",
    "APOSENTO ALTO",
    "BAUTISTA SAAVEDRA",
    "BELÉN DE SANTA ANA",
    "BETANIA",
    "CALAQUE WICHIWICHI",
    "CALATA CAPURITA",
    "CALATA GRANDE",
    "CALERÍA",
    "CANTAPA",
    "CATAVI",
    "CENTRAL CALLA",
    "CENTRAL COPAJIRA",
    "CENTRO PUTINA",
    "CHIPAMAYA",
    "CORPAPUTO",
    "DIGNIDAD",
    "EL ALTO: FILADELFIA",
    "EL PARAÍSO",
    "EL PROGRESO",
    "FORTALEZA",
    "GERMÁN BUSCH (Luz y Vida)",
    "HUACULLANI",
    "HUARINA",
    "HUAYNAPOTOSI PALCOCO",
    "ISLA SURIQUI",
    "JAILLIHUAYA",
    "JANKO AMAYA",
    "JESÚS DE MACHACA",
    "KENAKAHUA",
    "KERANI CENTRAL",
    "KHONKHO LIQUILIQUI",
    "LA FLORIDA",
    "LEQUIÑOSO",
    "MARISCAL SUCRE",
    "MUCUÑA",
    "NAZARENO LUZ DE CRISTO",
    "NUEVA JERUSALÉN",
    "PACAJES",
    "PARIRI",
    "PEÑAS",
    "POCOHOTA",
    "PONGONHUYO BERENGUELA",
    "PONGONHUYO CENTRAL",
    "PRIMERO DE MAYO",
    "PUCARANI",
    "PUCHUNI",
    "RÍO SECO",
    "SAANCHI",
    "SAJAMA",
    "SAMANCHA",
    "SAN FELIPE DE SEK'E",
    "SANTA ROSA (ex Villa Tejada)",
    "SANTA ROSA DE TARACO",
    "SAYHUAPAMPA (Carabuco)",
    "SEGUENCA",
    "SENKATA",
    "SURAMAYA",
    "TACACA",
    "TIQUINA",
    "TUQUIA",
    "VILLA ABAROA",
    "VILLA EXALTACIÓN",
    "VILLA MERCEDES",
    "VILLA NATIVIDAD",
    "VILLA PALESTINA",
    "VILLA SANTIAGO I",
    "VILLA SANTIAGO II",
    "VILUYO",
    "CONDORCHINOCA",
    "IRUMA",
    "CHOJNACOTA",
    "POCOKAHUA",
    "VENTILLITA",
    "CENTRAL ORURO",
    "NUEVA VIDA KANTUTA",
    "SAN MIGUEL",
    "MACHACAMARCA",
    "LOS PINOS",
    "COORDENADOR",
    "FUENTE DE VIDA",
    "TUPIZA",
    "BANDERANI",
    "HUAYLLATIRA",
    "TOLAPALCA",
    "PUCARA",
    "BARRIO PERIODISTA",
    "BASE AÉREA",
    "CALA CALA",
    "CLIZA",
    "NAZARENO PUCARA (inactiva)",
    "QUILLACOLLO",
    "TOCO PARADA",
    "ILLA HIROSHIMA",
    "VILLA IMPERIAL",
    "VILLA MÉJICO",
    "TIERRA DE ESPERANZA",
    "MISIÓN TRINIDAD",
    "CHULUMANI",
    "HUIRI ESMERALDA",
    "HUIRI NOGALANI",
    "UNIÓN HUIRI",
    "VILLA COPALANI",
    "TUCUPI",
    "PALOS BLANCOS",
    "BRECHA F",
    "LUZ Y VIDA",
    'AUQUI SAMAÑA" (Inactiva)',
    "SANTIAGO TOCORONI",
    "CENTRO TOCORONI",
    "CONCHITA GRANDE",
    "CENTRO CONCHITA",
    "CONCHITA CHICO",
    "SAN SILVERIO",
    "CARANAVI",
    "CULTURAL UNIDO",
    "BOLINDA",
    "ILLIMANI LOS ANDES",
    "ALCOCHE",
    "LOS ANDES",
    "TRAPICHIPONTE",
    "WACACALA",
    "BELÉN DE COACHIA",
    "ANTARANI",
    "AVIRCATO",
    "BOTIJLACA",
    "CALACACHI",
    "CALARI",
    "CALASAYA",
    "CALTECA",
    "CALLIRPA",
    "CANTUYO",
    "CAQUIAVIRI",
    "COHONI",
    "CONIRI",
    "COLQUE ALTA",
    "COLQUENCHA",
    "COMANCHE",
    "COROCORO",
    "CHAPICHAPINI",
    "CHIPANAMAYA",
    "CHULLUNKHAYANI",
    "ENEQUELLA",
    "GENERAL PANDO",
    "HILATA SAN JORGE",
    "IRPUMA",
    "JEK'ERI",
    "KELLA KELLA BAJA",
    "LACA",
    "ARANJUEZ",
    "BAJO PAMPAHASI",
    "BELLA VISTA",
    "BUENOS AIRES",
    "CENTRAL LA PAZ",
    "CODAVISA",
    "COTA COTA",
    "EL BUEN PASTOR",
    "ESCOBAR URÍA",
    "KOINONÍA",
    "LA PORTADA",
    "MEMORIAL WINCHESTER",
    "MIRAFLORES",
    "MUNAYPATA",
    "PASANKERI",
    "PLAYA VERDE BAJO SOPOCACHI",
    "23 DE MARZO",
    "VILLA FÁTIMA",
    "LLIMPHI",
    "NUEVA TILATA 3",
    "PATACAMAYA",
    "PLAYA VERDE",
    "PUTUNI",
    "ROSAPATA DE TULI",
    "ROSAPATA YARIBAY",
    "SICUIPATA",
    "TACACHIA",
    "TOMATA",
    "TOPOHOCO",
  ];
  // Filtro de iglesias basado en el término de búsqueda
  const filteredIglesias = useMemo(() => {
    return iglesias.filter((iglesia) =>
      iglesia.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, iglesias]);

  // Función para manejar la selección de la iglesia desde la lista filtrada
  const handleSelect = (value) => {
    setFormData({ ...formData, iglesia: value }); // Establece la iglesia seleccionada
    setSearchTerm(""); // Limpia el campo de búsqueda
  };

  const navigate = useNavigate(); // Para redirigir después de actualizar

  // Cargar datos del estudiante al montar el componente
  useEffect(() => {
    const obtenerDatosEstudiante = async () => {
      try {
        const response = await axios.get(
          `http://localhost:5000/api/estudiantes/${codigoEstudiante}`
        );

        const estudianteData = response.data;

        // Asegúrate de convertir la fecha a formato 'YYYY-MM-DD' si es necesario
        const fechaNacimiento = estudianteData.fechaNacimiento
          ? new Date(estudianteData.fechaNacimiento).toISOString().split("T")[0]
          : ""; // Si no hay fecha, se asigna un string vacío

        setFormData({
          codigoEstudiante: estudianteData.codigoEstudiante,
          periodoIngreso: estudianteData.periodoIngreso,
          turno: estudianteData.turno,
          nivel: estudianteData.nivel,
          modalidad: estudianteData.modalidad,
          ciclo: estudianteData.ciclo,
          foto: estudianteData.foto || null,
          estado: estudianteData.estado,
          dni: estudianteData.dni,
          nombres: estudianteData.nombres,
          apellidoPaterno: estudianteData.apellidoPaterno,
          apellidoMaterno: estudianteData.apellidoMaterno,
          sexo: estudianteData.sexo,
          celular: estudianteData.celular,
          correo: estudianteData.correo,
          fechaNacimiento, // Asignamos la fecha formateada
          pais: estudianteData.pais,
          ciudad: estudianteData.ciudad,
          direccion: estudianteData.direccion,
          iglesia: estudianteData.iglesia,
          distrito: estudianteData.distrito,
          fotocopiaCI: estudianteData.fotocopiaCI || null,
          formulario1: estudianteData.formulario1 || null,
          formulario2: estudianteData.formulario2 || null,
          formulario3: estudianteData.formulario3 || null,
          comprobantePago: estudianteData.comprobantePago || null,
          matricula: estudianteData.matricula || null,
        });
      } catch (error) {
        console.error("Error al cargar los datos del estudiante:", error);
        alert("No se pudieron cargar los datos del estudiante");
      }
    };

    obtenerDatosEstudiante();
  }, [codigoEstudiante]);

  const handleInputChange = (e) => {
    const { name, value } = e.target; // Extraemos el nombre y valor del campo

    // Actualizamos el estado general del formulario
    setFormData({
      ...formData,
      [name]: value,
    });

    // Si el campo modificado es "iglesia", también actualizamos el término de búsqueda
    if (name === "iglesia") {
      setSearchTerm(value); // Actualizamos el término de búsqueda
    }
  };

  // Manejar el cambio de archivos
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0], // Asigna el archivo seleccionado al campo correspondiente
    });
  };

  // Manejador del formulario para guardar cambios
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    // Agregar los datos al FormData para enviarlos
    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        formDataToSend.append(key, formData[key]);
      }
    });

    try {
      const response = await axios.put(
        `http://localhost:5000/api/estudiantes/${codigoEstudiante}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("Estudiante actualizado correctamente");
    } catch (error) {
      console.error("Error al actualizar estudiante:", error);
      alert("Error al actualizar el estudiante");
    }
  };

  return (
    <div className="container">
      <h2>Modificar Estudiante</h2>
      <form onSubmit={handleSubmit}>
        <div className="custom-box">
          <button className="custom-button">.: DATOS ACADÉMICOS :.</button>
          <div className="form-fields d-flex">
            <div className="w-75">
              <div className="row mb-3">
                <div className="col-md-6">
                  <label>Código de Estudiante:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="codigoEstudiante"
                    value={formData.codigoEstudiante}
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label>Periodo de Ingreso:</label>
                  <select
                    className="form-control"
                    name="periodoIngreso"
                    value={formData.periodoIngreso}
                    onChange={handleInputChange}
                  >
                    <option value="I-2024">I-2024</option>
                    <option value="II-2024">II-2024</option>
                    <option value="I-2023">I-2023</option>
                    {/* Más opciones */}
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label>Nivel:</label>
                  <select
                    className="form-control"
                    name="nivel"
                    value={formData.nivel}
                    onChange={handleInputChange}
                  >
                    <option value="LICENCIATURA">Licenciatura</option>
                    <option value="BACHILLERATO">Bachillerato</option>
                    <option value="CURSO_COMPLEMENTARIO">
                      Cursos Complementarios
                    </option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label>Modalidad:</label>
                  <select
                    className="form-control"
                    name="modalidad"
                    value={formData.modalidad}
                    onChange={handleInputChange}
                  >
                    <option value="REGULAR">Regular</option>
                    <option value="MODULAR">Modular</option>
                    <option value="INTENSIVO">Intensivo</option>
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label>Ciclo Académico:</label>
                  <select
                    className="form-control"
                    name="ciclo"
                    value={formData.ciclo}
                    onChange={handleInputChange}
                  >
                    {[...Array(10).keys()].map((i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <label>Estado:</label>
                  <select
                    name="estado"
                    className="form-control"
                    value={formData.estado}
                    onChange={handleInputChange}
                  >
                    <option value="Activo">Activo</option>
                    <option value="Inactivo">Inactivo</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Columna para la imagen */}
            <div className="w-25 text-center">
              {/* Columna para la imagen, ocupa el 25% */}
              <img
                src={
                  typeof formData.foto === "string"
                    ? `http://localhost:5000/${formData.foto}` // Si la imagen es una cadena (ruta del servidor), concatenamos con la URL base
                    : formData.foto instanceof Blob
                    ? URL.createObjectURL(formData.foto) // Si la imagen es un archivo local, la previsualizamos con createObjectURL
                    : "/Imagenes/Avatar.jpg" // Imagen por defecto
                }
                alt="Imagen de perfil"
                className="img-thumbnail"
                style={{
                  width: "150px",
                  height: "200px",
                  objectFit: "cover",
                  marginBottom: "10px",
                }}
              />
              <p>
                {formData.foto ? "Foto cargada" : "Fotografía no disponible"}
              </p>
              <label htmlFor="upload-button" className="btn btn-info mt-2">
                <i className="fas fa-upload"></i> Subir imagen
              </label>
              <input
                type="file"
                id="upload-button"
                className="d-none"
                name="foto"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>

        <div className="custom-box">
          <button className="custom-button">.: DATOS PERSONALES :.</button>
          <div className="form-fields">
            <div className="row mb-3">
              <div className="col-md-4">
                <label>DNI:</label>
                <input
                  type="text"
                  className="form-control"
                  name="dni"
                  value={formData.dni}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4">
                <label>Nombres:</label>
                <input
                  type="text"
                  className="form-control"
                  name="nombres"
                  value={formData.nombres}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4">
                <label>Apellido Paterno:</label>
                <input
                  type="text"
                  className="form-control"
                  name="apellidoPaterno"
                  value={formData.apellidoPaterno}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <label>Apellido Materno:</label>
                <input
                  type="text"
                  className="form-control"
                  name="apellidoMaterno"
                  value={formData.apellidoMaterno}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4">
                <label>Sexo:</label>
                <select
                  className="form-control"
                  name="sexo"
                  value={formData.sexo}
                  onChange={handleInputChange}
                >
                  <option value="">==Seleccionar==</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Masculino">Masculino</option>
                </select>
              </div>
              <div className="col-md-4">
                <label>Celular:</label>
                <input
                  type="text"
                  className="form-control"
                  name="celular"
                  value={formData.celular}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <label>Correo Electrónico:</label>
                <input
                  type="email"
                  className="form-control"
                  name="correo"
                  value={formData.correo}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4">
                <label>Fecha de Nacimiento:</label>
                <input
                  type="date"
                  className="form-control"
                  name="fechaNacimiento"
                  value={formData.fechaNacimiento}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4" style={{ position: "relative" }}>
                <label>Iglesia:</label>
                <input
                  type="text"
                  className="form-control"
                  name="iglesia"
                  value={formData.iglesia}
                  onChange={handleInputChange} // Maneja los cambios en el campo dinámicamente
                  placeholder="Buscar iglesia..."
                />

                {/* Lista desplegable de iglesias */}
                {searchTerm && (
                  <ul className="mi-lista">
                    {filteredIglesias.map((iglesia, index) => (
                      <li
                        key={index}
                        className="mi-lista-item"
                        onClick={() => handleSelect(iglesia)} // Selecciona la iglesia
                      >
                        {iglesia}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <label>País:</label>
                <Select
                  options={[
                    { value: "BO", label: "Bolivia" },
                    { value: "PE", label: "Perú" },
                    // Agrega más países
                  ]}
                  value={{ value: formData.pais, label: formData.pais }}
                  onChange={(selectedOption) =>
                    setFormData({ ...formData, pais: selectedOption.value })
                  }
                  styles={{
                    control: (base) => ({
                      ...base,
                      width: "100%", // Ajustar el ancho
                    }),
                  }}
                />
              </div>
              <div className="col-md-4">
                <label>Ciudad:</label>
                <input
                  type="text"
                  className="form-control"
                  name="ciudad"
                  value={formData.ciudad}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4">
                <label>Dirección:</label>
                <input
                  type="text"
                  className="form-control"
                  name="direccion"
                  value={formData.direccion}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4">
                <label>Distrito:</label>
                <input
                  type="text"
                  className="form-control"
                  name="distrito"
                  value={formData.distrito}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="custom-box">
          <button className="custom-button">.: DOCUMENTOS DIGITALES :.</button>
          <div className="form-fields">
            <div className="row mb-3">
              {/* Fotocopia CI */}
              <div className="col-md-6">
                <label>Fotocopia CI:</label>
                <input
                  type="file"
                  className="form-control"
                  name="fotocopiaCI"
                  accept="application/pdf"
                  onChange={handleFileChange}
                />
                {formData.fotocopiaCI ? (
                  <div>
                    {/* Verificar si es un archivo local (File) o una URL del servidor */}
                    <iframe
                      src={
                        formData.fotocopiaCI instanceof File
                          ? URL.createObjectURL(formData.fotocopiaCI) // Si es un archivo nuevo, previsualizamos
                          : `http://localhost:5000/${formData.fotocopiaCI}` // Si es una URL, la mostramos
                      }
                      width="100%"
                      height="200px"
                      style={{ border: "none" }}
                      title="Previsualización PDF"
                    ></iframe>
                    <a
                      href={`http://localhost:5000/${formData.fotocopiaCI}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-info"
                      style={{ marginTop: "10px" }}
                    >
                      Visualizar/Abrir
                    </a>
                  </div>
                ) : (
                  <div>
                    <img
                      src="/Imagenes/icono-pdf.png"
                      alt="No hay archivo"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginBottom: "10px",
                      }}
                    />
                    <p>No se ha cargado ningún archivo</p>
                  </div>
                )}
              </div>

              {/* Formulario 1 */}
              <div className="col-md-6">
                <label>Formulario 1:</label>
                <input
                  type="file"
                  className="form-control"
                  name="formulario1"
                  accept="application/pdf"
                  onChange={handleFileChange}
                />
                {formData.formulario1 ? (
                  <div>
                    {/* Verificar si el archivo es un nuevo archivo (Blob) o un archivo ya cargado (URL) */}
                    {formData.formulario1 instanceof File ? (
                      // Si es un archivo local, previsualizamos
                      <iframe
                        src={URL.createObjectURL(formData.formulario1)}
                        width="100%"
                        height="200px"
                        style={{ border: "none" }}
                        title="Previsualización PDF"
                      ></iframe>
                    ) : (
                      // Si es una URL, mostramos el PDF
                      <iframe
                        src={`http://localhost:5000/${formData.formulario1}`}
                        width="100%"
                        height="200px"
                        style={{ border: "none" }}
                        title="Previsualización PDF"
                      ></iframe>
                    )}
                    <a
                      href={`http://localhost:5000/${formData.formulario1}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-info"
                      style={{ marginTop: "10px" }}
                    >
                      Visualizar/Abrir
                    </a>
                  </div>
                ) : (
                  <div>
                    <img
                      src="/Imagenes/icono-pdf.png"
                      alt="No hay archivo"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginBottom: "10px",
                      }}
                    />
                    <p>No se ha cargado ningún archivo</p>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-3">
              {/* Formulario 2 */}
              <div className="col-md-6">
                <label>Formulario 2:</label>
                <input
                  type="file"
                  className="form-control"
                  name="formulario2"
                  accept="application/pdf, image/png, image/jpeg, image/jpg"
                  onChange={handleFileChange}
                />
                {formData.formulario2 ? (
                  // Verificar si el archivo es una imagen o un PDF
                  formData.formulario2 instanceof Blob ? (
                    formData.formulario2.type.startsWith("image/") ? (
                      <div>
                        {/* Mostrar imagen previsualizada */}
                        <img
                          src={URL.createObjectURL(formData.formulario2)}
                          alt="Previsualización"
                          width="100%"
                          height="auto"
                          style={{
                            border: "1px solid #ccc",
                            padding: "10px",
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        {/* Mostrar PDF en un iframe */}
                        <iframe
                          src={URL.createObjectURL(formData.formulario2)}
                          width="100%"
                          height="200px"
                          style={{ border: "none" }}
                          title="Previsualización PDF"
                        ></iframe>
                        <a
                          href={URL.createObjectURL(formData.formulario2)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-info"
                          style={{ marginTop: "10px" }}
                        >
                          Visualizar/Abrir
                        </a>
                      </div>
                    )
                  ) : (
                    <div>
                      <img
                        src="/Imagenes/icono-pdf.png"
                        alt="No hay archivo"
                        style={{
                          width: "50px",
                          height: "50px",
                          marginBottom: "10px",
                        }}
                      />
                      <p>No se ha cargado ningún archivo</p>
                    </div>
                  )
                ) : (
                  // Cuando aún no se ha seleccionado ningún archivo
                  <div>
                    <p>No se ha seleccionado ningún archivo.</p>
                  </div>
                )}
              </div>

              {/* Formulario 3 */}
              <div className="col-md-6">
                <label>Formulario 3:</label>
                <input
                  type="file"
                  className="form-control"
                  name="formulario3"
                  accept="application/pdf"
                  onChange={handleFileChange}
                />
                {formData.formulario3 ? (
                  <div>
                    <iframe
                      src={`http://localhost:5000/${formData.formulario3}`}
                      width="100%"
                      height="200px"
                      style={{ border: "none" }}
                      title="Previsualización PDF"
                    ></iframe>
                    <a
                      href={`http://localhost:5000/${formData.formulario3}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-info"
                      style={{ marginTop: "10px" }}
                    >
                      Visualizar/Abrir
                    </a>
                  </div>
                ) : (
                  <div>
                    <img
                      src="/Imagenes/icono-pdf.png"
                      alt="No hay archivo"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginBottom: "10px",
                      }}
                    />
                    <p>No se ha cargado ningún archivo</p>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-3">
              {/* Boleta de pago */}
              <div className="col-md-6">
                <label>Boleta de Pago:</label>
                <input
                  type="file"
                  className="form-control"
                  name="comprobantePago"
                  accept="application/pdf, image/png, image/jpeg, image/jpg"
                  onChange={handleFileChange}
                />
                {formData.comprobantePago ? (
                  // Verificar si el archivo es una imagen o un PDF
                  formData.comprobantePago instanceof Blob ? (
                    formData.comprobantePago.type.startsWith("image/") ? (
                      <div>
                        {/* Mostrar imagen previsualizada */}
                        <img
                          src={URL.createObjectURL(formData.comprobantePago)}
                          alt="Previsualización"
                          width="100%"
                          height="auto"
                          style={{
                            border: "1px solid #ccc",
                            padding: "10px",
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        {/* Mostrar PDF en un iframe */}
                        <iframe
                          src={URL.createObjectURL(formData.comprobantePago)}
                          width="100%"
                          height="200px"
                          style={{ border: "none" }}
                          title="Previsualización PDF"
                        ></iframe>
                        <a
                          href={URL.createObjectURL(formData.comprobantePago)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-info"
                          style={{ marginTop: "10px" }}
                        >
                          Visualizar/Abrir
                        </a>
                      </div>
                    )
                  ) : (
                    <div>
                      <img
                        src="/Imagenes/icono-pdf.png"
                        alt="No hay archivo"
                        style={{
                          width: "50px",
                          height: "50px",
                          marginBottom: "10px",
                        }}
                      />
                      <p>No se ha cargado ningún archivo</p>
                    </div>
                  )
                ) : (
                  // Cuando aún no se ha seleccionado ningún archivo
                  <div>
                    <p>No se ha seleccionado ningún archivo.</p>
                  </div>
                )}
              </div>

              {/* Matricula */}
              <div className="col-md-6">
                <label>Matricula:</label>
                <input
                  type="file"
                  className="form-control"
                  name="matricula"
                  accept="application/pdf, image/png, image/jpeg, image/jpg"
                  onChange={handleFileChange}
                />
                {formData.matricula ? (
                  // Verificar si el archivo es una imagen o un PDF
                  formData.matricula instanceof Blob ? (
                    formData.matricula.type.startsWith("image/") ? (
                      <div>
                        {/* Mostrar imagen previsualizada */}
                        <img
                          src={URL.createObjectURL(formData.matricula)}
                          alt="Previsualización"
                          width="100%"
                          height="auto"
                          style={{
                            border: "1px solid #ccc",
                            padding: "10px",
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        {/* Mostrar PDF en un iframe */}
                        <iframe
                          src={URL.createObjectURL(formData.matricula)}
                          width="100%"
                          height="200px"
                          style={{ border: "none" }}
                          title="Previsualización PDF"
                        ></iframe>
                        <a
                          href={URL.createObjectURL(formData.matricula)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-info"
                          style={{ marginTop: "10px" }}
                        >
                          Visualizar/Abrir
                        </a>
                      </div>
                    )
                  ) : (
                    <div>
                      <img
                        src="/Imagenes/icono-pdf.png"
                        alt="No hay archivo"
                        style={{
                          width: "50px",
                          height: "50px",
                          marginBottom: "10px",
                        }}
                      />
                      <p>No se ha cargado ningún archivo</p>
                    </div>
                  )
                ) : (
                  // Cuando aún no se ha seleccionado ningún archivo
                  <div>
                    <p>No se ha seleccionado ningún archivo.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary">
          Guardar Cambios
        </button>
      </form>
    </div>
  );
};

export default ModificarEstudiante;
