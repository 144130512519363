import React, { useState } from "react";
import "./PaginaSobreNosotros.css";

const PaginaSobreNosotros = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Función para abrir el modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Función para cerrar el modal
  const closeModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(false);
  };

  return (
    <div className="menu-contenedor4">
      {/* Contenedor Principal con imagen de fondo */}
      <div className="header-image-containerNu">
        <div className="overlayNo">
          <div className="texto-contenedor">
            <span className="subtitulo">
              EQUIPANDO SIERVOS PARA LA GRAN COMISIÓN
            </span>
            <h1 className="titulo-degradado2">Más de 70 años de trayectoria</h1>
            <p>
              Después oí la voz del Señor, que decía: ‘¿A quién enviaré, y quién
              irá por nosotros?’ Entonces respondí: ‘Heme aquí, envíame a mí.
            </p>
            <button className="btn-video" onClick={openModal}>
              Ver video <i className="fa fa-play-circle"></i>
            </button>
          </div>
          <div className="imagen-derecha">
            <img src="/Imagenes/QuienesSomos.png" alt="Estudiante" />
          </div>
        </div>
      </div>

      {/* Modal de video */}
      {isModalOpen && (
        <div className="video-modal" onClick={closeModal}>
          <div
            className="video-modal-contentNo"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close-modal" onClick={closeModal}>
              &times;
            </span>
            <video controls autoPlay>
              <source
                src="/Videos/Video Recorrido MisionVision.mp4"
                type="video/mp4"
              />
              Tu navegador no soporta el elemento de video.
            </video>
          </div>
        </div>
      )}

      {/* Sección historia */}
      <div className="contenido-Historia">
        <div className="historia-container">
          <div className="linea-decorativaNo"></div>
          <div className="tarjeta-historia">
            <div className="contenido-tarjetaNo texto-animado">
              <h2 className="subtitulo-historia">Nuestra Historia</h2>
              <p className="texto-justificadoNo">
                El Seminario Nazareno Boliviano, conocido hoy como SENABOL,
                comenzó su misión educativa en febrero de 1953 como una Escuela
                Bíblica en la ciudad de La Paz, con ocho estudiantes en las
                instalaciones de la calle 6 de Agosto. A finales de ese mismo
                año, se consolidó como Instituto Bíblico, logrando matricular a
                40 estudiantes. En 1955, se adquirió una finca de 5 hectáreas en
                Aranjuez, donde los misioneros Taylor y su esposa iniciaron la
                construcción de nuevas instalaciones que fueron inauguradas en
                1958, marcando un importante avance en su desarrollo.
              </p>
              <br></br>
              <p className="texto-justificadoNo">
                En 1980,bajo la dirección del misionero Daniel Brewer, la
                institución dio un nuevo paso académico y comenzó a operar como
                el Seminario Nazareno Boliviano, con la capacidad de otorgar el
                título de Bachiller en Teología. En 2003, adoptó el nombre de
                "Seminario Teológico Nazareno de Bolivia" (STNB), manteniendo su
                compromiso con una formación académica sólida y relevante. Entre
                los años 2009 y 2016, el seminario fue conocido como SENAC
                (Seminario Nazareno del Área Central), extendiendo su alcance a
                países vecinos como Paraguay. Finalmente, en 2017, retomó su
                nombre original como Seminario Nazareno Boliviano (SENABOL),
                reafirmando su identidad y misión. Desde 1953 hasta 2024,
                SENABOL ha sido un pilar de la educación teológica en Bolivia,
                formando a innumerables estudiantes y avanzando de manera
                constante hacia la excelencia académica y espiritual.
              </p>
            </div>
            <img
              src="/Imagenes/docentes.jpg"
              alt="Inicio y Crecimiento"
              className="imagen-historia hover-imagen"
            />
          </div>
          <div className="adorno-puntosredondosSo"></div>
          <div className="circulo-decorativoHi"></div>
        </div>

        {/* contenedor mision vision ****************************************************************************************/}

        <div className="ContenedorMisionVision">
          <div className="recuadro-transparente">
            <div className="titulo-estrategico">
              <span className="subtitulo-pequeno">SENABOL</span>
              <h2 className="declaracion-titulo">
                Dirección Estratégica y Objetivos
              </h2>
            </div>
            <div className="declaracion-container">
              <div className="declaracion-tarjeta tarjeta-animada">
                <div className="icono-container">
                  <div className="icono-lineaX"></div>
                  <div className="icono-circuloX">
                    <svg
                      className="icono-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 256 256"
                    >
                      <path d="m225.27 60.21l-96-32a4 4 0 0 0-2.54 0l-96 32A4 4 0 0 0 28 64v80a4 4 0 0 0 8 0V69.55l43.88 14.63a60 60 0 0 0 24.54 91c-20.86 5.74-39 19.13-51.77 38.65a4 4 0 0 0 6.7 4.36C75.17 193.92 100.2 180 128 180s52.83 13.92 68.65 38.18a4 4 0 0 0 6.7-4.36c-12.72-19.52-30.91-32.91-51.77-38.65a60 60 0 0 0 24.54-91l49.15-16.39a4 4 0 0 0 0-7.58ZM180 120a52 52 0 1 1-92.07-33.14l38.8 12.93a3.95 3.95 0 0 0 2.54 0l38.8-12.93A51.85 51.85 0 0 1 180 120m-52-28.22L44.65 64L128 36.22L211.35 64Z" />
                    </svg>
                  </div>
                </div>
                <div className="valores-section">
                  <h4 style={{ color: "#A00404" }}>Misión</h4>
                  <p>
                    La misión del SEMINARIO NAZARENO BOLIVIANO (SENABOL) es
                    servir primordialmente a la Iglesia del Nareno en Bolivia,
                    Formando teológica e integralmente a personas con vocación
                    de servicio, desde la perspectiva Arminiana – Wesleyana.
                  </p>
                </div>
              </div>

              <div className="declaracion-tarjeta tarjeta-animada">
                <div className="icono-container">
                  <div className="icono-lineaY"></div>
                  <div className="icono-circuloY">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icono-svg"
                      viewBox="0 0 256 256"
                    >
                      <path d="M237 147.44a4 4 0 0 1-5.48-1.4c-8.33-14-20.93-22-34.56-22a4 4 0 0 1-1.2-.2a37 37 0 0 1-3.8.2a4 4 0 0 1 0-8a28 28 0 1 0-27.12-35a4 4 0 0 1-7.75-2a36 36 0 1 1 54 39.48c10.81 3.85 20.51 12 27.31 23.48a4 4 0 0 1-1.4 5.44M187.46 214a4 4 0 0 1-1.46 5.46a3.93 3.93 0 0 1-2 .54a4 4 0 0 1-3.46-2a61 61 0 0 0-105.08 0a4 4 0 0 1-6.92-4a68.35 68.35 0 0 1 39.19-31a44 44 0 1 1 40.54 0a68.35 68.35 0 0 1 39.19 31M128 180a36 36 0 1 0-36-36a36 36 0 0 0 36 36m-64-64a28 28 0 1 1 27.12-35a4 4 0 0 0 7.75-2a36 36 0 1 0-53.57 39.75a63.55 63.55 0 0 0-32.5 22.85a4 4 0 0 0 6.4 4.8A55.55 55.55 0 0 1 64 124a4 4 0 0 0 0-8" />
                    </svg>
                  </div>
                </div>
                <div className="valores-section">
                  <h4 style={{ color: "#A00404" }}>Visión</h4>
                  <p>
                    Senabol es una institución referente en formación teológica,
                    con programas contextualizados, eficiente en su atención y
                    servicio integral a los estudiantes, con docentes
                    especializados, para el cumplimiento de la gran comisión,
                    enfatizando la vida en santidad: practica y social.
                  </p>
                </div>
              </div>

              <div className="declaracion-tarjeta tarjeta-animada">
                <div className="icono-container">
                  <div className="icono-lineaZ"></div>
                  <div className="icono-circuloZ">
                    <svg
                      className="icono-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 256 256"
                    >
                      <path d="M226.06 148.57L188 125.74V104a4 4 0 0 0-2-3.47l-54-30.85V44h20a4 4 0 0 0 0-8h-20V16a4 4 0 0 0-8 0v20h-20a4 4 0 0 0 0 8h20v25.68l-54 30.85a4 4 0 0 0-2 3.47v21.74l-38.06 22.83A4 4 0 0 0 28 152v64a4 4 0 0 0 4 4h80a4 4 0 0 0 4-4v-48a12 12 0 0 1 24 0v48a4 4 0 0 0 4 4h80a4 4 0 0 0 4-4v-64a4 4 0 0 0-1.94-3.43M36 154.26l32-19.2V212H36Zm92-6.26a20 20 0 0 0-20 20v44H76V106.32l52-29.71l52 29.71V212h-32v-44a20 20 0 0 0-20-20m92 64h-32v-76.94l32 19.2Z" />
                    </svg>
                  </div>
                </div>
                <div className="valores-section">
                  <h4 style={{ color: "#A00404" }}>Propósito</h4>
                  <p>
                    SENABOL tiene como objetivo principal capacitar y formar de
                    manera integral a aquellos que han sido llamados al
                    ministerio, brindándoles las herramientas necesarias para
                    desarrollar su vocación de servicio. Mediante una variedad
                    de programas académicos y ministeriales, tanto residenciales
                    como descentralizados y a distancia, SENABOL se esfuerza por
                    garantizar que cada estudiante reciba una educación de alta
                    calidad.
                  </p>
                </div>
              </div>
            </div>
            {/* Adornos adicionales */}
            <div class=".puntoMi"></div>
            <div className="adorno-circulos-concentricos adorno-circulos-concentricos-1"></div>
            <div className="adorno-circulos-concentricos adorno-circulos-concentricos-2"></div>
          </div>
        </div>
      </div>
      {/* Secciones las autoridades porfa******************************************************************************************** */}

      <div className="seccion-autoridadesBi">
        <h2 className="titulo3">Nuestras Autoridades</h2>
        <div className="linea-decorativaBi"></div>
        <p className="descripcion2">
          SENABOL, cuenta con un equipo de autoridades dedicadas a guiar y
          fortalecer la misión de formar teológica e integralmente a personas
          con vocación de servicio. Comprometidas con la excelencia académica y
          espiritual, desempeñan un papel fundamental en la dirección y
          desarrollo de nuestra institución.
        </p>
        <div className="contenedor-autoridades">
          {/* Tarjeta 1 */}
          <div className="tarjeta-autoridad">
            <div className="tarjeta">
              <div className="tarjeta-frontal">
                <div className="imagen-contenedor">
                  <img
                    src="/Imagenes/rector.png"
                    alt="Rev. Mag. Lic. Luis Adolfo Chávez Ticona"
                    className="imagen-autoridad"
                  />
                </div>
                <h3 className="nombre-autoridad">
                  Rev.Mag.Luis Adolfo Chávez Ticona
                </h3>
                <div className="cargo-autoridad">RECTOR DE SENABOL</div>
              </div>
              <div className="tarjeta-trasera">
                <p>
                  Es pastor presbítero ordenado en la Iglesia del Nazareno,
                  nació en la ciudad de El Alto, La Paz, Bolivia. Está casado
                  con Marcia López y es miembro activo de la Iglesia del
                  Nazareno. Es egresado de la carrera de Auditoria de la
                  Universidad de San Andrés, realizó sus estudios de
                  Bachillerato en Teología en el Seminario Nazareno Boliviano,
                  hizo sus estudios de Licenciatura en Teología en el Seminario
                  Teológico Nazareno Sudamericano (Pilar-Argentina), se graduó
                  en la Maestría en Teología y Biblia del Seminario Nazareno de
                  las Américas “SENDAS” (San José-Costa Rica).
                </p>
                <br></br>
                <p>
                  También realizado varios diplomados relacionados a la labor
                  pastoral y al ministerio,actualmente está realizando sus
                  estudios de Doctorado en Ministerio en el Seminario
                  Internacional Teológico Bautista. SITB (Buenos Aires
                  -Argentina) Ha desarrollado el ministerio pastoral en
                  diferentes Iglesias durante 13 años, con llamado y dones para
                  el Discipulado y consejería. Actualmente sirve en el área
                  educativa de nuestra iglesia del Nazareno como Rector del
                  Seminario Nazareno Boliviano SENABOL.
                </p>
              </div>
            </div>
          </div>

          {/* Tarjeta 2 */}
          <div className="tarjeta-autoridad">
            <div className="tarjeta">
              <div className="tarjeta-frontal">
                <div className="imagen-contenedor">
                  <img
                    src="/Imagenes/Vicerector.png"
                    alt="Lic. Ing. Nelson Saavedra Romanos"
                    className="imagen-autoridad"
                  />
                </div>
                <h3 className="nombre-autoridad">
                  Lic. Ing. Nelson Saavedra Romanos
                </h3>
                <div className="cargo-autoridad">
                  VICERRECTOR ACADÉMICO DE SENABOL
                </div>
              </div>
              <div className="tarjeta-trasera">
                <p>
                  Rev. Nelson Samuel Saavedra Carvajal es el Vicerrector
                  Académico del SENABOL, en los años precedentes fue docente de
                  cursos presenciales y virtuales en el SENABOL y ETED.
                  Asimismo, trabajó como pastor por 27 años en varias iglesias
                  del distrito La Paz. Obtuvo su bachillerato en Teología del
                  SENABOL y la licenciatura en Teología del STNCS. Entre sus
                  cargos distritales se encuentra que fue vicepresidente de JNI
                  y luego presidente de JNI, miembro del concilio distrital de
                  MNI, miembro de la Junta de Credenciales y Junta de Estudios
                  Ministeriales.
                  <br></br>
                  De la misma forma que muchos pastores del distrito son
                  bivocacionales, pastor Nelson estudio Ingeniería Electrónica
                  en la UMSA, por lo cual trabajó en ONGs cristianas,
                  posteriormente en instituciones estatales y finalmente en
                  empresas privadas. Esta casado con María Magdalena Aguilar
                  Guanto por 24 años, fueron bendecidos con 2 hijas. Le gusta
                  predicar, enseñar la palabra de Dios y desea aportar con sus
                  conocimientos técnicos en la presencia digital del SENABOL en
                  el Internet y en el desarrollo de las actividades cotidianas
                  del SENABOL.
                </p>
              </div>
            </div>
          </div>

          {/* Tarjeta 3 */}
          <div className="tarjeta-autoridad">
            <div className="tarjeta">
              <div className="tarjeta-frontal">
                <div className="imagen-contenedor">
                  <img
                    src="/Imagenes/administrativo.png"
                    alt="Lic. Arq. Edwin Apaza Cerezo"
                    className="imagen-autoridad"
                  />
                </div>
                <h3 className="nombre-autoridad">
                  Lic. Arq. Edwin Apaza Cerezo
                </h3>
                <div className="cargo-autoridad">
                  VICERRECTOR ADMINISTRATIVO DE SENABOL
                </div>
              </div>
              <div className="tarjeta-trasera">
                <p>
                  Es pastor presbítero Ordenado de la Iglesia del Nazareno,
                  nació en Omasuyos provincia Murillo La Paz, Bolivia. Esta
                  casado con Graciela Tallacagua Palomino tiene tres hijos Luz
                  Belén, Dana Massiel y Alejandro Matías, , Arquitecto de
                  profesión, Bachiller en teología de SENABOL, terminando
                  Licenciatura en teología … realizo Diplomados en: Organización
                  y Administración en pedagogía del Aula en Educación Superior,
                  Preparación, evaluación y gerencia de proyectos sociales,
                  Ordenamiento territorial urbanización y vivienda, Gerencia de
                  la Construcción, Gestión municipal indígena para el desarrollo
                  sostenible, Formulación y evaluación de proyectos de
                  infraestructura, Programa de alta gerencia en Ingeniería y
                  administración de la construcción, Programa ejecutivo en:
                  Gestión de procesos de impacto para el sector de saneamiento
                  básico.
                  <br></br>
                  Fue presidente de la JNI del distrito de La Paz 1998-2002,
                  presidente JNI Distrito El Alto 2004, Coordinador Nacional de
                  la JNI 2008-2010. Ha desarrollado el ministerio pastoral
                  durante 20 años en diferentes Iglesias, actualmente es pastor
                  de la Iglesia del Nazareno Central la Paz, Vicerrector
                  Administrativo y profesor de SENABOL, Presidente de DNI,
                  miembro de la Junta de Propiedades y Junta de Estudios
                  Ministerial del Distrito de La Paz.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Adornos adicionales */}
        <div class="adorno-puntosAu"></div>
        <div className="adorno-x"></div>
        <div className="adorno-circulo"></div>
        <div className="adorno-puntos-esquina"></div>
      </div>
      {/* Seccion cantidades **********************************************************************************/}
      <div className="stats-section">
        <div className="stat-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stat-icon"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 5C14.21 5 16 6.79 16 9V12H8V9C8 6.79 9.79 5 12 5Z" />
            <path d="M12 2C9.33 2 7.16 4.17 7.16 6.84C7.16 10.5 12 15 12 15C12 15 16.84 10.5 16.84 6.84C16.84 4.17 14.67 2 12 2Z" />
            <path d="M10 21H14" />
            <path d="M12 15V21" />
          </svg>
          <h3 className="stat-number">3,000</h3>
          <p className="stat-label">CASOS DE ÉXITO</p>
        </div>
        <div className="stat-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stat-icon"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="7" r="4" />
            <path d="M5.2 20C5.8 17.2 8.7 15 12 15C15.3 15 18.2 17.2 18.8 20" />
          </svg>
          <h3 className="stat-number">320</h3>
          <p className="stat-label">TUTORES DE CONFIANZA</p>
        </div>
        <div className="stat-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stat-icon"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
            <line x1="16" y1="2" x2="16" y2="6" />
            <line x1="8" y1="2" x2="8" y2="6" />
            <line x1="3" y1="10" x2="21" y2="10" />
          </svg>
          <h3 className="stat-number">1,000</h3>
          <p className="stat-label">HORARIOS</p>
        </div>
        <div className="stat-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stat-icon"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M2 7V17C2 18.1 2.9 19 4 19H20C21.1 19 22 18.1 22 17V7" />
            <path d="M8 3H16C17.1 3 18 3.9 18 5V8H6V5C6 3.9 6.9 3 8 3Z" />
          </svg>
          <h3 className="stat-number">587</h3>
          <p className="stat-label">CURSOS</p>
        </div>
      </div>
    </div>
  );
};

export default PaginaSobreNosotros;
