import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Agregamos useNavigate
import "./Acceso.css";

const Acceso = () => {
  const [rolSeleccionado, setRolSeleccionado] = useState("Administrador");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Inicializamos el hook para la navegación

  const roles = ["Administrador", "Docente", "Estudiante", "Personal"];

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("http://localhost:5000/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password, role: rolSeleccionado }),
      });

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("role", data.role);
        alert("Login exitoso");
        navigate("/dashboard"); // Redirigir a la página del dashboard
      } else {
        alert("Error al iniciar sesión: " + data.message);
      }
    } catch (error) {
      console.error("Error en la autenticación", error);
      alert("Hubo un problema al conectarse con el servidor.");
    }
  };

  return (
    <div className="contenedor">
      {/* Imagen de fondo */}
      <img
        src="/Imagenes/FONDO LOGIN22.png"
        alt="Fondo Login"
        className="imagen-fondo"
      />

      {/* Logo y Bienvenida */}
      <div className="logo-y-bienvenida">
        <img
          src="/Imagenes/LOGO.png"
          alt="Logo"
          className="logo-imagenLo"
        />
        <h1 className="titulo-bienvenido">Bienvenido</h1>
      </div>

      {/* Sección derecha */}
      <div className="seccion-derecha">
        {/* Contenedor de roles */}
        <div className="roles-container">
          {roles.map((rol) => (
            <span
              key={rol}
              className={`rol ${rolSeleccionado === rol ? "activo" : ""}`}
              onClick={() => setRolSeleccionado(rol)}
            >
              {rol}
            </span>
          ))}
        </div>

        {/* Título del formulario */}
        <h2 className="titulo-login">Inicio de Sesión</h2>
        <p className="texto-rol-seleccionado">
          Rol seleccionado: <strong>{rolSeleccionado}</strong>
        </p>

        {/* Formulario */}
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Nombre de Usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <div className="contenedor-checkboxLo">
            <input type="checkbox" id="terminos" />
            <label htmlFor="terminos">Recordar Contraseña</label>
          </div>
          <button type="submit" className="boton-registrarse">
            Ingresar
          </button>
        </form>

        {/* Enlace de soporte */}
        <p className="enlace-iniciar-sesion">
          ¿Necesita ayuda? <Link to="/PaginaSoporteTecnico" className="help-link">Soporte Técnico</Link>
        </p>

        {/* Copyright */}
        <p className="copyright">
          Copyright ©2024 Senabol. Todos los derechos reservados
        </p>
      </div>
    </div>
  );
};

export default Acceso;
