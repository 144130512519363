import React from "react";
import Slider from "react-slick";
import "./SeccionInstituciones.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const instituciones = [
  { src: "/Imagenes/DNI-LOGO.png", alt: "Discipulado Nazareno Internacional" },
  { src: "/Imagenes/JNI.jpg", alt: "Juventud Nazarena Internacional" },
  { src: "/Imagenes/MNIjpg.jpg", alt: "Misiones Nazarenas Internacionales" },
  { src: "/Imagenes/IGLESIA-NAZARENO.jpg", alt: "Iglesia del Nazareno" },
  { src: "/Imagenes/CASA.jpg", alt: "La Casa del Nazareno" },
];

const CustomArrow = ({ className, style, onClick, icon }) => (
  <div
    className={`${className} custom-arrow`} // Clase CSS personalizada
    style={{
      ...style,
      fontSize: '3rem', // Aumenta el tamaño de la flecha
      color: '#6f0000', // Cambia el color de la flecha
      background: 'none', // Sin fondo
      border: 'none', // Sin borde
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '50px', // Margen izquierdo
      marginRight: '50px', // Margen derecho
    }}
    onClick={onClick}
  >
    {icon}
  </div>
);

const SeccionInstituciones = () => {
  <div className="adornos-circulo"></div>
  const settings = {
    dots: true, // Muestra los puntos de navegación
    infinite: true, // Desplazamiento infinito
    speed: 500, // Velocidad de la transición
    slidesToShow: 4, // Slides visibles al mismo tiempo
    slidesToScroll: 1, // Slides desplazados por vez
    autoplay: true, // Reproducción automática
    autoplaySpeed: 3000, // Intervalo de reproducción automática
    arrows: true, // Muestra las flechas de navegación
    prevArrow: <CustomArrow className="custom-prev-arrow" icon="&#8249;" />, // Flecha izquierda con clase personalizada
    nextArrow: <CustomArrow className="custom-next-arrow" icon="&#8250;" />, // Flecha derecha con clase personalizada
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="seccion-instituciones">
      <h2 className="titulo-seccion">
  <i className="fas fa-handshake" style={{ marginRight: '10px', color: '#7F1618' }}></i>
  Nuestros Aliados
  
</h2>

      <p className="descripcion-seccion">
        SENABOL, comprometido con la formación integral y el desarrollo
        espiritual en Bolivia, se enorgullece de contar con la colaboración de
        diversas organizaciones y entidades afines que apoyan nuestra misión de
        fortalecer la fe y promover el conocimiento teológico. A través de
        alianzas estratégicas, buscamos crear un impacto positivo en nuestra
        comunidad, fomentando el crecimiento espiritual y la unidad en la labor
        de servir a Dios y a la sociedad.
      </p>
      <Slider {...settings}>
        {instituciones.map((inst, index) => (
          <div key={index} className="institucion-logo">
            <img src={inst.src} alt={inst.alt} className="logo" />
          </div>
        ))}
      </Slider>

    </section>
  );
};

export default SeccionInstituciones;
