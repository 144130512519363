import React, { useState } from "react";
import "./PaginaSoporteTecnico.css";

const PaginaSoporteTecnico = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Función para abrir el modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Función para cerrar el modal
  const closeModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(false);
  };

  return (
    
    <div className="contenedorPrincipalSoporte">
    <div className="menu-contenedor4Sop">
      {/* Contenedor Principal con imagen de fondo */}
      <div className="header-image-containerNuSop">
        <div className="overlayNoSop">
          <div className="texto-contenedorSop">
            <span className="subtituloSop">
              ¿NECESITAS AYUDA?
            </span>
            <h1 className="titulo-degradado2Sop">
              Soporte técnico disponible <br></br>las 24/7 con Emanuel, el asistente
              virtual de SENABOL
            </h1>
            <p>
              En Senabol, combinamos la experiencia de nuestro equipo humano con
              la rapidez y eficiencia de nuestro asistente virtual,{" "}
              <strong>Emanuel</strong>. Diseñado para responder tus dudas al
              instante y si necesitas más ayuda, nuestros especialistas estará listo para atenderte.
             
            </p>
           
          </div>
          <div className="imagen-derechaSop">
            <img src="/Imagenes/SoporteTecnico.png" alt="Estudiante" />
          </div>
        </div>
      </div>
    </div>
    {/* Contenedor de recuadro blanco***************************************************/}
    <div className="fondoblancoprincipal ">
    {/* insertar contenido de la pagina */}
    <section className="contact-section">
      <h2>Contáctanos</h2>
      <h3>¿Requieres Ayuda?</h3>

      <div className="contact-options">
        {/*Opción 1: Soporte Virtual*/}
        <div class="contact-box">
          <i class="fas fa-comment-dots icon"></i>
          <p class="texto-rojo">
            Chatea con Emanuel, nuestro asistente virtual
          </p>
          <p>
            Nuestro asistente virtual está aquí para ayudarte a resolver tus
            dudas.
          </p>
          <a
            href="https://wa.me/63757954?text=Hola,%20me%20gustaría%20obtener%20información"
            target="_blank"
            class="chat-button"
          >
            <i class="fab fa-whatsapp"></i> Escríbenos
          </a>
        </div>
        {/*Contacto Técnico */}
        <div class="contact-box">
          <i class="fas fa-phone-alt icon"></i>
          <p class="texto-rojo">Comunícate con nuestros Técnicos</p>
          <p>
            Nuestros técnicos estarán encantados de ayudarte a solucionar
            cualquier problema.
          </p>
          <button class="contact-button">
            <i class="fas fa-headset"></i>
            <div class="contact-numbers">
              <p>
                <strong>63797554</strong>
              </p>
              <p>
                <strong>68110115</strong>
              </p>
            </div>
          </button>
        </div>
      </div>
      {/* Sección de preguntas frecuentes */}
      <section className="faq-section">
        <h2>Preguntas Frecuentes</h2>
        <p>
          Así tienes preguntas sobre nuestros servicios, productos o
          cualquier otro tema relacionado con SENABOL, revisa primero
          nuestra sección de Preguntas Frecuentes.
        </p>
        <ul>
          <li>
            <a href="https://www.youtube.com/@seminarionazarenoboliviano1740">¿Cómo ingreso al sistema por primera vez?</a>
          </li>
          <li>
            <a href="https://www.youtube.com/@seminarionazarenoboliviano1740">Como Inscribirme de forma online</a>
          </li>
          <li>
            <a href="https://www.youtube.com/@seminarionazarenoboliviano1740">Como saber cual es mi usuario y contraseña </a>
          </li>
          
        </ul>
      </section>
    </section>
  </div>
  </div>
  );
};

export default PaginaSoporteTecnico;
