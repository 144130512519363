import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./NoticiaDetalle.css";

const NoticiaDetalle = () => {
  const { id } = useParams(); // Obtener el ID de la noticia desde la URL
  const [noticia, setNoticia] = useState(null);

  useEffect(() => {
    // Petición al backend para obtener los detalles de la noticia
    fetch(`http://localhost:5000/api/noticias/${id}`)
      .then((response) => response.json())
      .then((data) => setNoticia(data))
      .catch((error) =>
        console.error("Error al obtener los detalles de la noticia:", error)
      );
  }, [id]);

  if (!noticia) {
    return <p>Cargando detalles de la noticia...</p>; // Mostrar un mensaje mientras se cargan los datos
  }

  return (
    <div className="noticia-detalle-container">
      <div className="noticia-detalle-card">
        {/* Imagen */}
        <div className="noticia-detalle-imagen">
          <img
            src={`http://localhost:5000/uploads/${noticia.imagen}`}
            alt={noticia.titulo}
          />
        </div>

        {/* Contenido */}
        <div className="noticia-detalle-contenido">
  <h1 className="noticia-detalle-titulo">{noticia.titulo}</h1>
  <p className="noticia-detalle-fecha">
    Publicado el: {new Date(noticia.fecha).toLocaleDateString()}
  </p>
  {noticia.descripcion.split("\n").map((parrafo, index) => (
    <p key={index} className="noticia-detalle-descripcion">
      {parrafo}
    </p>
  ))}
</div>

      </div>
    </div>
  );
};

export default NoticiaDetalle;
