import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  HomeIcon,
  UsersIcon,
  AcademicCapIcon,
  UserGroupIcon,
  BookOpenIcon,
  LibraryIcon,
} from "@heroicons/react/outline";
import "./MenuLateral.css";

const MenuLateral = ({ isCollapsed }) => {
  const [menuAbierto, setMenuAbierto] = useState(false);

  const toggleSubMenu = () => {
    setMenuAbierto(!menuAbierto);
  };

  return (
    <div className={`barra-lateralGe ${isCollapsed ? "colapsado" : ""}`}>
      <div className="logo-contenedorGe">
        <div className="logo-columnaGe">
          <img src="/Imagenes/LOGO.png" alt="Logo del sistema" className="logoGe" />
        </div>
        {!isCollapsed && (
          <div className="texto-columnaGe">
            <span className="nombre-sistema">ADMINISTRADOR</span>
          </div>
        )}
      </div>

      <ul>
        <li>
          <Link to="/dashboard">
            <HomeIcon className="icono-menu-hero" />
            {!isCollapsed && "Panel de datos"}
          </Link>
        </li>
        <li>
          <Link to="/administracion-usuarios">
            <UsersIcon className="icono-menu-hero" />
            {!isCollapsed && "Usuarios"}
          </Link>
        </li>
        <li>
          <Link to="/docentes">
            <AcademicCapIcon className="icono-menu-hero" />
            {!isCollapsed && "Docentes"}
          </Link>
        </li>
        <li>
          <Link to="/gestion-estudiantes">
            <UserGroupIcon className="icono-menu-hero" />
            {!isCollapsed && "Estudiantes"}
          </Link>
        </li>
        <li>
          <Link to="/noticias">
            <UserGroupIcon className="icono-menu-hero" />
            {!isCollapsed && "Noticias"}
          </Link>
        </li>
        <li>
          <Link to="/cursos">
            <BookOpenIcon className="icono-menu-hero" />
            {!isCollapsed && "Cursos"}
          </Link>
        </li>
        <li>
          <Link to="/biblioteca">
            <LibraryIcon className="icono-menu-hero" />
            {!isCollapsed && "Biblioteca"}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default MenuLateral;
