// OfertaAcademica.js
// OfertaAcademica.js
import React from "react";
import { useNavigate } from "react-router-dom"; // Importa useNavigate para redirección
import "./OfertaAcademica.css";

const OfertaAcademica = () => {
  const navigate = useNavigate(); // Inicializa useNavigate

  return (
    <div className="Contenedor PrincipalOfe">
      <section className="oferta-academica">
        <div className="oferta-content">
        <span className="subtitulo">
        FORMACIÓN ACADÉMICA PARA SERVIR Y LIDERAR
            </span>
          <h1>Excelencia Académica Para Servir Con Propósito</h1>
          
          <p>
            En nuestro seminario, te invitamos a vivir una experiencia
            transformadora a través de programas diseñados para llevarte a las
            profundidades de las Escrituras y fortalecer una fe viva y sólida
            que impacte cada área de tu vida.
          </p>

          <button
            className="cta-button"
            onClick={() =>
              window.open(
                process.env.PUBLIC_URL +
                  "/Documentos/PLAN DE ESTUDIOS SENABOL.pdf",
                "_blank"
              )
            }
          >
            Ver Malla Curricular  
          </button>
        </div>
        <div className="laptop-mockup">
          <img
            src={`${process.env.PUBLIC_URL}/Imagenes/Laptop.png`}
            alt="Laptop Mockup"
          />
        </div>
      </section>

      {/* Contenedor de las tarjetas de programas académicos */}
      <div className="contenedorTarjetasA">
        <h1>Programas Académicos</h1>
        <div className="linea-decorativaA"></div>
        <div className="courses-section">
          {/* Tarjeta 1 - Diplomado en Educación Cristiana */}
          <div className="course-card">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 256 256"
              className="course-icon"
            >
              <path
                fill="currentColor"
                d="m225.27 60.21l-96-32a4 4 0 0 0-2.54 0l-96 32A4 4 0 0 0 28 64v80a4 4 0 0 0 8 0V69.55l43.88 14.63a60 60 0 0 0 24.54 91c-20.86 5.74-39 19.13-51.77 38.65a4 4 0 0 0 6.7 4.36C75.17 193.92 100.2 180 128 180s52.83 13.92 68.65 38.18a4 4 0 0 0 6.7-4.36c-12.72-19.52-30.91-32.91-51.77-38.65a60 60 0 0 0 24.54-91l49.15-16.39a4 4 0 0 0 0-7.58ZM180 120a52 52 0 1 1-92.07-33.14l38.8 12.93a3.95 3.95 0 0 0 2.54 0l38.8-12.93A51.85 51.85 0 0 1 180 120m-52-28.22L44.65 64L128 36.22L211.35 64Z"
              />
            </svg>
            <h3>Diplomado en Estudios Interculturales</h3>
            <div className="rating">
              <span>★★★★★</span>
              <span className="rating-score">(5.0)</span>
            </div>
            <div className="instructor">
              <span>9 cursos</span>
            </div>
            <p>5 áreas </p>
            <div className="pricing">
              <p>Modalidades Presencial / Virtual</p>
            </div>
            <button
              className="enroll-button"
              onClick={() => navigate("/programa-diplomados")}
            >
               DETALLES DEL PROGRAMA
            </button>
          </div>

          {/* Tarjeta 2 - Licenciatura en Teología */}
          <div className="course-card">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 256 256"
              className="course-icon"
            >
              <path
                fill="currentColor"
                d="m225.27 60.21l-96-32a4 4 0 0 0-2.54 0l-96 32A4 4 0 0 0 28 64v80a4 4 0 0 0 8 0V69.55l43.88 14.63a60 60 0 0 0 24.54 91c-20.86 5.74-39 19.13-51.77 38.65a4 4 0 0 0 6.7 4.36C75.17 193.92 100.2 180 128 180s52.83 13.92 68.65 38.18a4 4 0 0 0 6.7-4.36c-12.72-19.52-30.91-32.91-51.77-38.65a60 60 0 0 0 24.54-91l49.15-16.39a4 4 0 0 0 0-7.58ZM180 120a52 52 0 1 1-92.07-33.14l38.8 12.93a3.95 3.95 0 0 0 2.54 0l38.8-12.93A51.85 51.85 0 0 1 180 120m-52-28.22L44.65 64L128 36.22L211.35 64Z"
              />
            </svg>
            <h3>Licenciatura en Teología</h3>
            <div className="rating">
              <span>★★★★★</span>
              <span className="rating-score">(5.0)</span>
            </div>
            <div className="instructor">
              <span>13 Materias</span>
            </div>
            <p>50 clases </p>
            <div className="pricing">
              <p>Modalidades Presencial / Virtual</p>
            </div>
            <button
              className="enroll-button"
              onClick={() => navigate("/programa-licenciatura")}
            >
               DETALLES DEL PROGRAMA
            </button>
          </div>

          {/* Tarjeta 3 - Bachillerato en Teología */}
          <div className="course-card">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 256 256"
              className="course-icon"
            >
              <path
                fill="currentColor"
                d="m225.27 60.21l-96-32a4 4 0 0 0-2.54 0l-96 32A4 4 0 0 0 28 64v80a4 4 0 0 0 8 0V69.55l43.88 14.63a60 60 0 0 0 24.54 91c-20.86 5.74-39 19.13-51.77 38.65a4 4 0 0 0 6.7 4.36C75.17 193.92 100.2 180 128 180s52.83 13.92 68.65 38.18a4 4 0 0 0 6.7-4.36c-12.72-19.52-30.91-32.91-51.77-38.65a60 60 0 0 0 24.54-91l49.15-16.39a4 4 0 0 0 0-7.58ZM180 120a52 52 0 1 1-92.07-33.14l38.8 12.93a3.95 3.95 0 0 0 2.54 0l38.8-12.93A51.85 51.85 0 0 1 180 120m-52-28.22L44.65 64L128 36.22L211.35 64Z"
              />
            </svg>
            <h3>Bachillerato en Teología</h3>
            <div className="rating">
              <span>★★★★★</span>
              <span className="rating-score">(5.0)</span>
            </div>
            <div className="instructor">
              <span>45 Materias</span>
            </div>
            <p>50 clases (190 hrs)</p>
            <div className="pricing">
              <p>Modalidades Presencial / Virtual</p>
            </div>
            <button
              className="enroll-button"
              onClick={() => navigate("/programa-bachillerato")}
            >
               DETALLES DEL PROGRAMA
            </button>
          </div>

           {/* Tarjeta 4 - Diplomas */}
           <div className="course-card">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 256 256"
              className="course-icon"
            >
              <path
                fill="currentColor"
                d="m225.27 60.21l-96-32a4 4 0 0 0-2.54 0l-96 32A4 4 0 0 0 28 64v80a4 4 0 0 0 8 0V69.55l43.88 14.63a60 60 0 0 0 24.54 91c-20.86 5.74-39 19.13-51.77 38.65a4 4 0 0 0 6.7 4.36C75.17 193.92 100.2 180 128 180s52.83 13.92 68.65 38.18a4 4 0 0 0 6.7-4.36c-12.72-19.52-30.91-32.91-51.77-38.65a60 60 0 0 0 24.54-91l49.15-16.39a4 4 0 0 0 0-7.58ZM180 120a52 52 0 1 1-92.07-33.14l38.8 12.93a3.95 3.95 0 0 0 2.54 0l38.8-12.93A51.85 51.85 0 0 1 180 120m-52-28.22L44.65 64L128 36.22L211.35 64Z"
              />
            </svg>
            <h3>Diplomas</h3>
            <div className="rating">
              <span>★★★★★</span>
              <span className="rating-score">(5.0)</span>
            </div>
            <div className="instructor">
              <span>4 Diplomas</span>
            </div>
            <p> (190 hrs)</p>
            <div className="pricing">
              <p> Virtual</p>
            </div>
            <button
              className="enroll-button"
              onClick={() => navigate("/programa-Diplomas")}
            >
              DETALLES DEL PROGRAMA
            </button>
          </div>

          {/* Tarjeta 4 - Cursos Complementarios */}
          <div className="course-card">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 256 256"
              className="course-icon"
            >
              <path
                fill="currentColor"
                d="m225.27 60.21l-96-32a4 4 0 0 0-2.54 0l-96 32A4 4 0 0 0 28 64v80a4 4 0 0 0 8 0V69.55l43.88 14.63a60 60 0 0 0 24.54 91c-20.86 5.74-39 19.13-51.77 38.65a4 4 0 0 0 6.7 4.36C75.17 193.92 100.2 180 128 180s52.83 13.92 68.65 38.18a4 4 0 0 0 6.7-4.36c-12.72-19.52-30.91-32.91-51.77-38.65a60 60 0 0 0 24.54-91l49.15-16.39a4 4 0 0 0 0-7.58ZM180 120a52 52 0 1 1-92.07-33.14l38.8 12.93a3.95 3.95 0 0 0 2.54 0l38.8-12.93A51.85 51.85 0 0 1 180 120m-52-28.22L44.65 64L128 36.22L211.35 64Z"
              />
            </svg>
            <h3>Cursos Complementarios</h3>
            <div className="rating">
              <span>★★★★★</span>
              <span className="rating-score">(5.0)</span>
            </div>
            <div className="instructor">
              <span>15 Cursos</span>
            </div>
            <p>50 clases (190 hrs)</p>
            <div className="pricing">
              <p>Modalidades Presencial / Virtual</p>
            </div>
            <button
              className="enroll-button"
              onClick={() => navigate("/programa-cursosComplementarios")}
            >
               DETALLES DEL PROGRAMA
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfertaAcademica;
