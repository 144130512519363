import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { FaUserCircle } from "react-icons/fa";
import "./Encabezado.css";

const Encabezado = () => {
  const location = useLocation(); // Detecta la ruta actual
  const [mostrarDropdownEnlaces, setMostrarDropdownEnlaces] = useState(false); // Estado para Enlaces
  const [mostrarMenu, setMostrarMenu] = useState(false); // Estado para el menú hamburguesa en móviles

  // Función para cerrar el menú desplegable al hacer clic fuera
  const cerrarDropdown = (e) => {
    if (!e.target.closest(".menu-dropdown")) {
      setMostrarDropdownEnlaces(false);
    }
  };

  // Agregar y eliminar el evento global en el documento
  useEffect(() => {
    document.addEventListener("click", cerrarDropdown);
    return () => {
      document.removeEventListener("click", cerrarDropdown);
    };
  }, []);

  return (
    <header className="encabezado">
      {/* Barra Superior */}
      <div className="encabezado-superior">
        <div className="info-contacto">
          <span>✉ seminarionazarenoboliviano@gmail.com</span>
          <span>📞 +591(2)2740559</span>
        </div>
        <div className="redes-sociales">
          <a
            href="https://www.facebook.com/bolivia.seminario"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="jam:facebook" className="icon" />
          </a>
          <a
            href="https://x.com/senabol_nazaren"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="simple-icons:x" className="icon" />
          </a>
          <a
            href="https://www.instagram.com/senabol2023/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="mdi:instagram" className="icon" />
          </a>
          <a
            href="https://www.tiktok.com/@senabol_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="ic:baseline-tiktok" className="icon" />
          </a>
          <a
            href="https://www.youtube.com/@seminarionazarenoboliviano1740"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="mdi:youtube" className="icon" />
          </a>
        </div>
      </div>

      {/* Barra Principal */}
      <div className="encabezado-inferior">
        {/* Logo */}
        <div className="logo-seccion">
          <img
            src="/Imagenes/LOGO.png"
            alt="Logo de Senabol"
            className="logo-imagen"
          />
        </div>

        {/* Menú Hamburguesa */}
        <div
          className="menu-hamburguesa"
          onClick={() => setMostrarMenu(!mostrarMenu)}
        >
          <Icon icon="mdi:menu" />
        </div>

        {/* Navegación */}
        <nav className={`navegacion ${mostrarMenu ? "mostrar" : ""}`}>
          <ul>
            <li>
              <Link
                to="/"
                className={location.pathname === "/" ? "active" : ""}
              >
                PRINCIPAL
              </Link>
            </li>
            <li>
              <Link
                to="/sobre-nosotros"
                className={
                  location.pathname === "/sobre-nosotros" ? "active" : ""
                }
              >
                SOBRE NOSOTROS
              </Link>
            </li>
            <li>
              <Link
                to="/oferta-academica"
                className={
                  location.pathname === "/oferta-academica" ? "active" : ""
                }
              >
                PROGRAMAS
              </Link>
            </li>

            {/* Menú desplegable para Enlaces */}
            <li
              className="menu-dropdown"
              onMouseEnter={() => setMostrarDropdownEnlaces(true)}
              onMouseLeave={() => setMostrarDropdownEnlaces(false)}
            >
              <span className="dropdown-toggle">ENLACES</span>
              {mostrarDropdownEnlaces && (
                <ul className="dropdown-content">
                  <li>
                    <a
                      href="https://120w.xyz/virtualsenabol/login/index.php"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PAE SENABOL
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://nazarenovirtual.instructure.com/login/canvas"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CANVAS
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://senbol-gnec.kari.opalsinfo.net/bin/home#0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BIBLIOTECA - OPALS
                    </a>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <Link
                to="/novedades"
                className={location.pathname === "/novedades" ? "active" : ""}
              >
                NOTICIAS
              </Link>
            </li>
            <li>
              <Link
                to="/PaginaSoporteTecnico"
                className={
                  location.pathname === "/PaginaSoporteTecnico" ? "active" : ""
                }
              >
                SOPORTE
              </Link>
            </li>
          </ul>
        </nav>

        {/* Botón Ingresar */}
        <div className="contenedor-boton">
          <Link to="/Acceso" className="btn-ingresar">
          <span className="barra-divisoria">
              <Icon icon="ls:bar" />
            </span>
            <span className="icono-ingresar">
              <Icon icon="mdi:login" />
            </span>
            
            ACCEDER
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Encabezado;
