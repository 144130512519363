import jsPDF from "jspdf";
import "jspdf-autotable";

const generarPDF = (estudiante) => {
  const doc = new jsPDF();

  // 1. Encabezado con logo y título
  const logoURL = "/Imagenes/LOGO.png"; // Asegúrate de usar la ruta correcta
  doc.addImage(logoURL, "PNG", 10, 10, 30, 30); // Logo en la esquina superior izquierda
  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text("SEMINARIO NAZARENO BOLIVIANO", 60, 20);
  doc.setFontSize(14);
  doc.text("BOLETA DE INSCRIPCIÓN", 70, 30);

  // Línea horizontal
  doc.line(10, 40, 200, 40);

  // 2. Datos Personales y Académicos en dos columnas
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Gestión:", 10, 50);
  doc.setFont("helvetica", "normal");
  doc.text(`${estudiante.gestion || "I/2024"}`, 50, 50);

  doc.setFont("helvetica", "bold");
  doc.text("Nombre:", 10, 60);
  doc.setFont("helvetica", "normal");
  doc.text(
    `${estudiante.nombres} ${estudiante.apellidoPaterno} ${estudiante.apellidoMaterno}`,
    50,
    60
  );

  doc.setFont("helvetica", "bold");
  doc.text("Carrera:", 10, 70);
  doc.setFont("helvetica", "normal");
  doc.text(`${estudiante.carrera || "Psicomotricidad y Deportes"}`, 50, 70);

  doc.setFont("helvetica", "bold");
  doc.text("No. C.I.:", 140, 50);
  doc.setFont("helvetica", "normal");
  doc.text(`${estudiante.dni}`, 170, 50);

  doc.setFont("helvetica", "bold");
  doc.text("Reg. Univ.:", 140, 60);
  doc.setFont("helvetica", "normal");
  doc.text(`${estudiante.codigoEstudiante}`, 170, 60);

  doc.setFont("helvetica", "bold");
  doc.text("Fecha:", 140, 70);
  doc.setFont("helvetica", "normal");
  doc.text(`${new Date().toLocaleDateString()}`, 170, 70);

  // Línea separadora
  doc.line(10, 80, 200, 80);

  // 3. Tabla de Asignaturas (sin la columna "Nivel" y con mayor anchura)
  const asignaturas = [
    ["No.", "Asignatura", "Turno", "Fecha Mod"],
    ["1", "Nuevo Testamento I", "Mañana", "2025-01-10"],
    ["2", "Antiguo Testamento I", "Tarde", "2025-01-11"],
    ["3", "Hermenéutica", "Mañana", "2025-01-12"],
  ];

  doc.autoTable({
    startY: 90,
    head: [asignaturas[0]],
    body: asignaturas.slice(1),
    theme: "grid",
    headStyles: { fillColor: [0, 56, 168], textColor: [255, 255, 255] },
    styles: { fontSize: 10, cellPadding: 3, halign: "center" },
    columnStyles: {
      0: { cellWidth: 10 },    // "No." columna
      1: { cellWidth: 100 },   // "Asignatura" columna (aumentada)
      2: { cellWidth: 30 },    // "Turno" columna
      3: { cellWidth: 40 },    // "Fecha Mod" columna
    },
  });

  // Total horas inscritas
  const totalHoras = "28";
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text(
    `Total horas mes inscritas: ${totalHoras}`,
    10,
    doc.lastAutoTable.finalY + 10
  );

  // Nota al pie
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.text(
    "NOTA: Este formulario es válido para fines administrativos y debe ser presentado con documentos oficiales.",
    10,
    doc.lastAutoTable.finalY + 20
  );

  // Firmas (Estudiante y Vicerrector Académico)
  const firmaY = doc.lastAutoTable.finalY + 40; // Y-coordinate para las firmas
  doc.setFont("helvetica", "bold");
  doc.text("_______________________", 10, firmaY); // Firma del estudiante
  doc.text("Firma del Estudiante", 10, firmaY + 10);

  doc.text("_______________________", 140, firmaY); // Firma del Vicerrector
  doc.text("Firma del Vicerrector Académico", 130, firmaY + 10);

  // Descargar el PDF
  doc.save(`Boleta_${estudiante.codigoEstudiante}.pdf`);
};

export default generarPDF;
