import React from "react";
import "./PiePagina.css";

const PiePagina = () => {
  return (
    <footer className="pie-pagina">
      {/* Contenedor principal del pie de página */}
      <div className="pie-pagina-contenido">
        {/* Columna 1: Logo y descripción */}
        {/* Columna 1: Logo y descripción */}
        <div className="pie-pagina-logo">
        <div className="logos-container">
  {/* Logo de SENABOL */}
  <img src="/Imagenes/LOGO.png" alt="Logo SENABOL" className="logo-senabol" />
  
  {/* Nuevo logo (SIRAI) */}
  <img src="/Imagenes/LOGOSIS.png" alt="Logo SIRAI" className="logo-sirai" />
</div>

          <p className="texto-justificadolo">
            Fomentamos un ambiente que inspire a cada estudiante
            a fortalecer su fé en Dios.
          </p>
          <div className="pie-pagina-social">
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.tiktok.com/@senabol_"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-tiktok"></i>
            </a>
          </div>
        </div>

        {/* Columna 2: Asociados */}
        <div className="pie-pagina-info">
          <ul>
            <li className="titulo-explorar">
              <h4>Asociados</h4>
            </li>
            <li>
              <a
                href="https://www.mesonaz.org/ministerios/discipulado-nazareno-internacional/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Discipulado Nazareno Internacional
              </a>
            </li>
            <li>
              <a
                href="https://nazarene.org/es/quienes-somos/organizaci%C3%B3n/ministerios/misiones-nazarenas-internacionales"
                target="_blank"
                rel="noopener noreferrer"
              >
                Misiones Nazarenas Internacionales
              </a>
            </li>
            <li>
              <a
                href="https://nazarene.org/es/quienes-somos/organizaci%C3%B3n/ministerios/juventud-nazarena-internacional"
                target="_blank"
                rel="noopener noreferrer"
              >
                Juventud Nazarena Internacional
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/LCNBOL/"
                target="_blank"
                rel="noopener noreferrer"
              >
                La Casa del Nazareno
              </a>
            </li>
            <li>
              <a
                href="https://nazarene.org/es/article/nazarenos-en-bolivia-celebran-75-anos-de-ministerio"
                target="_blank"
                rel="noopener noreferrer"
              >
                Iglesia del Nazareno
              </a>
            </li>
          </ul>
        </div>

        {/* Columna 3: Red Institucional */}
        <div className="pie-pagina-info">
          <ul>
            <li className="titulo-explorar">
              <h4>Red Institucional</h4>
            </li>
            <li>
              <a
                href="https://asit.edu.ar/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Asociación de seminarios e <br></br> instituciones Teológicas
                del Cono Sur
              </a>
            </li>
            <li>
              <a
                href="https://www.samnaz.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Iglesia del Nazareno <br></br> América del Sur
              </a>
            </li>
            <li>
              <a
                href="https://nazarene.org/iboe-institutions"
                target="_blank"
                rel="noopener noreferrer"
              >
                International Board <br></br> of Education
              </a>
            </li>
            <li>
              <a
                href="https://nazarenoconosur.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Oficina del Área Cono Sur
              </a>
            </li>
            <li>
              <a
                href="https://nazarene.org/index.php/es/article/la-conferencia-gnec-promueve-un-sistema-de-educacion-para-toda-la-vida-y-sin-fronteras"
                target="_blank"
                rel="noopener noreferrer"
              >
                Educación Global y Desarrollo <br></br> del Cuerpo Ministerial
              </a>
            </li>
          </ul>
        </div>

        {/* Columna 4: Contacto */}
        <div className="pie-pagina-contacto">
          <h4>¿Tiene alguna pregunta?</h4>
          <p>
            <i className="fas fa-map-marker-alt"></i> Av. Aranjuez, N° 100,
            Aranjuez, Bolivia.
          </p>
          <p>
            <i className="fas fa-phone-alt"></i> +591 69709246
          </p>
          <p>
            <i className="fas fa-envelope"></i>{" "}
            seminarionazarenoboliviano@gmail.com
          </p>
        </div>
      </div>

      {/* Mapa */}
      <div className="pie-pagina-mapa">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3824.3340413500946!2d-68.0955575252528!3d-16.55968524226401!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x915f26d67ba7fc29%3A0xf462eb6b2fdfec31!2sSENABOL!5e0!3m2!1sen!2sbo!4v1726350628897!5m2!1sen!2sbo"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Ubicación SENABOL"
        ></iframe>
      </div>

      {/* Copyright */}
      <div className="pie-pagina-copyright">
        <p>
          Copyright ©2025 Senabol. Todos los derechos reservados | Diseñado con{" "}
          <i className="fas fa-heart corazon"></i>{" "}
          <span className="nombre"> Bania H.S.</span>
        </p>
      </div>
    </footer>
  );
};

export default PiePagina;
