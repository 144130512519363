import React, { useState } from "react";
import "./BotonWhatsapp.css";

const BotonWhatsapp = () => {
  const phoneNumber = "59176205140"; // Tu número en formato internacional
  const [isOpen, setIsOpen] = useState(false); // Estado para mostrar/ocultar el cuadro de contacto
  const [selectedOption, setSelectedOption] = useState(""); // Estado para la opción seleccionada

  // Función para abrir/ocultar el cuadro de contacto
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  // Función para manejar la selección del usuario
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Función para redirigir a WhatsApp
  const handleEnviarClick = () => {
    let mensaje = "Hola, necesito más información.";
    if (selectedOption === "programas") {
      mensaje = "Hola, necesito información sobre los programas.";
    } else if (selectedOption === "soporte") {
      mensaje = "Hola, necesito soporte técnico.";
    } else if (selectedOption === "ubicacion") {
      mensaje = "Hola, necesito los precios del curso";
    }

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      mensaje
    )}`;
    window.open(whatsappUrl, "_blank"); // Abrir WhatsApp en una nueva pestaña
  };

  return (
    <div>
      {/* Botón flotante */}
      <div className="whatsapp-button" onClick={togglePopup}>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
          className="whatsapp-icon"
        />
      </div>

      {/* Cuadro de contacto */}
      {isOpen && (
        <div className="contact-popup">
          <h3>Contáctanos</h3>
          <p>Seleccione el tipo de consulta que tiene:</p>
          <select
            value={selectedOption}
            onChange={handleSelectChange}
            className="consulta-select"
          >
            <option value="">¿Qué tipo de consulta tienes?</option>
            <option value="programas">Información Sobre los Programas</option>
            <option value="soporte">Soporte Técnico</option>
            <option value="ubicacion">Precios del Curso</option>
          </select>
          <button className="btn-enviar" onClick={handleEnviarClick}>
            Enviar
          </button>
        </div>
      )}
    </div>
  );
};

export default BotonWhatsapp;
