import React, { useEffect } from "react";
import "./SeccionTestimonios.css";

const SeccionTestimonios = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible"); // Aplica la clase al contenedor
          }
        });
      },
      { threshold: 0.2 } // Detecta cuando el 20% del contenedor es visible
    );

    const timelineContainer = document.querySelector(".timeline-container");
    if (timelineContainer) {
      observer.observe(timelineContainer);
    }

    return () => {
      if (timelineContainer) observer.unobserve(timelineContainer);
    };
  }, []);

  return (
    <section className="testimonios-seccion">
      <h2 className="testimonios-titulo">¿Qué dicen nuestros exalumnos?</h2>
      <div className="timeline-container">
        {/* Tarjeta en el lado izquierdo */}
        <div className="timeline-item left">
          <div className="tarjeta-testimonio">
            <div className="contenido-testimonio">
              <img
                src="/Imagenes/FotoTestimonio1.jpg"
                alt="Foto de Juan Pérez"
                className="foto-testimonio"
              />
              <div>
                <h3>Raul Mamani</h3>
                <p>
                  Estudiar en este lugar fue lo mejor que me pasó en la vida.
                  Durante tres años de internado aprendí teología, principios,
                  valores y lo que significa trabajar con disciplina y orden.
                  Las mañanas eran de clases, las tardes de trabajo y las noches
                  de estudio en la biblioteca. Recomiendo ingresar al seminario;
                  es una experiencia muy enriquecedora..
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Línea central punteada */}
        <div className="timeline-line">
          <div className="puntoa"></div>
          <div className="puntoa"></div>
          <div className="puntoa"></div>
        </div>

        {/* Tarjetas en el lado derecho */}
        <div className="timeline-item right">
          <div className="tarjeta-testimonio">
            <div className="contenido-testimonio2">
              <img
                src="/Imagenes/FotoTestimonio2.jpg"
                alt="Foto de María López"
                className="foto-testimonio"
              />
              <div>
                <h3>Ptor. Juan Paredes</h3>
                <p>
                  Mi experiencia en Senabol tuvo sus luces y sombras. Fue un
                  tiempo en el que pude fortalecer mi relación personal con
                  Dios, pero también me dejó con la sensación de que el sistema
                  podía ofrecer mucho más en términos de acompañamiento y
                  calidad académica. Aun así, valoro ese período como una etapa
                  importante en mi vida, que me enseñó a buscar a Dios por mí
                  mismo y a depender más de Su guía que de las
                  circunstancias externas.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="timeline-item right">
          <div className="tarjeta-testimonio">
            <div className="contenido-testimonio3">
              <img
                src="/Imagenes/FotoTestimonio3.jpg"
                alt="Foto de Carlos García"
                className="foto-testimonio"
              />
              <div>
                <h3>Ptor. Oscar Tarquino</h3>
                <p>
                  Estudiar en el seminario fue una de las mejores experiencias
                  de mi vida. Allí hice amigos que hasta hoy recuerdo con mucho
                  cariño, aprendí profundamente acerca de Dios y fortalecí mi
                  vida espiritual. También adquirí valores y principios que han
                  dejado una huella imborrable en mi vida.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SeccionTestimonios;
