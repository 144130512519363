import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SeccionBanner.css";

const SeccionBanner = () => {
  const navigate = useNavigate();

  const handleInscripcionClick = () => {
    navigate("/inscripcion");
  };

  const handleProgramasClick = () => {
    navigate("/oferta-academica");
  };

  // Arreglo con frases e imágenes para rotar
  const contenido = [
    {
      bienvenida: "¡Discipulado de por vida!",
      titulo: "Acompañando a la Iglesia en el Cumplimiento de la Misión",
      descripcion:
        "Proceso de discipulado que inicie en la iglesia local, el objetivo es lograr que el desarrollo ministerial y programas de educación acerquen su Iglesia al Seminario.",
      imagen: "/Imagenes/EstudiantePortada.png",
    },
    {
      bienvenida: "Educación a Distancia",
      titulo:
        "Facilitando el Proceso de Formación Ministerial en Bolivia",
      descripcion:
        " Este proceso se divide en:Desarrollo ministerial por áreas (módulos),Formación de subsedes SENABOL-ETED, Cursos Virtuales zoom y Plataforma de Apoyo Educativo PAE y Estrategias de formación diferencia para adultos y casos especiales.",
      imagen: "/Imagenes/EstudiantePortada2.png",
    },
    {
      bienvenida: "Formación integral",
      titulo: "•	Fortaleciendo La Formación  Teologica Con Identidad Y Herencia Arminio-Wesleyana. ",
      descripcion:
        "Formación integral por competencias 4C  (carácter,  contenido, capacidad y contexto), con los siguientes programas: Diplomas intermedios(áreas - módulos), Bachillerato superior en teología, Diplomados y Licenciatura. ",
      imagen: "/Imagenes/EstudiantePortada3.png",
    },
  ];

  const [index, setIndex] = useState(0);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimate(true);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % contenido.length);
        setAnimate(false);
      }, 500); // Tiempo de la animación antes de actualizar el contenido
    }, 5000);

    return () => clearInterval(interval);
  }, [contenido.length]);

  return (
    <section className="seccion-bannerSe">
      <div
        className={`banner-contenidoSe ${
          animate ? "slide-out-left" : "slide-in-right"
        }`}
      >

       <div className="bienvenidaSe">
          <h2>{contenido[index].bienvenida}</h2>
        </div>
        <h1 className="banner-subtituloSe">{contenido[index].titulo}</h1>
        <p className="banner-descripcionSe">{contenido[index].descripcion}</p>
        <div className="banner-botonesSe">
          <button
            className="btn btn-empezarBa"
            onClick={handleInscripcionClick}
          >
            ¡Inscríbete Ahora!
          </button>
          <button className="btn btn-cursosBa" onClick={handleProgramasClick}>
            Ver Programas
          </button>
        </div>
      </div>
      <div
        className={`banner-imagen ${
          animate ? "slide-out-left" : "slide-in-right"
        }`}
      >
        <img
          src={contenido[index].imagen}
          alt="Estudiante"
          className="imagen-portada"
        />
      </div>
    </section>
  );
};

export default SeccionBanner;
