import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Para llamar al backend
import { Modal, Button } from "react-bootstrap";
import countryList from "react-select-country-list";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";
import "./AlumnoDatos.css";

const AlumnoDatos = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [estudiantes, setEstudiantes] = useState([]);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [paginaActual, setPaginaActual] = useState(1);
  const [filterText, setFilterText] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); 
  
  const [formData, setFormData] = useState({
    codigoEstudiante: "Código Estudiante",
    periodoIngreso: "",
    turno: "Mañana",
    nivel: "Licenciatura",
    distrito:"",
    modalidad: "REGULAR",
    ciclo: "1",
    foto: null,
    estado: "Activo",
    dni: "",
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    sexo: "",
    celular: "",
    correo: "",
    fechaNacimiento: "",
    pais: "",
    ciudad: "",
    direccion: "",
    iglesia: "",
    distrito: "",
    fotocopiaCI: null,
    formulario1: null,
    formulario2: null,
    formulario3: null,
    comprobantePago: null,
    matricula: null,
  });

  const iglesias = [
    "16 DE FEBRERO",
    "16 DE JULIO",
    "2 DE FEBRERO",
    "6 DE JUNIO",
    "7 DE SEPTIEMBRE",
    "ACHICA ARRIBA",
    "ALTO CHIJINI",
    "ALTO LIMA",
    "ANTACOLLO",
    "APOSENTO ALTO",
    "BAUTISTA SAAVEDRA",
    "BELÉN DE SANTA ANA",
    "BETANIA",
    "CALAQUE WICHIWICHI",
    "CALATA CAPURITA",
    "CALATA GRANDE",
    "CALERÍA",
    "CANTAPA",
    "CATAVI",
    "CENTRAL CALLA",
    "CENTRAL COPAJIRA",
    "CENTRO PUTINA",
    "CHIPAMAYA",
    "CORPAPUTO",
    "DIGNIDAD",
    "EL ALTO: FILADELFIA",
    "EL PARAÍSO",
    "EL PROGRESO",
    "FORTALEZA",
    "GERMÁN BUSCH (Luz y Vida)",
    "HUACULLANI",
    "HUARINA",
    "HUAYNAPOTOSI PALCOCO",
    "ISLA SURIQUI",
    "JAILLIHUAYA",
    "JANKO AMAYA",
    "JESÚS DE MACHACA",
    "KENAKAHUA",
    "KERANI CENTRAL",
    "KHONKHO LIQUILIQUI",
    "LA FLORIDA",
    "LEQUIÑOSO",
    "MARISCAL SUCRE",
    "MUCUÑA",
    "NAZARENO LUZ DE CRISTO",
    "NUEVA JERUSALÉN",
    "PACAJES",
    "PARIRI",
    "PEÑAS",
    "POCOHOTA",
    "PONGONHUYO BERENGUELA",
    "PONGONHUYO CENTRAL",
    "PRIMERO DE MAYO",
    "PUCARANI",
    "PUCHUNI",
    "RÍO SECO",
    "SAANCHI",
    "SAJAMA",
    "SAMANCHA",
    "SAN FELIPE DE SEK'E",
    "SANTA ROSA (ex Villa Tejada)",
    "SANTA ROSA DE TARACO",
    "SAYHUAPAMPA (Carabuco)",
    "SEGUENCA",
    "SENKATA",
    "SURAMAYA",
    "TACACA",
    "TIQUINA",
    "TUQUIA",
    "VILLA ABAROA",
    "VILLA EXALTACIÓN",
    "VILLA MERCEDES",
    "VILLA NATIVIDAD",
    "VILLA PALESTINA",
    "VILLA SANTIAGO I",
    "VILLA SANTIAGO II",
    "VILUYO",
    "CONDORCHINOCA",
    "IRUMA",
    "CHOJNACOTA",
    "POCOKAHUA",
    "VENTILLITA",
    "CENTRAL ORURO",
    "NUEVA VIDA KANTUTA",
    "SAN MIGUEL",
    "MACHACAMARCA",
    "LOS PINOS",
    "COORDENADOR",
    "FUENTE DE VIDA",
    "TUPIZA",
    "BANDERANI",
    "HUAYLLATIRA",
    "TOLAPALCA",
    "PUCARA",
    "BARRIO PERIODISTA",
    "BASE AÉREA",
    "CALA CALA",
    "CLIZA",
    "NAZARENO PUCARA (inactiva)",
    "QUILLACOLLO",
    "TOCO PARADA",
    "ILLA HIROSHIMA",
    "VILLA IMPERIAL",
    "VILLA MÉJICO",
    "TIERRA DE ESPERANZA",
    "MISIÓN TRINIDAD",
    "CHULUMANI",
    "HUIRI ESMERALDA",
    "HUIRI NOGALANI",
    "UNIÓN HUIRI",
    "VILLA COPALANI",
    "TUCUPI",
    "PALOS BLANCOS",
    "BRECHA F",
    "LUZ Y VIDA",
    'AUQUI SAMAÑA" (Inactiva)',
    "SANTIAGO TOCORONI",
    "CENTRO TOCORONI",
    "CONCHITA GRANDE",
    "CENTRO CONCHITA",
    "CONCHITA CHICO",
    "SAN SILVERIO",
    "CARANAVI",
    "CULTURAL UNIDO",
    "BOLINDA",
    "ILLIMANI LOS ANDES",
    "ALCOCHE",
    "LOS ANDES",
    "TRAPICHIPONTE",
    "WACACALA",
    "BELÉN DE COACHIA",
    "ANTARANI",
    "AVIRCATO",
    "BOTIJLACA",
    "CALACACHI",
    "CALARI",
    "CALASAYA",
    "CALTECA",
    "CALLIRPA",
    "CANTUYO",
    "CAQUIAVIRI",
    "COHONI",
    "CONIRI",
    "COLQUE ALTA",
    "COLQUENCHA",
    "COMANCHE",
    "COROCORO",
    "CHAPICHAPINI",
    "CHIPANAMAYA",
    "CHULLUNKHAYANI",
    "ENEQUELLA",
    "GENERAL PANDO",
    "HILATA SAN JORGE",
    "IRPUMA",
    "JEK'ERI",
    "KELLA KELLA BAJA",
    "LACA",
    "ARANJUEZ",
    "BAJO PAMPAHASI",
    "BELLA VISTA",
    "BUENOS AIRES",
    "CENTRAL LA PAZ",
    "CODAVISA",
    "COTA COTA",
    "EL BUEN PASTOR",
    "ESCOBAR URÍA",
    "KOINONÍA",
    "LA PORTADA",
    "MEMORIAL WINCHESTER",
    "MIRAFLORES",
    "MUNAYPATA",
    "PASANKERI",
    "PLAYA VERDE BAJO SOPOCACHI",
    "23 DE MARZO",
    "VILLA FÁTIMA",
    "LLIMPHI",
    "NUEVA TILATA 3",
    "PATACAMAYA",
    "PLAYA VERDE",
    "PUTUNI",
    "ROSAPATA DE TULI",
    "ROSAPATA YARIBAY",
    "SICUIPATA",
    "TACACHIA",
    "TOMATA",
    "TOPOHOCO",
  ];

  // Filtro de iglesias basado en el término de búsqueda
  const filteredIglesias = useMemo(() => {
    return iglesias.filter((iglesia) =>
      iglesia.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, iglesias]);

  // Función para manejar la selección de la iglesia desde la lista filtrada
  const handleSelect = (value) => {
    setFormData({ ...formData, iglesia: value }); // Establece la iglesia seleccionada
    setSearchTerm(""); // Limpia el campo de búsqueda
  };


  const toggleForm = () => {
    setIsExpanded(!isExpanded); // Alternar el estado de expansión
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target; // Extraemos el nombre y valor del campo

    // Actualizamos el estado general del formulario
    setFormData({
      ...formData,
      [name]: value,
    });

    // Si el campo modificado es "iglesia", también actualizamos el término de búsqueda
    if (name === "iglesia") {
      setSearchTerm(value); // Actualizamos el término de búsqueda
    }
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };

  // Buscar estudiante
  const handleSearch = () => {
    const { codigoEstudiante } = formData;

    // Si se proporciona un código de estudiante, buscar solo por código
    if (codigoEstudiante) {
      obtenerEstudiantes(codigoEstudiante);
    } else {
      // Si no hay código de estudiante, aplicar los filtros
      const { modalidadCurso, periodoIngreso, iglesia, nivel } = formData;
      obtenerEstudiantesConFiltros(
        modalidadCurso,
        periodoIngreso,
        iglesia,
        nivel
      );
    }
  };
  useEffect(() => {
    // Solo aplicar los filtros si no se está buscando por código de estudiante
    if (!formData.codigoEstudiante) {
      const { modalidadCurso, periodoIngreso, iglesia, nivel } = formData;
      obtenerEstudiantesConFiltros(
        modalidadCurso,
        periodoIngreso,
        iglesia,
        nivel
      );
    }
  }, [
    formData.modalidadCurso,
    formData.periodoIngreso,
    formData.iglesia,
    formData.nivel,
  ]);

  // función obtenerEstudiante
  const obtenerEstudiantes = async (codigoEstudiante) => {
    try {
      // Crear los parámetros de la URL, en este caso solo filtraremos por el código
      const params = new URLSearchParams();

      if (codigoEstudiante) {
        params.append("codigoEstudiante", codigoEstudiante); // Agregar código de estudiante como filtro
      }

      const response = await axios.get(
        `http://localhost:5000/api/estudiantes?${params.toString()}`
      );

      // Si hay un código de estudiante, el backend debe devolver solo ese estudiante
      if (response.data.length > 0) {
        setEstudiantes([response.data[0]]); // Mostrar solo el estudiante encontrado
      } else {
        setEstudiantes([]); // Si no hay resultados, vaciar la tabla
      }
    } catch (error) {
      console.error("Error al obtener estudiante", error);
      setEstudiantes([]); // En caso de error, vaciar la tabla
    }
  };
  //Estudiantes con filtro
  const handleFilter = () => {
    const { modalidadCurso, periodoIngreso, iglesia, nivel } = formData;
    obtenerEstudiantesConFiltros(
      modalidadCurso,
      periodoIngreso,
      iglesia,
      nivel
    );
  };

  // Mantén esta función para obtener los estudiantes según los filtros
  const obtenerEstudiantesConFiltros = async (
    modalidadCurso,
    periodoIngreso,
    iglesia,
    nivel
  ) => {
    try {
      const params = new URLSearchParams();

      if (modalidadCurso) params.append("modalidadCurso", modalidadCurso);
      if (periodoIngreso) params.append("periodoIngreso", periodoIngreso);
      if (iglesia) params.append("iglesia", iglesia);
      if (nivel) params.append("nivel", nivel);

      const response = await axios.get(
        `http://localhost:5000/api/estudiantes?${params.toString()}`
      );

      setEstudiantes(response.data); // Actualiza la lista de estudiantes
    } catch (error) {
      console.error("Error al obtener estudiantes filtrados", error);
    }
  };

  // Obtener la lista de países desde la librería
  const options = countryList().getData();

  // Función para manejar el cambio en el selector de países
  const handleCountryChange = (selectedOption) => {
    // Cambié el nombre aquí
    setFormData({
      ...formData,
      pais: selectedOption.value, // Aquí guardamos el país seleccionado
    });
  };

  // Parámetros de paginación: número de página y registros por página
  const obtenerEstudiantesPaginados = async (pagina, tamanoPagina) => {
    try {
      const response = await axios.get(
        `http://localhost:5000/api/estudiantes?page=${pagina}&limit=${tamanoPagina}`
      );
      setEstudiantes(response.data.estudiantes); // Actualiza la lista de estudiantes
      setTotalPaginas(response.data.totalPaginas); // Esto será útil para manejar la paginación
    } catch (error) {
      console.error("Error al obtener estudiantes paginados", error);
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const logoURL = `${process.env.PUBLIC_URL}/Imagenes/LOGO.png`;

    const img = new Image();
    img.src = logoURL;
    img.onload = function () {
      // Añadir la imagen
      doc.addImage(img, "PNG", 15, 6, 25, 20);

      // Añadir el título
      doc.setFontSize(16);
      doc.text("Reporte General de Estudiantes", 70, 29);

      // Añadir el subtítulo
      doc.setFontSize(12);
      doc.text("Oficina de Servicios Académicos", 80, 35);

      // Añadir la tabla
      doc.autoTable({
        startY: 43,
        head: [["Código", "DNI", "Apellidos y Nombres", "Correo", "Celular"]],
        body: estudiantes.map((est) => [
          est.codigoEstudiante,
          est.dni,
          `${est.apellidoPaterno} ${est.apellidoMaterno}, ${est.nombres}`,
          est.correo,
          est.celular,
        ]),
      });

      // Descargar el PDF
      doc.save("reporte_estudiantes.pdf");
    };

    img.onerror = function () {
      console.error("Error al cargar la imagen");
    };
  };

  // función Navigate
  const navigate = useNavigate();

  // función handleEdit para redirigir al formulario de modificación
  const handleEdit = (codigoEstudiante) => {
    console.log("Código del estudiante a modificar: ", codigoEstudiante);
    navigate(`/modificar-estudiante/${codigoEstudiante}`);
  };
// Definir la función handlePrint para redirigir a la impreción de la boleta de Inscripción
  const handlePrint = (codigoEstudiante) => {
    window.open(`/imprimir-estudiante/${codigoEstudiante}`, '_blank');
  };
  

 // Elimina ESTUDIANTE
 const handleDelete = async (codigoEstudiante) => { 
  const codigoLimpio = codigoEstudiante.trim();
  console.log("Código del estudiante a eliminar:", codigoLimpio);

  // SweetAlert2 para la confirmación de eliminación
  const confirmacion = await Swal.fire({
    title: '¿Estás seguro?',
    text: "No podrás revertir esta acción",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sí, eliminar',
    cancelButtonText: 'Cancelar',
  });

  // Si el usuario confirma la acción
  if (confirmacion.isConfirmed) {
    try {
      await axios.delete(
        `http://localhost:5000/api/estudiantes/${codigoLimpio}`
      );

      obtenerEstudiantesConFiltros(
        formData.modalidadCurso,
        formData.periodoIngreso,
        formData.iglesia,
        formData.nivel
      );

      // SweetAlert2 para éxito
      Swal.fire(
        'Eliminado',
        'El estudiante ha sido eliminado correctamente.',
        'success'
      );

    } catch (error) {
      console.error("Error al eliminar el estudiante:", error);

      // SweetAlert2 para error
      Swal.fire(
        'Error',
        'Hubo un error al intentar eliminar el estudiante.',
        'error'
      );
    }
  }
};
  // Definición de la expresión regular para validar solo letras.............................***************************************
  const nameRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/;

  // Validación básica de número de celular entre 8 y 15 dígitos
  const celularRegex = /^[0-9]{8,15}$/;

  // Regex para validar el formato del correo
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Validar que la fecha de nacimiento no sea futura y que la persona tenga al menos 17 años
  const fechaActual = new Date();
  const fechaNacimiento = new Date(formData.fechaNacimiento);
  const edadMinima = 17;

  // Calcular la diferencia de años
  const edad = fechaActual.getFullYear() - fechaNacimiento.getFullYear();
  const mes = fechaActual.getMonth() - fechaNacimiento.getMonth();
  if (
    mes < 0 ||
    (mes === 0 && fechaActual.getDate() < fechaNacimiento.getDate())
  ) {
    edad--;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación del DNI
    if (!formData.dni) {
      Swal.fire({
        icon: "error",
        title: "Error en el DNI",
        text: "El campo de DNI no puede estar vacío.",
      });
      return; // Detener la ejecución si el DNI está vacío
    }

    if (formData.dni.length < 7) {
      Swal.fire({
        icon: "error",
        title: "Error en el DNI",
        text: "El número de DNI debe contener al menos 7 caracteres.",
      });
      return; // Detener la ejecución si el DNI no tiene suficientes caracteres
    }
    // Validación de los nombres
    if (!formData.nombres) {
      Swal.fire({
        icon: "error",
        title: "Error en el Nombre",
        text: "El campo de nombres no puede estar vacío.",
      });
      return; // Detener la ejecución si el campo está vacío
    }
    // Validación de los nombres
    if (!nameRegex.test(formData.nombres)) {
      Swal.fire({
        icon: "error",
        title: "Error en el Nombre",
        text: "El campo de nombres solo debe contener letras.",
      });
      return; // Detener la ejecución si el nombre no es válido
    }
    // Validación del apellido paterno
    if (!formData.apellidoPaterno) {
      Swal.fire({
        icon: "error",
        title: "Error en el Apellido Paterno",
        text: "El campo de apellido paterno no puede estar vacío.",
      });
      return; // Detener la ejecución si el campo está vacío
    }
    if (!nameRegex.test(formData.apellidoPaterno)) {
      Swal.fire({
        icon: "error",
        title: "Error en el Apellido Paterno",
        text: "El campo de apellido paterno solo debe contener letras.",
      });
      return; // Detener la ejecución si el apellido paterno no es válido
    }
    // Validación del apellido materno
    if (!formData.apellidoMaterno) {
      Swal.fire({
        icon: "error",
        title: "Error en el Apellido Materno",
        text: "El campo de apellido materno no puede estar vacío.",
      });
      return; // Detener la ejecución si el campo está vacío
    }

    if (!nameRegex.test(formData.apellidoMaterno)) {
      Swal.fire({
        icon: "error",
        title: "Error en el Apellido Materno",
        text: "El campo de apellido materno solo debe contener letras.",
      });
      return;
    }
    // Validación del celular
    if (!formData.celular) {
      Swal.fire({
        icon: "error",
        title: "Error en el Celular",
        text: "El campo de celular no puede estar vacío.",
      });
      return;
    }
    if (!celularRegex.test(formData.celular)) {
      Swal.fire({
        icon: "error",
        title: "Error en el número de celular",
        text: "El número de celular debe contener entre 8 y 15 dígitos numéricos.",
      });
      return; // Detener la ejecución si el número no es válido
    }
    // Validación del correo
    if (!formData.correo) {
      Swal.fire({
        icon: "error",
        title: "Error en el Correo",
        text: "El campo de correo no puede estar vacío.",
      });
      return;
    }

    if (!emailRegex.test(formData.correo)) {
      Swal.fire({
        icon: "error",
        title: "Error en el Correo",
        text: "Por favor, ingresa un correo electrónico válido.",
      });
      return;
    }
    // Validación de la fecha de nacimiento
    if (!formData.fechaNacimiento) {
      Swal.fire({
        icon: "error",
        title: "Error en la Fecha de Nacimiento",
        text: "El campo de Fecha de Nacimiento no puede estar vacío.",
      });
      return; // Detener la ejecución si el campo está vacío
    }
    if (fechaNacimiento > fechaActual) {
      Swal.fire({
        icon: "error",
        title: "Fecha de Nacimiento Inválida",
        text: "La fecha de nacimiento no puede ser una fecha futura.",
      });
      return; // Detener la ejecución si la fecha es futura
    }
    // Validación de la edad

    if (edad < edadMinima) {
      Swal.fire({
        icon: "error",
        title: "Edad no permitida",
        text: "El estudiante debe tener al menos 17 años.",
      });
      return;
    }
    // Validación de Iglesia
    if (!formData.iglesia) {
      Swal.fire({
        icon: "error",
        title: "Error en Iglesia",
        text: "El campo de Iglesia no puede estar vacío.",
      });
      return; // Detener la ejecución si el campo está vacío
    }

    // Validación de Ciudad
    if (!formData.ciudad) {
      Swal.fire({
        icon: "error",
        title: "Error en Ciudad",
        text: "El campo de Ciudad no puede estar vacío.",
      });
      return; // Detener la ejecución si el campo está vacío
    }

    // Validación de Dirección
    if (!formData.direccion) {
      Swal.fire({
        icon: "error",
        title: "Error en Dirección",
        text: "El campo de Dirección no puede estar vacío.",
      });
      return; // Detener la ejecución si el campo está vacío
    }

    // Creamos un FormData para enviar datos y archivos
    const data = new FormData();

    // Agregamos los campos del formulario a FormData
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      const response = await fetch("http://localhost:5000/api/estudiantes", {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        // Estudiante agregado exitosamente
        Swal.fire({
          title: "Estudiante agregado",
          text: "El estudiante ha sido agregado correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        setShowModal(false); // Cerrar el modal
      } else {
        const result = await response.json();
        Swal.fire({
          title: "Error",
          text: `Error: ${result.message}`,
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } catch (error) {
      console.error("Error al agregar estudiante:", error);
      Swal.fire({
        title: "Error",
        text: "Error al agregar estudiante",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const handleSaveAndClose = () => {
    setShowModal(false);
  };

  return (
    <div className="container-content">
      {/* Título del formulario con el botón adentro */}
      <div
        className="card-header d-flex justify-content-between align-items-center"
        onClick={toggleForm}
        style={{ cursor: "pointer" }}
      >
        .: Gestión de Estudiantes:.
        <span className="toggle-icon">
          {isExpanded ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-up"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 12.354a.5.5 0 0 1-.708 0L8 9.707l-2.646 2.647a.5.5 0 0 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-down"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 12a.5.5 0 0 1-.354-.854l3-3a.5.5 0 1 1 .708.708L8.707 11.5l-2.647-2.646a.5.5 0 0 1 .707-.708l3 3a.5.5 0 0 1 0 .707L8 12z"
              />
            </svg>
          )}
        </span>
      </div>

      {/* Contenido del formulario colapsable */}
      {isExpanded && (
        <div className="card">
          <div className="card-body">
            <form className="mb-0">
              <div className="buscar-estudiante-contenedor">
                <div className="row mb-3">
                  <div className="col-md-8">
                    <label>Código de estudiante:</label>
                    <input
                      type="text"
                      name="codigoEstudiante"
                      value={formData.codigoEstudiante} // Este valor debe reflejar el valor actual ingresado por el usuario
                      onChange={handleInputChange} // Asegúrate de que la función maneje correctamente los cambios en el input
                      className="form-control"
                    />
                  </div>

                  <div
                    className="col-md-4 d-flex align-items-start"
                    style={{ marginTop: "28px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={handleSearch} // Sigue llamando esta función
                    >
                      <i className="fas fa-search"></i> Buscar estudiante
                    </button>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label>Seleccionar Modalidad de curso:</label>
                  <select
                    name="modalidadCurso"
                    className="form-control"
                    style={{
                      height: "40px",
                      fontSize: "16px",
                      color: "#878585",
                    }}
                    value={formData.modalidadCurso}
                    onChange={handleInputChange}
                  >
                    <option value="REGULAR">REGULAR</option>
                    <option value="MODULAR">MODULAR</option>
                    <option value="INTENSIVO">INTENSIVO</option>
                  </select>
                </div>

                <div className="col-md-6">
                  <label>Estado:</label>
                  <select
                    name="estado"
                    className="form-control"
                    style={{
                      height: "40px",
                      fontSize: "16px",
                      color: "#878585",
                    }}
                    value={formData.estado}
                    onChange={handleInputChange}
                  >
                   <option value="Activo">Activo</option>
                   <option value="Inactivo">Inactivo</option>
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                {/* Contenido del formulario colapsable 
                <div className="col-md-6">
                  <label>Iglesia:</label>
                  <input
                    type="text"
                    name="iglesia"
                    className="form-control"
                    value={formData.iglesia}
                    onChange={handleInputChange}
                  />
                </div>
                */}
                {/* Contenido del formulario colapsable */}
                <div className="col-md-6">
                  <label>Seleccionar Programa:</label>
                  <select
                    name="nivel"
                    className="form-control"
                    style={{
                      height: "40px",
                      fontSize: "16px",
                      color: "#878787",
                    }}
                    value={formData.nivel}
                    onChange={handleInputChange}
                  >
                    <option value="LICENCIATURA">Licenciatura</option>
                    <option value="BACHILLERATO">Bachillerato</option>
                    <option value="CURSO_COMPLEMENTARIO">
                      Cursos Complementarios
                    </option>
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleFilter}
                  >
                    <i className="fas fa-filter"></i> Filtrar Estudiantes
                  </button>
                </div>

                <div className="col-md-6 text-end">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => setShowModal(true)}
                  >
                    <i className="fas fa-file-alt"></i> Agregar Estudiante
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="estudiantes-wrapper">
        {/* Tabla para ver estudiantes */}
        <div className="card1">
          <div className="reporte-estudiantes-container text-end">
            <button
              type="button"
              className="btn btn-info"
              onClick={handleGeneratePDF}
            >
              <i className="fas fa-file-alt"></i> Reporte Estudiantes
            </button>
          </div>

          <div className="card-body">
            <div className="row mb-3 align-items-center">
              <div className="col-md-6 d-flex align-items-center">
                <label className="me-2">Mostrar</label>
                <select
                  className="form-control select-registros me-2"
                  style={{
                    width: "auto",
                    height: "30px",
                    padding: "2px 12px",
                    fontSize: "14px",
                    appearance: "auto",
                    WebkitAppearance: "menulist-button",
                    MozAppearance: "none",
                    transform:
                      "translateY(-2px)" /* Esta línea sube el select */,
                  }}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                  <option>100</option>
                </select>
                <label>registros</label>
              </div>
              <div className="col-md-6 d-flex justify-content-end align-items-center">
                <label className="me-2">Buscar:</label>
                <input
                  type="text"
                  placeholder="Buscar..."
                  className="form-control buscar-input"
                  style={{ width: "200px", height: "30px", fontSize: "14px" }}
                />
              </div>
            </div>

            <table className="table table-bordered custom-table">
              <thead>
                <tr>
                  <th>Código</th>
                  <th>DNI</th>
                  <th>Apellidos y Nombres</th>
                  <th>Correo</th>
                  <th>Celular</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {estudiantes.map((est) => (
                  <tr key={est.codigoEstudiante}>
                    <td>{est.codigoEstudiante}</td>
                    <td>{est.dni}</td>
                    <td>{`${est.apellidoPaterno} ${est.apellidoMaterno}, ${est.nombres}`}</td>
                    <td>{est.correo}</td>
                    <td>{est.celular}</td>


                    <td className="acciones">
  <div className="dropdown">
    <button
      className="btn custom-button2 dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      Acción
    </button>
    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <li>
        <button className="dropdown-item">
          <i className="fas fa-eye"></i> Ver
        </button>
      </li>
      <li>
        <button
          className="dropdown-item"
          onClick={() => handleEdit(est.codigoEstudiante)}
        >
          <i className="fas fa-edit"></i> Modificar
        </button>
      </li>
      <li>
        <button
          className="dropdown-item"
          onClick={() => handleDelete(est.codigoEstudiante)}
        >
          <i className="fas fa-trash-alt"></i> Eliminar
        </button>
      </li>
      {/* Nueva opción para imprimir */}
      <li>
        <button
          className="dropdown-item"
          onClick={() => handlePrint(est.codigoEstudiante)}
        >
          <i className="fas fa-print"></i> Imprimir
        </button>
      </li>
    </ul>
  </div>
</td>



                  </tr>
                ))}
              </tbody>
            </table>

            <div className="pagination-container">
              <button className="btn btn-primary btn-paginacion">
                Anterior
              </button>
              <span className="page-number">1 de {totalPaginas}</span>
              <button className="btn btn-primary btn-paginacion">
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal para agregar estudiante */}

      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <Modal.Header
          style={{ backgroundColor: "#00a9b7", padding: "13px 15px" }}
          className="custom-modal-header"
          closeButton
        >
          <Modal.Title
            style={{ color: "white", fontWeight: 300, fontSize: "20px",zIndex:20 }}
          >
            Agregar estudiante
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            {/* Parte 1 del formulario */}
            <div className="custom-box">
              <button className="custom-button">.: DATOS ACADÉMICOS :.</button>
              {/* Envolver los campos en un div interno */}

              <div className="form-fields d-flex">
                {" "}
                {/* d-flex para activar Flexbox */}
                <div className="w-75">
                  {" "}
                  {/* Columna para los campos, ocupa el 75% del ancho */}
                  <div className="row mb-3">
                    {/* Primera fila */}
                    <div className="col-md-6">
                      <label>Código de Estudiante:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="codigoEstudiante"
                        value={formData.codigoEstudiante}
                        readOnly
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Periodo de Ingreso:</label>
                      <select
                        className="form-control"
                        name="periodoIngreso"
                        value={formData.periodoIngreso}
                        onChange={handleInputChange}
                      >
                        <option value="I-2024">I-2024</option>
                        <option value="II-2024">II-2024</option>
                        <option value="I-2023">I-2023</option>
                        <option value="II-2023">II-2023</option>
                        <option value="I-2022">I-2022</option>
                        <option value="II-2022">II-2022</option>
                        <option value="I-2021">I-2021</option>
                        <option value="II-2021">II-2021</option>
                        <option value="I-2020">I-2020</option>
                        <option value="II-2020">II-2020</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    {/* Segunda fila */}
                    <div className="col-md-6">
                      <label>Nivel:</label>
                      <select
                        className="form-control"
                        name="nivel"
                        value={formData.nivel}
                        onChange={handleInputChange}
                      >
                        <option value="LICENCIATURA">Licenciatura</option>
                        <option value="BACHILLERATO">Bachillerato</option>
                        <option value="CURSO_COMPLEMENTARIO">
                          Curso Complementario
                        </option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label>Modalidad de Curso:</label>
                      <select
                        className="form-control"
                        name="modalidadCurso"
                        value={formData.modalidadCurso}
                        onChange={handleInputChange}
                      >
                        <option value="REGULAR">Regular</option>
                        <option value="MODULAR">Modular</option>
                        <option value="INTENSIVO">Intensivo</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    {/* Tercera fila */}
                    <div className="col-md-6">
                      <label>Ciclo Académico:</label>
                      <select
                        className="form-control"
                        name="cicloAcademico"
                        value={formData.cicloAcademico}
                        onChange={handleInputChange}
                      >
                        {[...Array(10).keys()].map((i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label>Estado:</label>
                      <select
                        name="estado"
                        className="form-control"
                        value={formData.estado}
                        onChange={handleInputChange}
                      >
                        <option value="Activo">Activo</option>
                        <option value="Inactivo">Inactivo</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="w-25 text-center">
                  {/* Columna para la imagen, ocupa el 25% */}
                  <img
                    src={
                      formData.foto
                        ? URL.createObjectURL(formData.foto)
                        : "/Imagenes/Avatar.jpg"
                    }
                    alt="Imagen de perfil"
                    className="img-thumbnail"
                    style={{
                      width: "150px",
                      height: "200px",
                      objectFit: "cover",
                      marginBottom: "10px",
                    }}
                  />
                  <p>
                    {formData.foto
                      ? "Foto cargada"
                      : "Fotografía no disponible"}
                  </p>
                  <label htmlFor="upload-button" className="btn btn-info mt-2">
                    <i className="fas fa-upload"></i> Subir imagen
                  </label>
                  <input
                    type="file"
                    id="upload-button"
                    className="d-none"
                    name="foto"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>

            <div className="custom-box">
              {/* Botón para la sección de Datos Personales */}
              <button className="custom-button">.: DATOS PERSONALES :.</button>

              {/* Recuadro con los campos de Datos Personales */}
              <div className="form-fields">
                <div className="row mb-3">
                  <div className="col-md-4">
                    <label>DNI:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="dni"
                      value={formData.dni}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Nombres:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nombres"
                      value={formData.nombres}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Apellido Paterno:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="apellidoPaterno"
                      value={formData.apellidoPaterno}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-4">
                    <label>Apellido Materno:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="apellidoMaterno"
                      value={formData.apellidoMaterno}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Sexo:</label>
                    <select
                      className="form-control"
                      name="sexo"
                      value={formData.sexo}
                      onChange={handleInputChange}
                    >
                      <option value="">==Seleccionar==</option>
                      <option value="Femenino">Femenino</option>
                      <option value="Masculino">Masculino</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label>Celular:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="celular"
                      value={formData.celular}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-4">
                    <label>Correo Electrónico:</label>
                    <input
                      type="email"
                      className="form-control"
                      name="correo"
                      value={formData.correo}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Fecha de Nacimiento:</label>
                    <input
                      type="date"
                      className="form-control"
                      name="fechaNacimiento"
                      value={formData.fechaNacimiento}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                  <label>Iglesia:</label>
                <input
                  type="text"
                  className="form-control"
                  name="iglesia"
                  value={formData.iglesia}
                  onChange={handleInputChange} // Maneja los cambios en el campo dinámicamente
                  placeholder="Buscar iglesia..."
                />

                {/* Lista desplegable de iglesias */}
                {searchTerm && (
                  <ul className="mi-lista">
                    {filteredIglesias.map((iglesia, index) => (
                      <li
                        key={index}
                        className="mi-lista-item"
                        onClick={() => handleSelect(iglesia)} // Selecciona la iglesia
                      >
                        {iglesia}
                      </li>
                    ))}
                  </ul>
                )}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-4">
                    <label>País:</label>
                    <Select
                      options={options}
                      value={options.find(
                        (option) => option.value === formData.pais
                      )}
                      onChange={handleCountryChange}
                      styles={{
                        control: (base) => ({
                          ...base,
                          width: "100%", // Ajustar el ancho
                        }),
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Ciudad:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="ciudad"
                      value={formData.ciudad}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Dirección:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="direccion"
                      value={formData.direccion}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Distrito:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="distrito"
                      value={formData.distrito}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-box">
              {/* Botón para la sección de Documentos Digitales */}
              <button className="custom-button">
                .: DOCUMENTOS DIGITALES :.
              </button>

              {/* Recuadro con los campos de Documentos Digitales */}
              <div className="form-fields">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>Fotocopia CI:</label>
                    <input
                      type="file"
                      className="form-control"
                      name="fotocopiaCI"
                      accept="image/*,application/pdf"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Formulario 1:</label>
                    <input
                      type="file"
                      className="form-control"
                      name="formulario1"
                      accept="image/*,application/pdf"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>Formulario 2:</label>
                    <input
                      type="file"
                      className="form-control"
                      name="formulario2"
                      accept="image/*,application/pdf"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Formulario 3:</label>
                    <input
                      type="file"
                      className="form-control"
                      name="formulario3"
                      accept="image/*,application/pdf"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>Comprobante de Pago:</label>
                    <input
                      type="file"
                      className="form-control"
                      name="comprobantePago"
                      accept="image/*,application/pdf"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer style={{ padding: "10px" }}>
          <Button className="btn-add-edit" type="submit" onClick={handleSubmit}>
            Agregar y Seguir Editando
          </Button>

          <Button className="btn-add-close" onClick={handleSaveAndClose}>
            Agregar y Cerrar
          </Button>

          <Button
            className="btn-cancel-action"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AlumnoDatos;
