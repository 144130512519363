import React from "react";
import "./EstudianteCalificaciones.css";

const EstudianteCalificaciones = () => {
  const calificaciones = [
    {
      sigla: "SIS-111",
      materia: "INTRODUCCIÓN A LA PROGRAMACIÓN",
      paralelo: "1 \"D\"",
      gestion: "I - 2019",
      nota: 61,
      literal: "Sesenta y uno",
      resultado: "APROBADO",
    },
    {
      sigla: "QMC-112",
      materia: "QUÍMICA APLICADA A LA INGENIERÍA",
      paralelo: "1 \"D\"",
      gestion: "I - 2019",
      nota: 51,
      literal: "Cincuenta y uno",
      resultado: "APROBADO",
    },
    // Añade más calificaciones aquí
  ];

  return (
    <div className="calificaciones-container">
      <h2>Calificaciones del Estudiante</h2>
      <table className="calificaciones-tabla">
        <thead>
          <tr>
            <th>#</th>
            <th>Sigla</th>
            <th>Materia</th>
            <th>Paralelo</th>
            <th>Gestión</th>
            <th>Nota</th>
            <th>Literal</th>
            <th>Resultado</th>
          </tr>
        </thead>
        <tbody>
          {calificaciones.map((item, index) => (
            <tr key={index} className={item.nota >= 51 ? "aprobado" : "reprobado"}>
              <td>{index + 1}</td>
              <td>{item.sigla}</td>
              <td>{item.materia}</td>
              <td>{item.paralelo}</td>
              <td>{item.gestion}</td>
              <td>{item.nota}</td>
              <td>{item.literal}</td>
              <td>{item.resultado}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EstudianteCalificaciones;
