import 'bootstrap/dist/css/bootstrap.min.css';// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'; // Importar el Router
import App from './App'; // Importa App que maneja rutas públicas y privadas
import reportWebVitals from './reportWebVitals';
import './index.css';

// Envolver App dentro de Router
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

reportWebVitals();

