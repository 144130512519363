import React from "react";

import "./PerfilesAutoridades.css";

const PerfilesAutoridades = () => {
  return (
    <div className="main2-container">
      {/* Triángulos decorativos */}
      <div className="triangle-left"></div>


      {/* Contenedor Principal */}
      <div className="header-image-container">
        <img
          src="/Imagenes/FONDO2.png"
          alt="Vista de la vivienda del SENABOL"
          className="header-image"
        />
      </div>

      {/* Contenedor de biografías de autoridades */}
      <div className="perfiles-container">
        <h2 className="titulo">
          AUTORIDADES DEL SEMINARIO NAZARENO BOLIVIANO
        </h2>
        <p className="descripcion">
          "dedicadOs a la formación teológica e integral"
        </p>
        <div className="linea-decorativa"></div>
        {/* Perfil de la primera autoridad */}
        <div className="perfil-autoridad">
          <img
            src="/Imagenes/rector.png"
            alt="Rector del SENABOL"
            className="perfil-imagen"
          />
          <div className="perfil-info">
            <h4>Rev. Mgtr. Lic. Luis Adolfo Chávez Ticona</h4>
            <h5>Rector Ejecutivo</h5>
            <p>
              {" "}
              Nacido el 7 de abril de 1975 en La Paz, Bolivia, es el actual
              Rector del Seminario Teológico Nazareno del Cono Sur (STNCS). Su
              compromiso con la educación teológica y el liderazgo espiritual ha
              sido fundamental en su trayectoria académica y ministerial. Luis
              Adolfo Chávez Ticona comenzó su formación teológica en el
              Seminario Bíblico Nazareno, donde completó su Licenciatura en
              Teología en 2002.{" "}
            </p>
            <p>
              {" "}
              Su vocación por el estudio profundo de las Escrituras y su deseo
              de contribuir a la formación de líderes cristianos lo llevaron a
              continuar sus estudios con una Maestría en Biblia y Teología, la
              cual obtuvo en 2006. Su enfoque académico se ha centrado en la
              integración de la teoría teológica con la práctica pastoral,
              buscando preparar a los estudiantes no solo como académicos, sino
              también como líderes comprometidos y efectivos en sus comunidades.
            </p>
          </div>
        </div>

        {/* Repetir el bloque para otros perfiles de autoridades */}
        <div className="perfil-autoridad">
          <img
            src="/Imagenes/Vicerector.png"
            alt="Vicerrector del SENABOL"
            className="perfil-imagen"
          />
          <div className="perfil-info">
            <h4>Lic. Ing. Nelson Saavedra Romanos</h4>
            <h5>Vicerrector Académico</h5>
            <p>
              {" "}
              Nacido el 7 de abril de 1975 en La Paz, Bolivia, es el actual
              Rector del Seminario Teológico Nazareno del Cono Sur (STNCS). Su
              compromiso con la educación teológica y el liderazgo espiritual ha
              sido fundamental en su trayectoria académica y ministerial. Luis
              Adolfo Chávez Ticona comenzó su formación teológica en el
              Seminario Bíblico Nazareno, donde completó su Licenciatura en
              Teología en 2002.{" "}
            </p>
            <p>
              {" "}
              Su vocación por el estudio profundo de las Escrituras y su deseo
              de contribuir a la formación de líderes cristianos lo llevaron a
              continuar sus estudios con una Maestría en Biblia y Teología, la
              cual obtuvo en 2006. Su enfoque académico se ha centrado en la
              integración de la teoría teológica con la práctica pastoral,
              buscando preparar a los estudiantes no solo como académicos, sino
              también como líderes comprometidos y efectivos en sus comunidades.
            </p>
          </div>
        </div>
        <div className="perfil-autoridad">
          <img
            src="/Imagenes/administrativo.png"
            alt="Vicerrector del SENABOL"
            className="perfil-imagen"
          />
          <div className="perfil-info">
            <h4>Arq. Edwin Apaza Cerezo</h4>
            <h5>Vicerrector Administrativo</h5>
            <p>
              {" "}
              Nacido el 7 de abril de 1975 en La Paz, Bolivia, es el actual
              Rector del Seminario Teológico Nazareno del Cono Sur (STNCS). Su
              compromiso con la educación teológica y el liderazgo espiritual ha
              sido fundamental en su trayectoria académica y ministerial. Luis
              Adolfo Chávez Ticona comenzó su formación teológica en el
              Seminario Bíblico Nazareno, donde completó su Licenciatura en
              Teología en 2002.{" "}
            </p>
            <p>
              {" "}
              Su vocación por el estudio profundo de las Escrituras y su deseo
              de contribuir a la formación de líderes cristianos lo llevaron a
              continuar sus estudios con una Maestría en Biblia y Teología, la
              cual obtuvo en 2006. Su enfoque académico se ha centrado en la
              integración de la teoría teológica con la práctica pastoral,
              buscando preparar a los estudiantes no solo como académicos, sino
              también como líderes comprometidos y efectivos en sus comunidades.
            </p>
          </div>
        </div>

        {/* Añadir más perfiles según sea necesario */}
      </div>
    </div>
  );
};

export default PerfilesAutoridades;
