import React, { useState } from "react";
import "./SeccionDestacados.css";

const SeccionDestacados = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoSource, setVideoSource] = useState("");

  const openModal = (videoSrc) => {
    setVideoSource(videoSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setVideoSource("");
  };

  return (
    <section
      className="seccion-destacados"
      style={{ backgroundImage: `url('/Imagenes/estudiantes-Fondo.jpg')` }}
    >
      <div className="destacados-header">
        <h2>
          <i
            className="fas fa-graduation-cap"
            style={{ marginRight: "10px" }}
          ></i>
          Ventajas de Estudiar en Senabol
        </h2>
      </div>
      <div className="rectangulo-transparente"></div>

      <div className="floating-circle"></div>
      <div className="floating-circle circle-medium"></div>
      <div className="floating-circle circle-large"></div>

      <div className="destacados-container">
        {/* Video 1 */}
        <div className="destacado">
          <div
            className="video-thumbnail"
            onClick={() => openModal("/Videos/DOCENTES SENABOL.mp4")}
          >
            <img
              src="/Imagenes/Mejores docentes.jpeg"
              alt="Mejores docentes"
              className="video-imagen"
            />
            <div className="play-buttonDes">
              <div className="triangle"></div>
            </div>
          </div>
          <div className="destacado-texto">Docentes de Excelencia</div>
        </div>

        {/* Video 2 */}
        <div className="destacado">
          <div
            className="video-thumbnail"
            onClick={() => openModal("/Videos/CALIDAD-ENSEÑANZA.mp4")}
          >
            <img
              src="/Imagenes/Enseñanza-calidad.jpg"
              alt="Enseñanza de calidad"
              className="video-imagen"
            />
            <div className="play-buttonDes">
              <div className="triangle"></div>
            </div>
          </div>
          <div className="destacado-texto">Enseñanza de Calidad</div>
        </div>

        {/* Video 3 */}
        <div className="destacado">
          <div
            className="video-thumbnail"
            onClick={() => openModal("/Videos/CLASES-EN LINEA.mp4")}
          >
            <img
              src="/Imagenes/estudiar-linea.jpg"
              alt="Estudios en línea"
              className="video-imagen"
            />
            <div className="play-buttonDes">
              <div className="triangle"></div>
            </div>
          </div>
          <div className="destacado-texto">Estudios en Línea</div>
        </div>

        {/* Video 4 */}
        <div className="destacado">
          <div
            className="video-thumbnail"
            onClick={() => openModal("/Videos/AMBIENTES.mp4")}
          >
            <img
              src="/Imagenes/vivienda.jpg"
              alt="Viviendas acogedoras"
              className="video-imagen"
            />
            <div className="play-buttonDes">
              <div className="triangle"></div>
            </div>
          </div>
          <div className="destacado-texto">Viviendas Acogedoras</div>
        </div>
      </div>

      <div className="floating-star"></div>
      <div className="floating-star star-small"></div>

      <div className="adorno-ondulado">
        <svg
          width="120"
          height="30"
          viewBox="0 0 120 30"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="onda"
            d="M0 15 Q 15 0, 30 15 T 60 15 T 90 15 T 120 15"
          />
        </svg>
      </div>

      {/* Modal para el video */}
      {isModalOpen && (
        <div className="video-modal" onClick={closeModal}>
          <div className="video-modal-content">
            <button className="close-modal" onClick={closeModal}>
              &#10005;
            </button>
            <video width="90%" height="auto" controls autoPlay>
              <source src={videoSource} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </section>
  );
};

export default SeccionDestacados;
