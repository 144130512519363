import React, { useEffect, useState } from "react"; 
import "./SeccionBienvenida.css";

const SeccionBienvenida = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Seleccionar ambos contenedores
    const videoContenedor = document.querySelector(".video-contenedorBi");
    const textoContenedor = document.querySelector(".texto-contenedorBi");
  
    const handleScroll = () => {
      // Animación para el video
      const rectVideo = videoContenedor.getBoundingClientRect();
      if (rectVideo.top < window.innerHeight && rectVideo.bottom >= 0) {
        videoContenedor.classList.add("visible");
      }
  
      // Animación para el texto
      const rectTexto = textoContenedor.getBoundingClientRect();
      if (rectTexto.top < window.innerHeight && rectTexto.bottom >= 0) {
        textoContenedor.classList.add("visible");
      }
    };
  
    // Escuchar el evento de scroll
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      // Limpiar el evento de scroll
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  

  return (
    <section className="seccion-bienvenida">
      <div className="contenidobien">
        {/* Columna izquierda: Texto */}
        <div className="texto-contenedorBi">
          <h3>
            ¡Bienvenido a SENABOL!
          </h3>
          <p>
            Es un privilegio para nosostros darle la bienvenida a este espacio
            dedicado al servicio de Dios y al crecimiento espiritual. Creemos
            firmemente que su visita no es una coincidencia, sino parte del plan
            perfecto de nuestro Señor para sus vidas. SENABOL es un ministerio
            que busca formar líderes equipados para cumplir el llamado divino,
            con excelencia académica y un corazón rendido a la voluntad de Dios.
          </p>
          <p>
            Aquí encontrarán recursos, inspiración y una comunidad comprometida
            con la expansión del Reino de Dios. Que el Señor le bendiga
            abundantemente y les llene de sabiduría y fortaleza para cumplir con
            el propósito para el cual fueron creados. ¡Que Dios lo bendiga!"
          </p>
         
        </div>

        {/* Columna derecha: Miniatura del video con botón */}
        <div className="video-contenedorBi">
          <div className="video-thumbnailBi" onClick={openModal}>
            <img
              src="/Imagenes/MiniaturaVideo.png"
              alt="Miniatura del video"
              className="video-imagen"
            />
            <div className="play-button">
              <span>&#9658;</span>
            </div>
          </div>
        </div>
      </div>

      {/* Modal para el video */}
      {isModalOpen && (
        <div className="video-modalBi" onClick={closeModal}>
          <div className="video-modal-contentBi">
            <span className="close-modal" onClick={closeModal}>
              &times;
            </span>
            <video width="90%" height="auto" controls autoPlay>
              <source
                src="./Videos/Bienvenido a Senabol - Seminario Nazareno Boliviano.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      )}

      {/* Adornos */}
      <div className="adornos">
        <div className="adorno-circulo-grandeBi"></div>
        <div className="adorno-circulo-pequeno"></div>
        <div className="adorno-puntos"></div>
        <div className="adorno-lineas"></div>
        <div className="adorno-ondas"></div>
      </div>
    </section>
  );
};

export default SeccionBienvenida;
