import React from 'react';
import './ProgramaBachillerato.css';

const LicenciaturaTeologia = () => {
  return (
    <div className="programaBach-page">
      {/* Cabecera */}
      <header className="programaBach-header">
        <h1>Licenciatura en Teología</h1>
        <p>Formación académica avanzada para líderes ministeriales y teológicos.</p>
        <span className="nivelBach">Nivel Licenciatura</span>
        <div className="calificacionesBach">
          ⭐⭐⭐⭐⭐ (13 Materias Obligatorias - Duración: 1 año y medio)
        </div>
      </header>

      {/* ¿Qué aprenderá? */}
      <section className="aprendizajeBach">
        <h2>¿Qué aprenderá?</h2>
        <p>
          Este programa proporciona una formación integral en teología, habilidades ministeriales y
          competencias académicas para enseñar, liderar y servir en diferentes contextos ministeriales.
        </p>
      </section>

      {/* Plan de estudios */}
      <section className="planEstudiosBach">
        <h2>Plan de Estudios</h2>
        <table>
          <thead>
            <tr>
              <th>Área</th>
              <th>Materias</th>
              <th>Duración</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Teología</td>
              <td>
                <ul>
                  <li>Psicología Ministerial</li>
                  <li>Homilética Especializada sobre la Santidad</li>
                  <li>Panorama Teológico Contemporáneo</li>
                  <li>Pedagogía Avanzada</li>
                </ul>
              </td>
              <td>Marzo - Junio</td>
            </tr>
            <tr>
              <td>Biblia</td>
              <td>
                <ul>
                  <li>Seminario en Teología Bíblica</li>
                  <li>Evangelismo Urbano</li>
                  <li>Misionología</li>
                  <li>Teología Paulina</li>
                </ul>
              </td>
              <td>Julio - Octubre</td>
            </tr>
            <tr>
              <td>Ministerial</td>
              <td>
                <ul>
                  <li>Teología del Evangelio y Crecimiento de la Iglesia</li>
                  <li>Griego Bíblico Instrumental</li>
                  <li>Administración y Liderazgo Cristiano</li>
                  <li>Teología Arminio Wesleyana</li>
                  <li>Seminario en Trabajos Finales y Examen de Grado</li>
                </ul>
              </td>
              <td>Noviembre - Febrero</td>
            </tr>
          </tbody>
        </table>
      </section>

      {/* Requisitos */}
      <section className="requisitosBach">
        <h2>Requisitos</h2>
        <ul>
          <li>Completar el Programa de Bachillerato en Teología.</li>
          <li>Recoger y completar los formularios correspondientes (recomendación y solicitud de admisión).</li>
          <li>Carta de recomendación pastoral.</li>
          <li>Inscripción en la secretaría del Seminario Nazareno Boliviano.</li>
        </ul>
      </section>

      {/* Descripción */}
      <section className="descripcionBach">
        <h2>Descripción</h2>
        <p>
          Este programa de Licenciatura en Teología está diseñado para estudiantes que desean una formación académica avanzada. 
          Consta de 13 materias organizadas en un año y medio, integrando elementos teológicos, ministeriales y prácticos. 
          Su objetivo es equipar líderes para responder a los desafíos del ministerio contemporáneo en diferentes contextos.
        </p>
      </section>
    </div>
  );
};

export default LicenciaturaTeologia;
