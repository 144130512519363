import React, { useState, useEffect } from "react";
import "./Dashboard.css";

const Dashboard = () => {
  return (
    <div className="dashboard-container">
    
      <div className="dashboard-content">
    
        <div className="contenido">
          <h1>Bienvenido al Dashboard</h1>
          <p>Este es el área restringida del sistema.</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

