/*src/ComponentesRestringidos/ModuloNoticias/ModuloNoticias.js*/
import React, { useEffect, useState } from 'react';
import './ModuloNoticias.css';
import FormularioNoticia from './FormularioNoticia';

const ModuloNoticias = () => {
  const [noticias, setNoticias] = useState([]);
  const [noticiaSeleccionada, setNoticiaSeleccionada] = useState(null);
  const [modoEditar, setModoEditar] = useState(false);

  useEffect(() => {
    fetchNoticias();
  }, []);

  const fetchNoticias = async () => {
    try {
      const response = await fetch('http://localhost:5000/api/noticias');
      const data = await response.json();
      setNoticias(data);
    } catch (error) {
      console.error('Error al obtener noticias:', error);
    }
  };

  const eliminarNoticia = async (id) => {
    try {
      await fetch(`http://localhost:5000/api/noticias/${id}`, { method: 'DELETE' });
      fetchNoticias(); // Refrescar la lista de noticias después de eliminar
    } catch (error) {
      console.error('Error al eliminar noticia:', error);
    }
  };

  const seleccionarNoticia = (noticia) => {
    setNoticiaSeleccionada(noticia);
    setModoEditar(true);
  };

  const nuevaNoticia = () => {
    setNoticiaSeleccionada(null);
    setModoEditar(true);
  };

  return (
    <div className="modulo-noticias-container">
      <h1>Gestión de Noticias</h1>
      <button onClick={nuevaNoticia} className="boton-agregar">
        Añadir Nueva Noticia
      </button>

      <div className="lista-noticias">
        {noticias.length > 0 ? (
          noticias.map((noticia) => (
            <div key={noticia._id} className="noticia-item">
              <h3>{noticia.titulo}</h3>
              <p>{noticia.descripcion}</p>
              <button onClick={() => seleccionarNoticia(noticia)} className="boton-editar">Editar</button>
              <button onClick={() => eliminarNoticia(noticia._id)} className="boton-eliminar">Eliminar</button>
            </div>
          ))
        ) : (
          <p>No hay noticias disponibles.</p>
        )}
      </div>

      {modoEditar && (
        <FormularioNoticia
          noticia={noticiaSeleccionada}
          onGuardar={() => {
            setModoEditar(false);
            fetchNoticias();
          }}
          onCancelar={() => setModoEditar(false)}
        />
      )}
    </div>
  );
};

export default ModuloNoticias;
