import React from 'react';
import './ProgramaBachillerato.css';

const ProgramaDiplomas = () => {
  return (
    <div className="programaBach-page">
      {/* Cabecera */}
      <header className="programaBach-header">
        <h1>Programa de Diplomas</h1>
        <p>
          Formación académica estructurada en áreas específicas para fortalecer tu conocimiento bíblico, 
          ministerial y pastoral. Ideal para quienes desean una preparación profunda en menos tiempo.
        </p>
        <span className="nivelBach">Nivel Diploma</span>
        <div className="calificacionesBach">
          ⭐⭐⭐⭐⭐ (4 Áreas Académicas - Duración: 1 año por área)
        </div>
      </header>

      {/* Aprendizaje */}
      <section className="aprendizajeBach">
        <h2>¿Qué aprenderá?</h2>
        <p>
          Este programa se divide en cuatro áreas académicas que abordan temas específicos de la teología, 
          liderazgo pastoral, desarrollo ministerial y conocimiento profundo de la Biblia. Cada diploma está 
          diseñado para brindarte una base sólida en su área correspondiente.
        </p>
      </section>

      {/* Plan de estudios */}
      {/* Plan de estudios */}
      <div className="planEstudiosBach">
        <h2>Plan de Estudios</h2>
        <table>
          <thead>
            <tr>
              <th>Diplomas</th>
              <th>Materias</th>
              <th>Duración (Horas)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Diploma en Liderazgo Pastoral</td>
              <td>
                <ul>
                  <li>Apologética Cristiana Contemporánea</li>
                  <li>Formación y Liderazgo Espiritual</li>
                  <li>Evangelismo y Desarrollo Integral de la Iglesia</li>
                  <li>Administración y Gestión de Recursos Eclesiásticos</li>
                  <li>Cuidado y Asesoramiento Pastoral</li>
                  <li>Organización y Política Iglesia del Nazareno</li>
                  <li>Ética Cristiana</li>
                  <li>Metodología de Investigación Teológica</li>
                </ul>
              </td>
              <td>9 meses</td>
            </tr>
            <tr>
              <td>Diploma en Biblia Virtual</td>
              <td>
                <ul>
                <li>Pentateuco</li>
                <li>Literatura Juanina</li>
                <li>Literatura Poética</li>
                <li>Romanos</li>
                  <li>Nuevo Testamento I</li>
                  <li>Antiguo Testamento I</li>
                  <li>Hermenéutica Bíblica</li>
                  <li>Epístolas pastorales</li>
                  <li>Métodos Exegéticos</li>
                  <li>Nuevo Testamento II</li>
                  <li>Antiguo Testamento II</li>
                  <li>Práctica Ministerial I</li>
                </ul>
              </td>
              <td>8 meses y 1 semana</td>
            </tr>
            <tr>
              <td>Diploma en Teología</td>
              <td>
                <ul>
                  <li>Teología Sistemática I</li>
                  <li>Teología Sistemática II</li>
                  <li>Teología Sistemática III</li>
                  <li>Teología Sistemática IV</li>
                  <li>Teología de Santidad I</li>
                  <li>Teología de Santidad II</li>
                  <li>Teología y Ministerio de Jesús</li>
                </ul>
              </td>
              <td>8 meses y 1 semana</td>
            </tr>
            <tr>
              <td>Diploma en  Desarrollo Ministerial e Historia</td>
              <td>
                <ul>
                  <li>Historia Eclesiástica I</li>
                  <li>Historia Eclesiástica II</li>
                  <li>Fundamentos Nazarénicos</li>
                  <li>Historia e Identidad Iglesia del Nazareno</li>
                  <li>La Iglesia en Misión</li>
                </ul>
              </td>
              <td>8 meses y 1 semana</td>
            </tr>
          </tbody>
        </table>
       
      </div>

      {/* Requisitos */}
      <section className="requisitosBach">
        <h2>Requisitos</h2>
        <ul>
          <li>Fotocopia de CI o DNI</li>
          <li>Título de Bachiller</li>
          <li>Formulario de inscripción</li>
          <li>Carta de recomendación pastoral</li>
        </ul>
      </section>

      {/* Descripción */}
      <section className="descripcionBach">
        <h2>Descripción</h2>
        <p>
          El Programa de Diplomas está compuesto por 4 áreas académicas diseñadas para equipar a los estudiantes 
          con conocimientos específicos y prácticos en un tiempo más reducido. Cada área abarca materias 
          fundamentales para el desarrollo de un ministerio sólido y efectivo, asegurando una formación integral 
          para el servicio cristiano.
        </p>
      </section>
    </div>
  );
};

export default ProgramaDiplomas;
