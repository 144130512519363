import React, { useState } from "react";
import { Icon } from "@iconify/react";
import "./BarraSuperior.css";

const BarraSuperior = ({ toggleMenu, isCollapsed }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <div className="barra-superior2">
      {/* Botón hamburguesa con ícono SVG delgado */}
      <button 
  className={`toggle-btn ${isCollapsed ? "colapsado" : ""}`}
  onClick={toggleMenu}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className="icono-hamburguesa"
  >
    <path
      d="M3 8V7h17v1zm17 4v1H3v-1zM3 17h17v1H3z"
    />
  </svg>
</button>

      {/* Íconos de usuario */}
      <div className="opciones-usuario">
        <div className="icono-wrapper">
          <Icon icon="weui:email-outlined" className="icono-grande" />
        </div>
        <div className="icono-wrapper notificaciones">
          <Icon icon="ion:notifications-outline" className="icono-grande" />
          <span className="notificaciones-badge">3</span>
        </div>

        {/* Perfil del usuario con menú desplegable */}
        <div className="avatar-container" onClick={toggleDropdown}>
          <img
            src="/Imagenes/Vicerector.png"
            alt="Usuario"
            className="avatar-usuario"
          />
          <span className="nombre-usuario">Nelson Saavedra</span>
          {dropdownVisible && (
            <div className="dropdown-menu">
              <ul>
                <li>My Account</li>
                <li>My Profile</li>
                <li>User Billing</li>
                <li>Settings</li>
                <li>Log Out</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BarraSuperior;
