import React from "react";
import "./SeccionRecorrido.css";
import { useNavigate } from "react-router-dom";

const SeccionRecorrido = () => {
  const navigate = useNavigate();

  const handleContact = () => {
    navigate("/contacto"); // Asegúrate de que esta ruta exista en tu proyecto
  };

  return (
    <section className="seccion-recorrido">
      <div className="titulo-contenedor2">
        <h1>
          CENTRO DE RETIROS Y CONVENCIONES “ARANJUEZ” <br></br>
        </h1>
        <h2>
          Ofrecemos espacios diseñados para crear momentos inolvidables. Ya sea
          una ceremonia matrimonial, un evento espiritual o una reunión
          familiar, nuestros servicios están pensados para brindarte comodidad,
          belleza y un ambiente adecuado para honrar a Dios en cada detalle.
        </h2>
      </div>

      <div className="botones-contenedorRe">
        {/* Botón Redes Sociales */}
        <button className="boton-descargarRe">
          <span>Visite nuestras redes sociales</span>
          <div className="iconos-redesRe">
            <a
              href="https://www.facebook.com/profile.php?id=61570465680442&mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }} // Opcional: para mantener el estilo limpio
            >
              <i className="fab fa-facebook"></i>
            </a>

            <i className="fab fa-youtube"></i>
            <i className="fab fa-tiktok"></i>
            <i className="fab fa-instagram"></i>
          </div>
        </button>

        {/* Botón Contacto */}
        <a href="tel:+59172504992" className="boton-contacto">
          Póngase en contacto con nosotros
        </a>
      </div>

      <div className="adorno-ondulado3">
        <svg
          width="100"
          height="30"
          viewBox="0 0 100 30"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="onda3"
            d="M0 15 Q 15 0, 30 15 T 60 15 T 90 15 T 120 15"
          />
        </svg>
      </div>
      <div className="adorno-ondulado2">
        <svg
          width="1300"
          height="30"
          viewBox="0 0 1300 30"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="onda2"
            d="M0 15 Q 15 0, 30 15 T 60 15 T 90 15 T 120 15"
          />
        </svg>
      </div>
    </section>
  );
};

export default SeccionRecorrido;
