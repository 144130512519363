import React, { useState } from 'react';

const Asistencia = () => {
  const [estudiantes, setEstudiantes] = useState([
    { id: 1, nombre: 'Adalit', paterno: 'Ticona', materno: 'Yujra' },
    { id: 2, nombre: 'Hernan', paterno: 'Sanchez', materno: 'Paucara' },
    // Agrega más estudiantes aquí
  ]);

  const [fechas, setFechas] = useState([]); // Estado para almacenar las fechas de clase
  const [nuevaFecha, setNuevaFecha] = useState(''); // Estado para la fecha seleccionada

  // Función para agregar una nueva columna (fecha de asistencia)
  const agregarColumna = () => {
    if (!nuevaFecha) {
      alert('Por favor selecciona una fecha');
      return;
    }
    if (fechas.includes(nuevaFecha)) {
      alert('La fecha ya está registrada');
      return;
    }
    setFechas([...fechas, nuevaFecha]);
    setNuevaFecha(''); // Limpiar el input
  };

  // Función para manejar la asistencia de cada estudiante
  const handleAsistencia = (id, fecha, valor) => {
    const nuevosEstudiantes = estudiantes.map((est) => {
      if (est.id === id) {
        return {
          ...est,
          [fecha]: valor, // Almacenar la asistencia en la columna de la fecha
        };
      }
      return est;
    });
    setEstudiantes(nuevosEstudiantes);
  };

  return (
    <div className="container mt-4">
      {/* Título */}
      <h2 className="text-center mb-4">Registro de Asistencia</h2>

      {/* Input para seleccionar una nueva fecha */}
      <div className="mb-3">
        <label htmlFor="fecha">Selecciona la fecha de la clase:</label>
        <input
          type="date"
          id="fecha"
          value={nuevaFecha}
          onChange={(e) => setNuevaFecha(e.target.value)}
          className="form-control"
        />
        <button className="btn btn-success mt-2" onClick={agregarColumna}>
          Añadir columna de asistencia
        </button>
      </div>

      {/* Tabla de asistencia */}
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Apellido Paterno</th>
            <th>Apellido Materno</th>
            {/* Columnas dinámicas basadas en las fechas */}
            {fechas.map((fecha) => (
              <th key={fecha}>{fecha}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {estudiantes.map((est, index) => (
            <tr key={est.id}>
              <td>{index + 1}</td>
              <td>{est.nombre}</td>
              <td>{est.paterno}</td>
              <td>{est.materno}</td>
              {/* Campos de asistencia basados en las fechas */}
              {fechas.map((fecha) => (
                <td key={fecha}>
                  <select
                    className="form-control"
                    value={est[fecha] || ''} // Valor actual de asistencia
                    onChange={(e) => handleAsistencia(est.id, fecha, e.target.value)}
                  >
                    <option value="">Seleccione</option>
                    <option value="Presente">Presente</option>
                    <option value="Ausente">Ausente</option>
                    <option value="Justificado">Justificado</option>
                  </select>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Asistencia;
