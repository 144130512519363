import "./FormularioInscripcion.css";
import Swal from "sweetalert2"; // Importar SweetAlert2
import Select from "react-select";
import React, { useState, useEffect } from "react"; // Importar useEffect
import axios from "axios"; // Asegúrate de agregar esta línea
import countryList from "react-select-country-list";

const FormularioInscripcion = () => {
  const [pasoActual, setPasoActual] = useState(1);
  const [errores, setErrores] = useState({});
  const [dniRegistrado, setDniRegistrado] = useState(false); // Para verificar si el DNI ya está registrado
  const [dniValidado, setDniValidado] = useState(false); // Para verificar si el DNI ha sido validado correctamente
  const [sugerencias, setSugerencias] = useState([]);
  const [sugerenciasCursos, setSugerenciasCursos] = useState([]); // Cursos disponibles
  const [formData, setFormData] = useState({
    codigoEstudiante: "",
    periodoIngreso: "",
    nivel: "",
    distrito: "",
    modalidadCurso: "",
    cicloAcademico: "",
    estado: "Inactivo",
    dni: "",
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    sexo: "",
    celular: "",
    correo: "",
    fechaNacimiento: "",
    iglesia: "",
    pais: "",
    ciudad: "",
    direccion: "",
    cursoInteres: "", // Curso de interés
    fotocopiaCI: null,
    formulario1: null,
    formulario2: null,
    formulario3: null,
    comprobantePago: null,
    matricula: null,
  });

  // Manejador de cambios para inputs y selects
  const handleInputChangeOnl = async (e) => {
    const { name, type, value, files } = e.target;

    if (type === "file") {
      // Manejo de archivos
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else if (name === "fechaNacimiento") {
      // Formatear fechas
      const formattedDate = new Date(value).toISOString().split("T")[0];
      setFormData({ ...formData, [name]: formattedDate });
    } else {
      // Manejo del resto de campos
      setFormData({ ...formData, [name]: value });

      // Si el campo es "iglesia", buscar sugerencias en el backend
      if (name === "iglesia" && value.trim() !== "") {
        try {
          const response = await axios.get(
            `http://localhost:5000/api/iglesias/buscar?q=${value}`
          );
          setSugerencias(response.data); // Guardar las sugerencias en el estado
        } catch (error) {
          console.error("Error al obtener sugerencias:", error);
          setSugerencias([]); // Limpiar sugerencias si ocurre un error
        }
      } else if (name === "iglesia" && value.trim() === "") {
        setSugerencias([]); // Limpiar sugerencias si el campo está vacío
      }
    }
  };

  // Cargar cursos desde el backend al montar el componente
  useEffect(() => {
    const fetchCursos = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/cursos"); // Endpoint para obtener todos los cursos
        setSugerenciasCursos(response.data); // Guardar todos los cursos en el estado
      } catch (error) {
        console.error("Error al obtener los cursos:", error);
        setSugerenciasCursos([]); // Limpia las sugerencias si hay error
      }
    };

    fetchCursos();
  }, []);

  // Manejo de selección de un curso
  const seleccionarCurso = (curso) => {
    setFormData({ ...formData, cursoInteres: curso });
  };

  // Manejo de selección de una iglesia desde las sugerencias
  const seleccionarIglesia = (iglesia) => {
    setFormData({ ...formData, iglesia });
    setSugerencias([]); // Limpiar la lista de sugerencias después de seleccionar
  };

  // Opciones de países
  const options = countryList().getData(); // Obtener la lista de países

  // Manejar los cambios en los archivos subidos
  const handleFileChangeOnl = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const validarCI = () => {
    let erroresTemp = {};

    // Validar número de identificación (DNI)
    const regexIdentificacion = /^\d{7,11}$/; // Solo números entre 7 y 11 dígitos
    if (!formData.dni) {
      erroresTemp.dni = ".:. Llenar este campo es obligatorio .:.";
    } else if (!regexIdentificacion.test(formData.dni)) {
      erroresTemp.dni =
        ".:. El número de identificación debe tener entre 7 y 11 dígitos, solo números .:.";
    }

    // Si hay errores, mostrar el mensaje y no continuar
    if (Object.keys(erroresTemp).length > 0) {
      setErrores(erroresTemp);
      setDniValidado(false); // Deshabilitar el botón "Siguiente" si hay errores
      return; // Detenemos la ejecución si hay errores
    }

    // Verificar si el DNI ya está registrado
    fetch(`http://localhost:5000/api/estudiantes?dni=${formData.dni}`)
      .then((response) => {
        console.log("Respuesta del backend:", response);

        if (response.status === 200) {
          setDniRegistrado(true);
          setErrores({ dni: "El CI O DNI ya está registrado en el sistema." });
          setDniValidado(false); // No puedes continuar si el DNI ya está registrado
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No puede proceder con la inscripción en línea",
            confirmButtonText: "OK",
          });
        } else if (response.status === 400) {
          setDniRegistrado(false);
          setErrores({}); // Limpiar errores previos
          setDniValidado(true); // Habilitar validación de DNI
          Swal.fire({
            icon: "success",
            title: "Registro no encontrado",
            text: "Su CI o DNI no está registrado en el sistema. Puede proceder con la inscripción en línea.",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error inesperado",
            text: "Hubo un problema al verificar el DNI.",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        console.error("Error al verificar DNI:", error);
        Swal.fire({
          icon: "error",
          title: "Error de conexión",
          text: "No se pudo conectar con el servidor. Intenta nuevamente.",
          confirmButtonText: "OK",
        });
      });
  };

  // Validar los otros datos personales (Pasos siguientes)
  const validarDatosPersonales = () => {
    let erroresTemp = {};

    // Validación para solo letras en nombres y apellidos
    const soloLetrasRegex = /^[a-zA-Z\s]+$/;

    if (!formData.nombres) {
      erroresTemp.nombres = ".:.Este campo es obligatorio.:.";
    } else if (!soloLetrasRegex.test(formData.nombres)) {
      erroresTemp.nombres = "El nombre solo debe contener letras.";
    }

    if (!formData.apellidoPaterno) {
      erroresTemp.apellidoPaterno = ".:.Este campo es obligatorio.:.";
    } else if (!soloLetrasRegex.test(formData.apellidoPaterno)) {
      erroresTemp.apellidoPaterno =
        "El apellido paterno solo debe contener letras.";
    }

    if (!formData.apellidoMaterno) {
      erroresTemp.apellidoMaterno = ".:.Este campo es obligatorio.:.";
    } else if (!soloLetrasRegex.test(formData.apellidoMaterno)) {
      erroresTemp.apellidoMaterno =
        "El apellido materno solo debe contener letras.";
    }

    // Validación para campo ciudad
    if (!formData.ciudad) {
      erroresTemp.ciudad = ".:.Este campo es obligatorio.:.";
    }

    // Validación para campo distrito
    if (!formData.distrito) {
      erroresTemp.distrito = ".:.Este campo es obligatorio.:.";
    }

    // Validación para campo cursoInteres
    if (!formData.cursoInteres) {
      erroresTemp.cursoInteres = ".:.Este campo es obligatorio.:.";
    }

    // Validación para campo direccion
    if (!formData.direccion) {
      erroresTemp.direccion = ".:.Este campo es obligatorio.:.";
    }

    // Validar fecha de nacimiento (evitar fechas futuras y comprobar que tenga al menos 18 años-------------------------------------------)
    const fechaActual = new Date();
    const fechaNacimiento = new Date(formData.fechaNacimiento);
    const edadMinima = 18;
    const fechaLimite = new Date(
      fechaActual.getFullYear() - edadMinima,
      fechaActual.getMonth(),
      fechaActual.getDate()
    );

    if (!formData.fechaNacimiento) {
      erroresTemp.fechaNacimiento = ".:.Este campo es obligatorio.:.";
    } else if (fechaNacimiento > fechaActual) {
      erroresTemp.fechaNacimiento = "No puedes seleccionar una fecha futura.";
    } else if (fechaNacimiento > fechaLimite) {
      erroresTemp.fechaNacimiento = `Debes tener al menos ${edadMinima} años.`;
    }

    // Validar iglesia
    if (!formData.iglesia)
      erroresTemp.iglesia = ".:.Este campo es obligatorio.:.";

    // Validar celular (solo números y longitud entre 8 y 15 dígitos)
    const regexCelular = /^[0-9]{8,15}$/; // Solo permite entre 8 y 15 dígitos
    if (!formData.celular) {
      erroresTemp.celular = ".:.Este campo es obligatorio.:.";
    } else if (!regexCelular.test(formData.celular)) {
      erroresTemp.celular =
        "El número de celular debe tener entre 8 y 15 dígitos y solo contener números.";
    }

    // Validar correo electrónico (formato correcto)
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expresión regular para validar email
    if (!formData.correo) {
      erroresTemp.correo = ".:.Este campo es obligatorio.:.";
    } else if (!regexEmail.test(formData.correo)) {
      erroresTemp.correo = "Por favor ingresa un correo electrónico válido.";
    }
    setErrores(erroresTemp);
    return Object.keys(erroresTemp).length === 0;
  };

  /****************************************************************************************/
  const siguientePaso = () => {
    // Validación para el paso 1 (DNI)
    if (pasoActual === 1) {
      if (!dniValidado) return; // Si el DNI no está validado, no permite avanzar
    }

    // Validación para el paso 2 (Datos personales)
    if (pasoActual === 2) {
      const esValido = validarDatosPersonales(); // Llamamos a la función de validación
      if (!esValido) return; // Si los datos no son válidos, no permitimos avanzar
    }

    // Si no hay errores, avanzamos al siguiente paso
    setPasoActual(pasoActual + 1);
    setErrores({}); // Limpiamos los errores
  };

  // Retroceder al paso anterior
  const pasoAnterior = () => {
    setPasoActual(pasoActual - 1);
  };

  // Manejar el envío del formulario
  const handleSubmitOnl = async (e) => {
    e.preventDefault();

    // Verificar si los archivos obligatorios están cargados
    if (!formData.comprobantePago || !formData.matricula) {
      // Si no se han subido los archivos requeridos, mostramos una alerta de SweetAlert
      Swal.fire({
        title: "Error",
        text: "Debe subir los documentos requeridos.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      return; // No enviamos el formulario hasta que los documentos estén presentes
    }

    // Si los archivos están cargados, procedemos a crear el FormData y enviarlo
    const data = new FormData();

    // Agregamos los campos del formulario a FormData
    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        data.append(key, formData[key]);
      }
    });

    try {
      const response = await fetch("http://localhost:5000/api/estudiantes", {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        // Estudiante agregado exitosamente
        Swal.fire({
          title: "Estudiante agregado",
          text: "El estudiante ha sido agregado correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        setFormData({
          codigoEstudiante: "",
          periodoIngreso: "",
          nivel: "",
          distrito: "",
          modalidadCurso: "",
          cicloAcademico: "",
          estado: "Inactivo",
          dni: "",
          nombres: "",
          apellidoPaterno: "",
          apellidoMaterno: "",
          sexo: "==Seleccionar==",
          celular: "",
          correo: "",
          fechaNacimiento: "",
          iglesia: "",
          pais: "",
          ciudad: "",
          direccion: "",
          fotocopiaCI: null,
          formulario1: null,
          formulario2: null,
          formulario3: null,
          comprobantePago: null,
          matricula: null,
        }); // Limpiar formulario
      } else {
        const result = await response.json();
        Swal.fire({
          title: "Error",
          text: `Error: ${result.message}`,
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } catch (error) {
      console.error("Error al agregar estudiante:", error);
      Swal.fire({
        title: "Error",
        text: "Error al agregar estudiante",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  return (
    <div className="formulario-inscripcion">
      <div
        className="fondo-formulario-inscripcion"
        style={{ backgroundImage: `url('/imagenes/laptop20escritorio.png')` }}
      >
        <div className="titulopriForm">
          <h1>¡Realiza tu inscripción completamente en línea!</h1>
          <p className="mensaje-obligatorio">
            Descargue los cuestionarios necesarios, complételos y adjúntelos al
            formulario de inscripción en línea en formato PDF, o también puede
            entregarlos de manera fisica en las oficinas de SENABOL.
          </p>
          <div className="botones-formularios">
            <button
              className="boton-formulario"
              onClick={() =>
                window.open("/Documentos/FORMULARIO-01.pdf", "_blank")
              }
            >
              Descargue el FORM-01
            </button>
            <button
              className="boton-formulario"
              onClick={() =>
                window.open("/Documentos/FORMULARIO-02.pdf", "_blank")
              }
            >
              Descargue el FORM-02
            </button>
            <button
              className="boton-formulario"
              onClick={() =>
                window.open("/Documentos/FORMULARIO-03.pdf", "_blank")
              }
            >
              Descargue el Formato de Carta
            </button>
          </div>
        </div>
      </div>
      <div className="header-inscripcion">
        <div className="header-inscripcionFor">
          <h2>Formulario de Inscripción</h2>
        </div>
      </div>

      <div className="barra-progreso">
        {/* Paso 1: Verificación del CI */}
        <div className={`paso ${pasoActual === 1 ? "activo" : ""}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icono-carnet"
          >
            <rect x="2" y="4" width="20" height="16" rx="2" ry="2"></rect>
            <circle cx="9" cy="10" r="3"></circle>
            <path d="M16 12h4M16 16h4"></path>
          </svg>
        </div>

        {/* Paso 2: Datos Personales */}
        <div className={`paso ${pasoActual === 2 ? "activo" : ""}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icono-usuario"
          >
            <circle cx="12" cy="7" r="4"></circle>
            <path d="M5.5 21h13a1 1 0 0 0 .993-.883L19.5 20a7.5 7.5 0 0 0-15 0l-.006.117A1 1 0 0 0 5.5 21z"></path>
          </svg>
        </div>

        {/* Paso 3: Subir Documentos */}
        <div className={`paso ${pasoActual === 3 ? "activo" : ""}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icono-subir"
          >
            <path d="M12 3v12M5.5 10.5L12 4l6.5 6.5M5 21h14"></path>
          </svg>
        </div>

        {/* Paso 4: Confirmación y Pago */}
        <div className={`paso ${pasoActual === 4 ? "activo" : ""}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icono-confirmacion"
          >
            <path d="M20 6L9 17l-5-5"></path>
          </svg>
        </div>
      </div>

      <form onSubmit={handleSubmitOnl}>
        {/* Paso 1 */}
        {pasoActual === 1 && (
          <section className="seccion-formulario">
            <h3>Verificación de identificación :</h3>

            <label>
              CI/DNI:
              <input
                type="text"
                name="dni"
                value={formData.dni}
                onChange={handleInputChangeOnl}
              />
              {errores.dni && <span className="error">{errores.dni}</span>}
            </label>

            <div className="navegaciononl">
              <button
                type="button"
                onClick={validarCI}
                className="boton-verificaronl"
              >
                Verificar CI/DNI
              </button>

              {dniValidado && !errores.dni && (
                <button
                  type="button"
                  onClick={siguientePaso}
                  className="boton-siguienteonl"
                >
                  Siguiente
                </button>
              )}
            </div>
          </section>
        )}

        {/* Paso 2 ****************************************************************************************/}
        {pasoActual === 2 && (
          <section className="seccion-formulario">
            <h3>Datos Personales :</h3>
            <div className="grid-form">
              {/* Datos Personales visibles */}
              <label>
                Nombres:
                <input
                  type="text"
                  name="nombres"
                  value={formData.nombres}
                  onChange={handleInputChangeOnl}
                />
                {errores.nombres && (
                  <span className="error">{errores.nombres}</span>
                )}
              </label>

              <label>
                Apellido Paterno:
                <input
                  type="text"
                  name="apellidoPaterno"
                  value={formData.apellidoPaterno}
                  onChange={handleInputChangeOnl}
                />
                {errores.apellidoPaterno && (
                  <span className="error">{errores.apellidoPaterno}</span>
                )}
              </label>

              <label>
                Apellido Materno:
                <input
                  type="text"
                  name="apellidoMaterno"
                  value={formData.apellidoMaterno}
                  onChange={handleInputChangeOnl}
                />
                {errores.apellidoMaterno && (
                  <span className="error">{errores.apellidoMaterno}</span>
                )}
              </label>

              <label>
                Fecha de Nacimiento:
                <input
                  type="date"
                  name="fechaNacimiento"
                  value={formData.fechaNacimiento}
                  onChange={handleInputChangeOnl}
                />
                {errores.fechaNacimiento && (
                  <span className="error">{errores.fechaNacimiento}</span>
                )}
              </label>

              <label>
                Iglesia:
                <input
                  type="text"
                  name="iglesia"
                  value={formData.iglesia}
                  onChange={handleInputChangeOnl}
                />
                {errores.iglesia && (
                  <span className="error">{errores.iglesia}</span>
                )}
                {/* Lista de sugerencias */}
                {sugerencias.length > 0 && (
                  <ul className="mi-lista">
                    {sugerencias.map((iglesia) => (
                      <li
                        key={iglesia._id}
                        className="mi-lista-item"
                        onClick={() => seleccionarIglesia(iglesia.nombre)}
                      >
                        {iglesia.nombre}
                      </li>
                    ))}
                  </ul>
                )}
              </label>

              <label style={{ display: "none" }}>
                Estado:
                <select
                  name="estado"
                  value={formData.estado}
                  onChange={handleInputChangeOnl}
                >
                  <option value="Inactivo">Inactivo</option>
                </select>
              </label>

              <label>
                Celular:
                <input
                  type="text"
                  name="celular"
                  value={formData.celular}
                  onChange={handleInputChangeOnl}
                />
                {errores.celular && (
                  <span className="error">{errores.celular}</span>
                )}
              </label>

              <label>
                Dirección:
                <input
                  type="text"
                  name="direccion"
                  value={formData.direccion}
                  onChange={handleInputChangeOnl}
                />
                {errores.direccion && (
                  <span className="error">{errores.direccion}</span>
                )}
              </label>

              <label>
                Curso de interés:
                <select
                  name="cursoInteres"
                  value={formData.cursoInteres}
                  onChange={(e) =>
                    setFormData({ ...formData, cursoInteres: e.target.value })
                  }
                >
                  <option value="">Seleccione un curso</option>
                  {sugerenciasCursos.map((curso) => (
                    <option key={curso._id} value={curso.nombre}>
                      {curso.nombre}
                    </option>
                  ))}
                </select>
                {errores.cursoInteres && (
                  <span className="error">{errores.cursoInteres}</span>
                )}
              </label>

              <label>
                Correo Electrónico:
                <input
                  type="email"
                  name="correo"
                  value={formData.correo}
                  onChange={handleInputChangeOnl}
                />
                {errores.correo && (
                  <span className="error">{errores.correo}</span>
                )}
              </label>

              {/* Nuevos Campos */}

              <label>
                Modalidad de Clases:
                <select
                  name="ciudad"
                  value={formData.ciudad}
                  onChange={handleInputChangeOnl}
                >
                  <option value="Select...">Select...</option>
                  <option value="PRESENCIAL">PRESENCIAL</option>
                  <option value="VIRTUAL SINCRONICO">VIRTUAL SINCRÓNICO</option>
                  <option value="VIRTUAL ASINCRONICO">
                    VIRTUAL ASINCRÓNICO
                  </option>
                  <option value="SEMI-PRESENCIAL">SEMI-PRESENCIAL</option>
                </select>
                {errores.ciudad && (
                  <span className="error">{errores.ciudad}</span>
                )}
              </label>
              <label>
                Distrito:
                <select
                  name="distrito"
                  value={formData.distrito}
                  onChange={handleInputChangeOnl}
                >
                  <option value="Select...">Select...</option>
                  <option value="DISTRITO EL ALTO NOROESTE">
                    DISTRITO EL ALTO NOROESTE
                  </option>
                  <option value="DISTRITO BOLIVIA SUR ORURO, POTOSÍ">
                    DISTRITO BOLIVIA SUR ORURO, POTOSÍ
                  </option>
                  <option value="DISTRITO BOLIVIA CENTRAL – COCHABAMBA">
                    DISTRITO BOLIVIA CENTRAL – COCHABAMBA
                  </option>
                  <option value="DISTRITO SANTA CRUZ">
                    DISTRITO SANTA CRUZ
                  </option>
                  <option value="DISTRITO YUNGAS, POTOSÍ">
                    DISTRITO YUNGAS
                  </option>
                  <option value="DISTRITO LA PAZ">DISTRITO LA PAZ</option>
                </select>
              </label>

              <label>
                País:
                <Select
                  options={options}
                  value={options.find(
                    (option) => option.value === formData.pais
                  )}
                  onChange={(option) =>
                    setFormData({
                      ...formData,
                      pais: option.value,
                    })
                  }
                />
              </label>

              <label>
                Sexo:
                <select
                  name="sexo"
                  value={formData.sexo}
                  onChange={handleInputChangeOnl}
                >
                  <option value="Select...">Select...</option>
                  <option value="Femenino">FEMENINO</option>
                  <option value="Masculino">MASCULINO</option>
                </select>
              </label>

              <label>
                Programa Académico:
                <select
                  name="nivel"
                  value={formData.nivel}
                  onChange={handleInputChangeOnl}
                >
                  <option value="Select...">Select...</option>
                  <option value="BACHILLERATO">BACHILLERATO EN TEOLOGÍA</option>
                  <option value="BACHILLERATO_JUVENIL">
                    BACHILLERATO EN PASTORAL JUVENIL
                  </option>
                  <option value="DIPLOMA EN BIBLIA VIRTUAL">
                    DIPLOMA EN BIBLIA VIRTUAL
                  </option>
                  <option value="DIPLOMA EN TEOLOGÍA">
                    DIPLOMA EN TEOLOGÍA
                  </option>
                  <option value="DIPLOMA EN LIDERAZGO PASTORAL">
                    DIPLOMA EN LIDERAZGO PASTORAL
                  </option>
                  <option value="DIPLOMA EN DESARROLLO MINISTERIAL E HISTORIA">
                    DIPLOMA EN DESARROLLO MINISTERIAL E HISTORIA
                  </option>
                  <option value="DIPLOMADO_DI">
                    DIPLOMADO ES ESTUDIOS INTERCULTURALES DEI
                  </option>
                  <option value="CURSO_COMPLEMENTARIO">
                    DIPLOMADO EN EDUCACIÓN CRISTIANA
                  </option>
                  <option value="CURSO_COMPLEMENTARIO">
                    CURSOS COMPLEMENTARIOS
                  </option>
                </select>
              </label>

              <label>
                Modalidad de Curso:
                <select
                  name="modalidadCurso"
                  value={formData.modalidadCurso}
                  onChange={handleInputChangeOnl}
                >
                  <option value="Select...">Select...</option>
                  <option value="REGULAR">REGULAR</option>
                  <option value="INTENSIVO">INTENSIVO</option>
                  <option value="ETED">ETED</option>
                  <option value="MODULAR">MODULAR</option>
                </select>
              </label>
            </div>

            {/* Navegación de pasos */}
            <div className="navegaciononl">
              <button
                type="button"
                onClick={pasoAnterior} // Función para retroceder
                className="boton-anterioronl"
              >
                Anterior
              </button>
              <button
                type="button"
                onClick={siguientePaso} // Aquí llamas a la función que maneja todo el proceso
                className="boton-siguienteonl"
                disabled={pasoActual !== 2} // Solo habilitar el botón en el paso 2
              >
                Siguiente
              </button>
            </div>
          </section>
        )}

        {/* Paso 3: Subir documentos */}
        {pasoActual === 3 && (
          <section className="seccion-formulario">
            <h3>Subir Documentos :</h3>
            <h4 className="opcional">
              .:.Si no cuenta con los documentos solicitados, dispone de un
              plazo aproximado de 2 semanas para regularizar su documentación
              con la institución.Por el momento, puede continuar con el proceso
              de inscripción seleccionando el botón 'Siguiente'. .:.
            </h4>
            <div className="subir-documentos-grid">
              {/* Documento: Carnet */}
              <div className="subir-documento">
                <label htmlFor="fotocopiaCI">
                  <img
                    src="/Imagenes/iconoSubir.png"
                    alt="fotocopiaCI"
                    className="imagen-subir"
                  />
                  <span>Subir fotocopia CI</span>
                </label>
                <input
                  type="file"
                  name="fotocopiaCI"
                  id="fotocopiaCI"
                  onChange={handleInputChangeOnl}
                  style={{ display: "none" }}
                />
                {formData.fotocopiaCI && (
                  <span>{formData.fotocopiaCI.name}</span>
                )}{" "}
                {/* Mostrar el nombre del archivo */}
              </div>

              {/* Documento: Formulario 1 */}
              <div className="subir-documento">
                <label htmlFor="formulario1">
                  <img
                    src="/Imagenes/iconoSubir.png"
                    alt="Subir formulario 1"
                    className="imagen-subir"
                  />
                  <span>Subir formulario 1</span>
                </label>
                <input
                  type="file"
                  name="formulario1"
                  id="formulario1"
                  onChange={handleInputChangeOnl}
                  style={{ display: "none" }}
                />
                {formData.formulario1 && (
                  <span>{formData.formulario1.name}</span>
                )}{" "}
                {/* Mostrar el nombre del archivo */}
              </div>

              {/* Documento: Formulario 2 */}
              <div className="subir-documento">
                <label htmlFor="formulario2">
                  <img
                    src="/Imagenes/iconoSubir.png"
                    alt="Subir formulario 2"
                    className="imagen-subir"
                  />
                  <span>Subir formulario 2</span>
                </label>
                <input
                  type="file"
                  name="formulario2"
                  id="formulario2"
                  accept="application/pdf, image/png, image/jpeg, image/jpg"
                  onChange={handleInputChangeOnl}
                  style={{ display: "none" }}
                />
                {formData.formulario2 && (
                  <span>{formData.formulario2.name}</span>
                )}{" "}
                {/* Mostrar el nombre del archivo */}
              </div>

              {/* Documento: Formulario 3 */}
              <div className="subir-documento">
                <label htmlFor="formulario3">
                  <img
                    src="/Imagenes/iconoSubir.png"
                    alt="formulario3"
                    className="imagen-subir"
                  />
                  <span>Subir formulario 3</span>
                </label>
                <input
                  type="file"
                  name="formulario3"
                  id="formulario3"
                  onChange={handleInputChangeOnl}
                  style={{ display: "none" }}
                />
                {formData.formulario3 && (
                  <span>{formData.formulario3.name}</span>
                )}{" "}
                {/* Mostrar el nombre del archivo */}
              </div>
            </div>
            <div className="navegaciononl">
              <button
                type="button"
                onClick={pasoAnterior} // Función para retroceder
                className="boton-anterioronl"
              >
                Anterior
              </button>
              <button
                type="button"
                onClick={siguientePaso} // Aquí llamas a la función que maneja todo el proceso
                className="boton-siguienteonl"
                disabled={pasoActual !== 3} // Solo habilitar el botón en el paso 2
              >
                Siguiente
              </button>
            </div>
          </section>
        )}

        {/* Paso 4: Comprobante de Pago */}
        {pasoActual === 4 && (
          <section className="seccion-formulario">
            <h3>Comprobante de Pago :</h3>
            <h4 className="opcional">
              .:. Los documentos solicitados a continuación deben ser subidos de
              manera obligatoria.Caso contrario, no podrá completar su
              inscripción. .:.
            </h4>

            <div className="subir-documento">
              <label htmlFor="comprobantePago">
                <img
                  src="/Imagenes/iconoSubir.png"
                  alt="comprobantePago"
                  className="imagen-subir"
                />
                <span>Subir Comprobate de pago</span>
              </label>
              <input
                type="file"
                name="comprobantePago"
                id="comprobantePago"
                onChange={handleInputChangeOnl}
                style={{ display: "none" }}
              />
              {formData.comprobantePago && (
                <span>{formData.comprobantePago.name}</span>
              )}{" "}
              {/* Mostrar el nombre del archivo */}
            </div>

            <div className="subir-documento">
              <label htmlFor="matricula">
                <img
                  src="/Imagenes/iconoSubir.png"
                  alt="matricula"
                  className="imagen-subir"
                />
                <span>Subir Comprobante Matricula</span>
              </label>
              <input
                type="file"
                name="matricula"
                id="matricula"
                onChange={handleInputChangeOnl}
                style={{ display: "none" }}
              />
              {formData.matricula && <span>{formData.matricula.name}</span>}{" "}
              {/* Mostrar el nombre del archivo */}
            </div>

            <div className="navegacion-final">
              <button
                type="button"
                onClick={pasoAnterior}
                className="boton-anterior-final"
              >
                Anterior
              </button>

              <button type="submit" className="boton-enviar-final">
                Enviar Inscripción
              </button>
            </div>
          </section>
        )}
      </form>
    </div>
  );
};

export default FormularioInscripcion;
