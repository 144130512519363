/* src/ComponentesRestringidos/ModuloNoticias/FormularioNoticia.js */
import React, { useState, useEffect } from 'react';

const FormularioNoticia = ({ noticia, onGuardar, onCancelar }) => {
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [estado, setEstado] = useState('borrador');
  const [imagen, setImagen] = useState(null); // Estado para almacenar la imagen

  useEffect(() => {
    if (noticia) {
      setTitulo(noticia.titulo);
      setDescripcion(noticia.descripcion);
      setEstado(noticia.estado);
    }
  }, [noticia]);

  const guardarNoticia = async () => {
    const metodo = noticia ? 'PUT' : 'POST';
    const url = noticia ? `http://localhost:5000/api/noticias/${noticia._id}` : 'http://localhost:5000/api/noticias';

    // Crear un FormData para enviar los datos junto con el archivo de imagen
    const formData = new FormData();
    formData.append('titulo', titulo);
    formData.append('descripcion', descripcion);
    formData.append('estado', estado);
    if (imagen) {
      formData.append('imagen', imagen); // Añadir la imagen si existe
    }

    try {
      await fetch(url, {
        method: metodo,
        body: formData, // Enviar el FormData
      });

      onGuardar();
    } catch (error) {
      console.error('Error al guardar noticia:', error);
    }
  };

  return (
    <div className="formulario-noticia">
      <h2>{noticia ? 'Editar Noticia' : 'Nueva Noticia'}</h2>
      <label>
        Título:
        <input type="text" value={titulo} onChange={(e) => setTitulo(e.target.value)} />
      </label>
      <label>
        Descripción:
        <textarea value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />
      </label>
      <label>
        Estado:
        <select value={estado} onChange={(e) => setEstado(e.target.value)}>
          <option value="borrador">Borrador</option>
          <option value="publicado">Publicado</option>
        </select>
      </label>
      <label>
        Imagen:
        <input type="file" onChange={(e) => setImagen(e.target.files[0])} /> {/* Input para subir la imagen */}
      </label>
      <div className="botones-formulario">
        <button onClick={guardarNoticia} className="boton-guardar">Guardar</button>
        <button onClick={onCancelar} className="boton-cancelar">Cancelar</button>
      </div>
    </div>
  );
};

export default FormularioNoticia;
