import React, { useState } from 'react';

const PlanillaCalificaciones = () => {
  // Estado para almacenar los datos de cada estudiante
  const [estudiantes, setEstudiantes] = useState([
    { id: 1, nombre: 'Adalit', paterno: 'Ticona', materno: 'Yujra', nivel: 'U', contenido: 18, capacidades: 15, caracter: 22, contexto: 17, numeral: 72, literal: 'SETENTA Y DOS', observacion: 'Aprobado' },
    { id: 2, nombre: 'Hernan', paterno: 'Sanchez', materno: 'Paucara', nivel: 'U', contenido: 18, capacidades: 21, caracter: 24, contexto: 20, numeral: 83, literal: 'OCHENTA Y TRES', observacion: 'Aprobado' },
    // Puedes agregar más estudiantes aquí
  ]);

  // Manejo del cambio en los inputs
  const handleInputChange = (id, campo, valor) => {
    const nuevosEstudiantes = estudiantes.map(est => 
      est.id === id ? { ...est, [campo]: valor } : est
    );
    setEstudiantes(nuevosEstudiantes);
  };

  // Manejo del envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Calificaciones guardadas:', estudiantes);
  };

  return (
    <div className="container mt-4">
      {/* Título de la planilla */}
      <h2 className="text-center mb-4">Planilla de Calificación</h2>
      <h2 className="text-center mb-4">Planilla de Calificaciónes</h2>
      
      {/* Información del curso */}
      <div className="mb-3">
        <p><strong>Gestión:</strong> 2024</p>
        <p><strong>Semestre:</strong> PRIMERO</p>
        <p><strong>Materia:</strong> ORG. Y POLÍTICA DE LA IGLESIA DEL NAZARENO</p>
        <p><strong>Docente:</strong> Rvdo. CARLOS HUAYNOCA ROJAS</p>
        <p><strong>Créditos:</strong> TRES</p>
        <p><strong>Fecha del Curso:</strong> 01 de Febrero al 05 de Abril de 2024</p>
      </div>

      {/* Tabla de calificaciones */}
      <form onSubmit={handleSubmit}>
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Apellido Paterno</th>
              <th>Apellido Materno</th>
              <th>Nivel</th>
              <th>Contenido</th>
              <th>Capacidades</th>
              <th>Carácter</th>
              <th>Contexto</th>
              <th>Nota Final (Numeral)</th>
              <th>Nota Final (Literal)</th>
              <th>Observaciones</th>
            </tr>
          </thead>
          <tbody>
            {estudiantes.map((est, index) => (
              <tr key={est.id}>
                <td>{index + 1}</td>
                <td>{est.nombre}</td>
                <td>{est.paterno}</td>
                <td>{est.materno}</td>
                <td>{est.nivel}</td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={est.contenido}
                    onChange={(e) => handleInputChange(est.id, 'contenido', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={est.capacidades}
                    onChange={(e) => handleInputChange(est.id, 'capacidades', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={est.caracter}
                    onChange={(e) => handleInputChange(est.id, 'caracter', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={est.contexto}
                    onChange={(e) => handleInputChange(est.id, 'contexto', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={est.numeral}
                    onChange={(e) => handleInputChange(est.id, 'numeral', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={est.literal}
                    onChange={(e) => handleInputChange(est.id, 'literal', e.target.value)}
                  />
                </td>
                <td>
                  <select
                    className="form-control"
                    value={est.observacion}
                    onChange={(e) => handleInputChange(est.id, 'observacion', e.target.value)}
                  >
                    <option value="Aprobado">Aprobado</option>
                    <option value="Reprobado">Reprobado</option>
                    <option value="Abandonado">Abandonado</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="submit" className="btn btn-primary mt-3">
          Guardar Calificaciones
        </button>
      </form>
    </div>
  );
};

export default PlanillaCalificaciones;
